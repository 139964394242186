import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {geocodeByAddress} from "react-places-autocomplete";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";
import {v4 as uuidGenerator} from 'uuid';
import {CoOwner} from "../../models/write/coOwner.interface";

export const addCoOwner = (action$: ActionsObservable<actionCreators.Actions>,
                           state$: StateObservable<AppState>,
                           {coOwnerGateway}: {
                               coOwnerGateway: CoOwnerGateway
                           }) => {
        return action$.pipe(
            ofType<actionCreators.Actions,
                ReturnType<typeof actionCreators.Actions.addCoOwnerToCondominiumProperty>>(actionCreators.ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY),
            // debounceTime(200),
            switchMap(() => {
                const coOwnersContactData = state$.value.uiState.coOwnersAddition.coOwnersContactData;
                return Promise.all(coOwnersContactData.map(async coOwnerContactData => {
                    const [addressGeocode] = await geocodeByAddress(coOwnerContactData.address);
                    return {
                        ...coOwnerContactData, address: coOwnerContactData.address.split(',')[0],
                        zipCode: addressGeocode.address_components[6].long_name,
                        city: addressGeocode.address_components[2].long_name,
                        country: addressGeocode.address_components[5].long_name
                    };
                }))
            }),
            switchMap(coOwners => {
                const quota = state$.value.uiState.coOwnersAddition.quota;
                const uuid = uuidGenerator();
                return coOwnerGateway.addCoOwner(state$.value.uiState.selectedCondominiumProperty!.id, {
                    id: uuid,
                    quota,
                    coOwnerData: coOwners[0],
                    conjointCoOwnerData: coOwners[1]
                } as CoOwner).pipe(
                    map(() => actionCreators.Actions.coOwnerToCondominiumPropertyAdded(uuid))
                );
            })
        )
    }
;
