import { Button, Card, Modal, Table } from "react-bootstrap";
import * as React from "react";
import { FC, useState } from "react";
import { SpecialKey } from "../../../../../corelogic/usecases/specialkeyslist/specialKey.interface";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../../../corelogic/usecases/specialkeydeletion/actionCreators";

interface Props {
  data: SpecialKey[];
}

export const SpecialKeysList: FC<Props> = ({ data }) => {
  const [specialKeyIdToDelete, setSpecialKeyIdToDelete] = useState<
    string | undefined
  >(undefined);

  const dispatch = useDispatch();

  const handleClose = () => setSpecialKeyIdToDelete(undefined);
  const onTrash = (specialKeyId: string) => () => {
    setSpecialKeyIdToDelete(specialKeyId);
  };

  const handleDeletion = () => {
    dispatch(actionCreators.Actions.deleteSpecialKey(specialKeyIdToDelete!));
    handleClose();
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Table responsive className="list-table">
            <thead className="first-level-head">
              <tr>
                <th>Nom</th>
                <th>Tantièmes</th>
              </tr>
            </thead>
            <tbody className="first-level-body">
              {data.map((specialKey) => {
                return (
                  <tr key={specialKey.id} style={{ textAlign: "center" }}>
                    <td>
                      <div>{specialKey.name}</div>
                    </td>
                    <td className="shares" style={{ verticalAlign: "middle" }}>
                      {specialKey.shares}
                    </td>
                    <td>
                      <div
                        style={{ textAlign: "center" }}
                        onClick={onTrash(specialKey.id)}
                      >
                        <i
                          className="fas fa-trash"
                          style={{ cursor: "pointer", color: "red" }}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
      <Modal show={!!specialKeyIdToDelete} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette clé spéciale ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Annuler
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#f16e00", border: "1px solid #f16e00" }}
            onClick={handleDeletion}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
