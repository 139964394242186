import React, { useContext, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { TopBar } from "../layout/topbar/topBar.component";
import SidebarMenu from "../layout/sidebar/sidebarMenu.component";
import { Col, Container, Row } from "react-bootstrap";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import "./app.component";
import CoOwnersManagement from "../screens/coownersmanagementscreen/coOwnersManagement.component";
import { CondominiumPropertyManagement } from "../screens/condominiumpropertymanagementscreen";
import { CallsForFunds } from "../screens/callsforfundsscreen/callsforfunds.component";
import "react-circular-progressbar/dist/styles.css";
import { PaymentDone } from "../screens/paymentdone/paymentDone.component";
import { LoginCallback, SecureRoute, useOktaAuth } from "@okta/okta-react";
import { ReactReduxContext, useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../store/appState.interface";
import * as actionCreators from "../../../../corelogic/usecases/userauthentication/actionCreators";
import axiosInterceptorSetup from "../../../secondary/real/axiosInterceptorSetup";
import { SpecialKeysManagement } from "../screens/specialkeysmanagement/specialKeysManagement.component";
import { CondominiumDetailsView } from "../screens/condominium-details-view/condominiumDetailsView.component";

export const App = () => {
  const { authState, authService } = useOktaAuth();

  const { store } = useContext(ReactReduxContext);

  useEffect(() => {
    axiosInterceptorSetup(store, authService);
  }, [store, authService]);

  const dispatch = useDispatch();
  const authenticatedUser = useSelector(
    (state: AppState) => state.coreLogicState.authenticatedUser
  );
  const location = useLocation();

  useEffect(() => {
    if (new URLSearchParams(location.search).get("type_hint") === "ACTIVATION")
      authService.login("/");
    if (authenticatedUser || authState.isPending) return;
    authService.getUser().then((info: any) => {
      if (info) {
        const { name, email } = info;
        dispatch(
          actionCreators.Actions.authenticatedUser(
            info.sub,
            name,
            email,
            authState.accessToken
          )
        );
      }
    });
  }, [dispatch, authService, authenticatedUser, authState, location.search]);

  return (
    <>
      <TopBar isUserAuthenticated={!!authenticatedUser} />
      <Switch>
        <Route
          path={process.env.REACT_APP_OKTA_LOGIN_CALLBACK_PATH}
          component={LoginCallback}
        />
        <Route
          exact
          path="/paiementrealise"
          component={PaiementDoneRouteComponent}
        />
        <Route component={DefaultContainerRouteComponent} />
      </Switch>
    </>
  );
};

const PaiementDoneRouteComponent = () => {
  return (
    <Container fluid>
      <Route path="/paiementrealise" component={PaymentDone} />
    </Container>
  );
};

const DefaultContainerRouteComponent = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <SidebarMenu />
          <Col sm={12} className="main">
            <div className="content">
              <SecureRoute
                exact
                path="/coproprietes"
                component={CondominiumPropertyManagement}
              />
              <SecureRoute
                exact
                path="/appelsdefonds"
                component={CallsForFunds}
              />
              <SecureRoute
                exact
                path="/coproprietaires"
                component={CoOwnersManagement}
              />
              <SecureRoute
                exact
                path="/mes-cles-speciales"
                component={SpecialKeysManagement}
              />
              <SecureRoute
                exact
                path="/details"
                component={CondominiumDetailsView}
              />
              <Route exact path="/" render={() => <Redirect to="/details" />} />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
