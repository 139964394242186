import React, {useState} from 'react';
import {useOktaAuth} from '@okta/okta-react';
import * as actionCreators from "../../../../../corelogic/usecases/userauthentication/actionCreators";
import {useDispatch} from "react-redux";

const issuer = process.env.REACT_APP_OKTA_ISSUER;
const redirectUri = process.env.REACT_APP_OKTA_LOGOUT_REDIRECT_URI;

export const LogoutButton = () => {
    const {authState, authService} = useOktaAuth();
    const [isLoggingOut, setLoggingOut] = useState<boolean>(false);
    const dispatch = useDispatch();

    const logout = async (event: any) => {
        event.preventDefault();
        setLoggingOut(true);
        const idToken = authState.idToken;
        await authService.logout('/').then(() => {
            dispatch(actionCreators.Actions.loggedOutUser());
            window.location.href = `${issuer}/v1/logout?id_token_hint=${idToken}&post_logout_redirect_uri=${redirectUri}`;
        });
    };


    if (isLoggingOut)
        return <i className="fas fa-circle-notch auth-spin" style={{fontSize: '2rem'}}/>;
    return <div>
        <div className="d-xl-none">
            <i className="fas fa-sign-out-alt" style={{fontSize: '2rem', color: 'red'}} onClick={logout}/>
        </div>
        <div className="d-none d-xl-block">
            <a href="/#" className="btn btn-danger" onClick={logout} style={{color: '#fff'}}>Se déconnecter</a>
        </div>
    </div>
}
