import {Field} from "formik";
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import {PhoneFormField} from "../../../formcustomfields/phoneFormField.component";
import React, {FunctionComponent} from "react";
import {GooglePlacesAutocompleteField} from "../../../formcustomfields/googlePlacesAutocompleteField.component";

interface Props {
    errors: any,
    touched: any
    setFieldValue: any,
    values: any;
}

export const CoOwnerContactDataFormFields: FunctionComponent<Props> = ({
                                                                           setFieldValue, values,
                                                                           errors, touched
                                                                       }) => {

    return <>
        <FormGroup>
            <Row>
                <Col>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            id="male"
                            value="male"
                            checked={values['gender'] === 'male'}
                            onChange={() => {
                                setFieldValue('gender', 'male');
                            }}
                            name='male'
                            type="radio"
                        />
                        <label className="form-check-label" htmlFor="male">M.</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input"
                            id="female"
                            value="female"
                            checked={values['gender'] === 'female'}
                            onChange={() => {
                                setFieldValue('gender', 'female');
                            }}
                            name='female'
                            type="radio"
                        />
                        <label className="form-check-label" htmlFor="female">Mme.</label>
                    </div>
                    <div>{errors.gender && touched.gender ? (
                        <div className="error-message">{errors.gender}</div>
                    ) : null}</div>
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="firstName">Prénom</FormLabel>
                    <Field className="form-control" id="firstName" name="firstName"/>
                    {errors.firstName && touched.firstName ? (
                        <div className="error-message">{errors.firstName}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="lastName">Nom</FormLabel>
                    <Field className="form-control" id="lastName" name="lastName"/>
                    {errors.lastName && touched.lastName ? (
                        <div className="error-message">{errors.lastName}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="email">E-mail</FormLabel>
                    <Field className="form-control" id="email" name="email"/>
                    {errors.email && touched.email ? (
                        <div className="error-message">{errors.email}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="address">Adresse</FormLabel>
                    <Field className="form-control" id="address" name="address" component={GooglePlacesAutocompleteField}/>
                    {errors.address && touched.address ? (
                        <div className="error-message">{errors.address}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="phone">Téléphone</FormLabel>
                    <Field className="form-control" id="phone" name="phone" component={PhoneFormField}/>
                    {errors.phone && touched.phone ? (
                        <div className="error-message">{errors.phone}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>

    </>
};