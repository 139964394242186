import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "../coownerlotsaddition/actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const addCoOwnerLots = (action$: ActionsObservable<actionCreators.Actions>,
                               state$: StateObservable<AppState>,
                               {coOwnerGateway}: {
                                   coOwnerGateway: CoOwnerGateway
                               }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.addCoOwnerLots>>(actionCreators.ADD_CO_OWNER_LOTS),
        // debounceTime(200),
        switchMap(action => {
                const lots: { quota: number, lotNumber: string, lotType: string, building: string }[] = [];
                lots.push(state$.value.uiState.coOwnerLotsAddition.lot!);
                return coOwnerGateway.addLots(action.payload.coOwnerId, lots).pipe(
                    map(() => actionCreators.Actions.coOwnerLotsAdded(action.payload.coOwnerId, lots[0].lotNumber))
                )
            }
        )
    )
};
