import * as React from "react";
import {Component} from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {AppState} from "../../../../../../store/appState.interface";
import {CondominiumPropertyEditionForm} from "./condominiumPropertyEditionForm.component";
import * as condominiumPropertyAdditionActionCreators
    from "../../../../../../corelogic/usecases/condominiumpropertyaddition/actionCreators";
import {getCondominiumPropertyAddition} from "../../coownersmanagementscreen/coowneredition/selectors";
import {CustomSpinner} from "../../../layout/progresscomponents/spinners/customSpinner.component";

interface Props {
    onClose: () => void;
    condominiumPropertyAddition: {
        adding: boolean,
        justAdded: { id: string, name: string } | null
    },
    addCondominiumProperty: typeof condominiumPropertyAdditionActionCreators.Actions.addCondominiumProperty;
    refreshContent: () => void;
}

interface State {
}

class CondominiumPropertyEditionModal extends Component<Props, State> {

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps && prevProps.condominiumPropertyAddition.justAdded?.id !==
            this.props.condominiumPropertyAddition.justAdded?.id) {
            this.handleClose();
            this.props.refreshContent();
        }
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        return <Modal show={true} onHide={this.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Nouvelle copropriété
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.condominiumPropertyAddition.adding ? <CustomSpinner/> :
                    <CondominiumPropertyEditionForm onSubmit={this.props.addCondominiumProperty}/>
                }
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = (state: AppState) => ({
    condominiumPropertyAddition: getCondominiumPropertyAddition(state)
});

const mapDispatchToProps = {
    addCondominiumProperty: condominiumPropertyAdditionActionCreators.Actions.addCondominiumProperty
};

export default connect(mapStateToProps, mapDispatchToProps)(CondominiumPropertyEditionModal);
