import { combineEpics, createEpicMiddleware } from "redux-observable";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { AppState } from "./appState.interface";
import { composeWithDevTools } from "redux-devtools-extension";
import coreLogicState from "./coreLogicStateReducer";
import uiState from "./uiStateReducer";
import { retrieveCallsForFundsSynthesis } from "../corelogic/usecases/callsforfundsyearsynthesisretrieval/retrieveCallsForFundsSynthesis";
import { retrieveCondominiumProperties } from "../corelogic/usecases/condominiumpropertiesretrieval/retrieveCondominiumProperties";
import { retrieveCoOwnerDetails } from "../corelogic/usecases/coownersdetailsretrieval/retrieveCoOwnerDetails";
import { retrieveCondominiumPropertyCoOwnersList } from "../corelogic/usecases/condominiumpropertycoownerslistretrieval/retrieveCondominiumPropertyCoOwnersList";
import { addCoOwner } from "../corelogic/usecases/coowneraddition/addCoOwner";
import { launchCallForFunds } from "../corelogic/usecases/callforfundslaunching/launchCallForFunds";
import { addCondominiumProperty } from "../corelogic/usecases/condominiumpropertyaddition/addCondominiumProperty";
import { addCondominiumPropertyExercise } from "../corelogic/usecases/condominiumpropertyexerciseaddition/addCondominiumPropertyExercise";
import { retrieveBalanceBeforeCallsArchives } from "../corelogic/usecases/balancebeforecallsarchivesretrieval/retrieveBalanceBeforeCallsArchives";
import { setBalanceBeforeCallArchive } from "../corelogic/usecases/balancebeforecallsarchivesset/setBalanceBeforeCallArchive";
import { addCoOwnerLots } from "../corelogic/usecases/coownerlotsaddition/addCoOwnerLots";
import { retrieveCoOwnerLots } from "../corelogic/usecases/coownerlotsretrieval/retrieveCoOwnerLots";
import * as actionCreators from "../corelogic/usecases/userauthentication/actionCreators";
import { authenticatedUser } from "../corelogic/usecases/userauthentication/authenticatedUser";
import { loggedOutUser } from "../corelogic/usecases/userauthentication/loggedOutUser";
import { removeCoOwner } from "../corelogic/usecases/coownerremoval/removeCoOwner";
import { editCoOwner } from "../corelogic/usecases/coownerdetailsedition/editCoOwnerDetails";
import { cashCoOwnerMoney } from "../corelogic/usecases/cashcoownermoney/cashCoOwnerMoney";
import { removeCoOwnerLots } from "../corelogic/usecases/coownerlotsremoval/removeCoOwnerLots";
import { updateCoOwnerLot } from "../corelogic/usecases/coownerlotmodification/updateCoOwnerLot";
import { addSpecialKey } from "../corelogic/usecases/specialkeyaddition/addSpecialKey";
import { listSpecialKeys } from "../corelogic/usecases/specialkeyslist/listSpecialKeys";
import { associateSpecialKey } from "../corelogic/usecases/specialkeyassociation/associateSpecialKey";
import {
  retrieveCoOwnersDetailsView,
  retrieveLotsDetailsView,
} from "../corelogic/usecases/condominiumdetailsview/retrieveDetailsView";
import { disassociateSpecialKey } from "../corelogic/usecases/specialkeydisassociation/disassociateSpecialKey";
import { deleteSpecialKey } from "../corelogic/usecases/specialkeydeletion/deleteSpecialKey";

const rootReducer =
  (reducer: any) =>
  (state: AppState | undefined, action: actionCreators.Actions) => {
    if (action.type === "LOGGED_OUT_USER") {
      state = undefined;
    }
    return reducer(state, action);
  };

export const createReduxStore = (dependencies: any) => {
  const epicMiddleware = createEpicMiddleware({ dependencies });
  const rootEpic = combineEpics<any>(
    retrieveCallsForFundsSynthesis,
    retrieveCondominiumProperties,
    retrieveCoOwnerDetails,
    retrieveCondominiumPropertyCoOwnersList,
    addCoOwner,
    addCondominiumProperty,
    addCondominiumPropertyExercise,
    retrieveBalanceBeforeCallsArchives,
    launchCallForFunds,
    setBalanceBeforeCallArchive,
    addCoOwnerLots,
    updateCoOwnerLot,
    retrieveCoOwnerLots,
    authenticatedUser,
    loggedOutUser,
    removeCoOwner,
    removeCoOwnerLots,
    editCoOwner,
    cashCoOwnerMoney,
    addSpecialKey,
    associateSpecialKey,
    disassociateSpecialKey,
    listSpecialKeys,
    retrieveCoOwnersDetailsView,
    retrieveLotsDetailsView,
    deleteSpecialKey
  );
  const store = createStore(
    rootReducer(
      combineReducers<AppState>({
        uiState,
        coreLogicState,
      })
    ),
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
};
