import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from ".//actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const retrieveCoOwnerLots = (action$: ActionsObservable<actionCreators.Actions>,
                                       state$: StateObservable<AppState>,
                                       {coOwnerGateway}:
                                           { coOwnerGateway: CoOwnerGateway }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.retrieveCoOwnerLots>>(actionCreators.RETRIEVE_CO_OWNER_LOTS),
        // debounceTime(2000),
        switchMap(action => {
            return coOwnerGateway.retrieveCoOwnerLotsByCoOwnerId(action.payload.coOwnerId)
                .pipe(
                    map(coOwnerLots =>
                        actionCreators.Actions.coOwnerLotsRetrieved(coOwnerLots)),
                );
        }));
};
