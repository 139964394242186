import * as React from "react";
import {FunctionComponent} from "react";
import {Col, Row} from "react-bootstrap";
import {CallForFundsAssignment} from "../../../../../../corelogic/models/callForFundsAssignments.interface";
import {format} from "date-fns";

interface Props {
    assignment: CallForFundsAssignment;
    downloadDocumentLink: (assignmentId: string) => () => void;
}

export const ExpandedDetailsListItem: FunctionComponent<Props> = (({
                                                                       assignment,
                                                                       downloadDocumentLink
                                                                   }) => {

    return <td colSpan={3} className="expanded-details-list-item" style={{border: 0}}>
        <Row>
            <Col>
                Solde avant appel
            </Col>
            <Col>{assignment.balanceBefore.toFixed(2)}&nbsp;€</Col>
        </Row>
        <Row>
            <Col>
                Charges générales
            </Col>
            <Col>{assignment.amount.provision.toFixed(2)}&nbsp;€</Col>
        </Row>
        <Row>
            <Col>
                Fonds de travaux
            </Col>
            <Col>{assignment.amount.constructionFund.toFixed(2)}&nbsp;€</Col>
        </Row>
        <Row>
            <Col>
                Règlement
            </Col>
            <Col>{assignment.paid.toFixed(2)}&nbsp;€</Col>
        </Row>
        <Row>
            <Col>
                Date du dernier règlement
            </Col>
            <Col>{assignment.lastCashingDate ?
            <span><i>{format(new Date(assignment.lastCashingDate), "dd/MM/yyyy")}</i></span> : <span>Non disponible</span>}
            </Col>
        </Row>
        <Row>
            <Col>
                Solde après appel
            </Col>
            <Col>
                <strong>{assignment.balanceAfter.toFixed(2)}&nbsp;€</strong>
            </Col>
        </Row>
        <Row>
            <Col>Document envoyé</Col>
            <Col><i className="fas fa-download" style={{fontSize: 20}}
                    onClick={downloadDocumentLink(assignment.id)}/></Col>
        </Row>
    </td>
});