import {Field} from "formik";
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import React, {FunctionComponent, useEffect, useState} from "react";
import {NumberFormField} from "../../formcustomfields/numberFormField.component";
import {SelectFormField} from "../../formcustomfields/selectFormField.component";
import {format} from "date-fns";
import {GooglePlacesAutocompleteField} from "../../formcustomfields/googlePlacesAutocompleteField.component";

interface Props {
    errors: any,
    touched: any
    setFieldValue: any,
    values: any;
    suggestedExercisesStarts: Date[];
    endDateExerciseSuggestionCalculator: (startDate: Date) => Date
}

export const CondominiumPropertyEditionFormFields: FunctionComponent<Props> = ({
                                                                                   suggestedExercisesStarts,
                                                                                   endDateExerciseSuggestionCalculator,
                                                                                   setFieldValue, values,
                                                                                   errors, touched
                                                                               }) => {

    const sharesTypes = ["1000", "10000", "Autre"];
    const [showCustomShares, setShowCustomShares] = useState(false);

    useEffect(() => {
        if (values['shares'] === 'Autre')
            setShowCustomShares(true);
        if(values['shares'] === '1000' || values['shares'] === '10000')
            setShowCustomShares(false);
    }, [values])


    return <>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="address">Adresse</FormLabel>
                    <Field className="form-control" id="address" name="address"
                           component={GooglePlacesAutocompleteField}/>
                    {errors.address && touched.address ? (
                        <div className="error-message">{errors.address}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="shares">Tantièmes (ex: 1000, 10000 ou autre)</FormLabel>
                    <Field id="shares" name="shares"
                           component={SelectFormField}
                    >
                        {sharesTypes.map((share, index) =>
                            <option key={index} value={share}>
                                {share}
                            </option>)}
                    </Field>
                    {showCustomShares &&
                    <><br/><Field className="form-control" id="shares" name="shares"
                                  component={NumberFormField} style={{width: '100%'}} placeHolder="Entrez une valeur de tantième"/>
                        {errors.shares && touched.shares ? (
                            <div className="error-message">{errors.shares}</div>
                        ) : null}</>}
                </Col>
            </Row>
        </FormGroup>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="exerciseRange">Exercice</FormLabel>
                    <Field className="form-control" id="exerciseRange" name="exerciseRange"
                           component={SelectFormField}
                    >
                        {suggestedExercisesStarts.map((ds, index) =>
                            <option key={index} value={ds.getMonth()}>
                                Du {format(ds, 'dd/MM')} au&nbsp;
                                {format(endDateExerciseSuggestionCalculator(ds), 'dd/MM')}
                            </option>)}
                    </Field>
                    {errors.exerciseRange && touched.exerciseRange ? (
                        <div className="error-message">{errors.exerciseRange}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>
    </>
};
