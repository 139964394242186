import { combineReducers } from "redux";
import * as detailsViewActionCreators from "../usecases/condominiumdetailsview/actionCreators";
import { LotsDetailsView } from "../usecases/condominiumdetailsview/lotsDetailsView";

const data = (
  state: LotsDetailsView | null = null,
  action: detailsViewActionCreators.Actions
) => {
  switch (action.type) {
    case detailsViewActionCreators.RETRIEVE_LOTS_DETAILS_VIEW:
      return null;
    case detailsViewActionCreators.LOTS_DETAILS_VIEW_RETRIEVED:
      return action.payload.lotsDetailsView;
    default:
      return state;
  }
};

const fetching = (
  state: boolean = false,
  action: detailsViewActionCreators.Actions
) => {
  switch (action.type) {
    case detailsViewActionCreators.RETRIEVE_LOTS_DETAILS_VIEW:
      return true;
    case detailsViewActionCreators.LOTS_DETAILS_VIEW_RETRIEVED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  fetching,
});
