import {combineReducers} from "redux";
import * as actionCreators from "../usecases/balancebeforecallsarchivesretrieval/actionCreators";
import {BalanceBeforeCall} from "../models/balanceBeforeCall.interface";

const data = (state: BalanceBeforeCall[] = [], action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES:
            return [];
        case actionCreators.BALANCE_BEFORE_CALLS_ARCHIVES_RETRIEVED:
            return action.payload;
        default:
            return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES;
};

export default combineReducers({
    data,
    fetching
});
