import {Button, Col, FormGroup, FormLabel, Modal, Row} from "react-bootstrap";
import {Field, Form, Formik} from "formik";
import {NumberFormField} from "../../../formcustomfields/numberFormField.component";
import {default as React, FunctionComponent, useCallback} from "react";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import {DatePickerField} from "../../../formcustomfields/datePickerField.component";

interface Props {
    showCashMoneyModal: boolean;
    closeCashMoneyModal: () => void;
    coOwnerFirstName: string;
    coOwnerLastName: string;
    cashMoney: (cash: number, cashingDate: Date) => void;
    processingPayment: boolean;
    maxAmount: number
}

export const CashMoneyModal: FunctionComponent<Props> = ({
                                                             showCashMoneyModal,
                                                             closeCashMoneyModal,
                                                             coOwnerFirstName,
                                                             coOwnerLastName,
                                                             cashMoney,
                                                             maxAmount,
                                                             processingPayment
                                                         }) => {

    const formSchema =
        Yup.object().shape({
            moneyCash: Yup.number()
                .min(0, 'Le montant payé doit être positif.')
                .required('Le montant payé est requis.'),
            cashingDate: Yup.date().required("La date d'encaissement est requise.")
        });

    const handleSubmit = useCallback(({moneyCash, cashingDate}) => {
        cashMoney(moneyCash, cashingDate);
    }, [cashMoney]);

    return <Formik
        enableReinitialize={true}
        initialValues={{
            moneyCash: undefined,
            cashingDate: new Date()
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, submitForm
          }) => {
            return <Modal show={showCashMoneyModal} onHide={closeCashMoneyModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Encaissement de {coOwnerFirstName} {coOwnerLastName}</Modal.Title>
                </Modal.Header>
                {processingPayment ? <>
                    <Modal.Body>
                        <Row>
                            <Col className="text-center">
                                <p>Saisie du paiement en cours ...</p>
                            </Col>
                        </Row>
                    </Modal.Body>
                </> : <>
                    <Modal.Body>
                        <Form noValidate onSubmit={handleSubmit}>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <FormLabel htmlFor="moneyCash">Montant payé (€) :</FormLabel>
                                        <Field className="form-control" id="moneyCash" name="moneyCash"
                                               component={NumberFormField} style={{width: '100%'}}
                                        placeholder={"Montant total à encaisser : " + maxAmount + ' €'}/>
                                        {errors.moneyCash && touched.moneyCash ? (
                                            <div className="error-message">{errors.moneyCash}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormLabel htmlFor="cashingDate">Date d'encaissement :</FormLabel>
                                        <Field className="form-control" id="cashingDate" name="cashingDate"
                                               component={DatePickerField} style={{width: '100%'}}
                                               selected={new Date()}/>
                                        {errors.cashingDate && touched.cashingDate ? (
                                            <div className="error-message">{errors.cashingDate}</div>
                                        ) : null}
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={closeCashMoneyModal}>
                            Annuler
                        </Button>
                        <Button onClick={submitForm} className="btn button-action">
                            Valider
                        </Button>
                    </Modal.Footer>
                </>
                }
            </Modal>
        }}
    </Formik>;
}