import {Field, Form, Formik} from "formik";
import * as React from "react";
import {FunctionComponent, useCallback, useState} from "react";
import {BalanceBeforeCall} from "../../../../../../../corelogic/models/balanceBeforeCall.interface";
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import {NumberFormField} from "../../../formcustomfields/numberFormField.component";
import * as Yup from "yup";

interface Props {
    balanceBeforeCall: BalanceBeforeCall,
    openProfile: (coOwnerId: string, conjoint?: boolean) => () => void;
    setBalanceBeforeCall: (coOwnerId: string, balanceBefore: number) => void;
}

export const BalanceBeforeCallArchiveRow: FunctionComponent<Props> = ({
                                                                          balanceBeforeCall,
                                                                          setBalanceBeforeCall,
                                                                          openProfile
                                                                      }) => {

        const [balanceBeforeEdition, setBalanceBeforeEdition] = useState('');
        const [balanceBeforeEditionValue, setBalanceBeforeEditionValue] = useState(balanceBeforeCall.balanceBefore);

        const editBalanceBefore = useCallback((coOwnerId: string) => () => {
            setBalanceBeforeEdition(coOwnerId);
        }, []);

        const formSchema = Yup.object().shape({
            balanceBefore: Yup.number()
        });

        const handleSubmit = useCallback(({balanceBefore}) => {
            setBalanceBeforeCall(balanceBeforeCall.coOwner.id, balanceBefore || 0);
            setBalanceBeforeEditionValue(balanceBefore || 0);
            setBalanceBeforeEdition('');
        }, [setBalanceBeforeCall, setBalanceBeforeEdition, balanceBeforeCall]);

        return <tr key={balanceBeforeCall.coOwner.id} style={{textAlign: 'center'}}>
            <td>
                <div className="co-owner-fullname" onClick={openProfile(balanceBeforeCall.coOwner.id)}>
                    {balanceBeforeCall.coOwner.coOwnerPerson.firstName + ' ' + balanceBeforeCall.coOwner.coOwnerPerson.lastName}
                </div>
                {balanceBeforeCall.coOwner.coOwnerPersonConjoint &&
                <div className="co-owner-fullname" onClick={openProfile(balanceBeforeCall.coOwner.id, true)}>
                    {balanceBeforeCall.coOwner.coOwnerPersonConjoint.firstName + ' ' +
                    balanceBeforeCall.coOwner.coOwnerPersonConjoint.lastName}
                </div>}
            </td>
            <td>
                {balanceBeforeEdition === balanceBeforeCall.coOwner.id ? (
                        <Formik
                            enableReinitialize={true}
                            initialValues={{
                                balanceBefore: balanceBeforeEditionValue
                            }}
                            validationSchema={formSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                  errors, touched,
                                  submitForm, handleBlur
                              }) => {
                                return <Form noValidate>
                                    <FormGroup>
                                        <Row>
                                            <Col>
                                                <FormLabel htmlFor="balanceBefore">
                                                    Montant &gt; 0 = à payer, montant &lt; 0 = trop perçu
                                                </FormLabel>
                                                <Field className="form-control" id="balanceBefore" name="balanceBefore"
                                                       component={NumberFormField} style={{width: '100%'}}
                                                       placeHolder="Entrez le montant du solde"
                                                       onBlur={(e: any) => {
                                                           handleBlur(e);
                                                           return submitForm();
                                                       }}/>
                                                {errors.balanceBefore && touched.balanceBefore ? (
                                                    <div className="error-message">{errors.balanceBefore}</div>
                                                ) : null}
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                </Form>
                            }}
                        </Formik>
                    ) :
                    <span>{balanceBeforeEditionValue || balanceBeforeCall.balanceBefore}&nbsp;&nbsp;&nbsp;<i
                        className="fas fa-pen"
                        onClick={editBalanceBefore(balanceBeforeCall.coOwner.id)}/></span>
                }
            </td>
        </tr>
    }
;