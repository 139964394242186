import { Button, Col, FormGroup, FormLabel, Modal, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { NumberFormField } from "../formcustomfields/numberFormField.component";
import React, { FC, useCallback, useEffect } from "react";
import * as Yup from "yup";
import * as actionCreators from "../../../../../corelogic/usecases/specialkeyaddition/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import * as listActionCreators from "../../../../../corelogic/usecases/specialkeyslist/actionCreators";
import { AppState } from "../../../../../store/appState.interface";

type Props = {
  onClose?: () => void;
};

export const SpecialKeyAdditionModal: FC<Props> = ({ onClose }) => {
  const dispatch = useDispatch();
  const specialKeyAdditionSelector = useSelector(
    (state: AppState) => state.coreLogicState.specialKeyAddition
  );

  const handleSubmit = (values: any) => {
    dispatch(actionCreators.Actions.addSpecialKey(values));
  };

  const closeSpecialKeyModal = useCallback(() => {
    if (onClose) onClose();
  }, [onClose]);

  useEffect(() => {
    if (specialKeyAdditionSelector.justAdded) {
      closeSpecialKeyModal();
      dispatch(listActionCreators.Actions.listSpecialKeys());
      dispatch(actionCreators.Actions.clearSpecialKeyAddition());
    }
  }, [dispatch, specialKeyAdditionSelector.justAdded, closeSpecialKeyModal]);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        name: "",
        shares: undefined,
      }}
      validationSchema={specialKeyFormSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, submitForm }) => {
        return (
          <Modal show={true} onHide={closeSpecialKeyModal}>
            <Modal.Header closeButton>
              <Modal.Title>Ajouter une clé spéciale</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                <Form className="co-owner-lot-special-key-form">
                  <FormGroup>
                    <Row>
                      <Col>
                        <FormLabel htmlFor="shares">
                          Tantièmes totaux alloués
                        </FormLabel>
                        <Field
                          className="form-control"
                          id="shares"
                          name="shares"
                          component={NumberFormField}
                          style={{ width: 100 }}
                        />
                        {errors.shares && touched.shares ? (
                          <div className="error-message">{errors.shares}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                  <FormGroup>
                    <Row>
                      <Col>
                        <FormLabel htmlFor="name">
                          Nom de la clé spéciale
                        </FormLabel>
                        <Field className="form-control" id="name" name="name" />
                        {errors.name && touched.name ? (
                          <div className="error-message">{errors.name}</div>
                        ) : null}
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
              </>
            </Modal.Body>
            <Modal.Footer style={{ border: 0 }}>
              <Button variant="secondary" onClick={closeSpecialKeyModal}>
                Annuler
              </Button>
              <Button onClick={submitForm} className="btn button-action">
                Valider
              </Button>
            </Modal.Footer>
          </Modal>
        );
      }}
    </Formik>
  );
};

const specialKeyFormSchema = () =>
  Yup.object().shape({
    name: Yup.string().required("Le nom de la clé spéciale est requis."),
    shares: Yup.number()
      .min(1, "Le nombre de tantièmes doit être supérieur à 0.")
      .required("Le nombre de tantièmes est requis."),
  });
