import React, {FunctionComponent, useState} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import './coOwnerEditionForm.component.scss';
import {NumberFormField} from "../../../formcustomfields/numberFormField.component";
import {CoOwnerContactDataFormFields} from "./coOwnerContactDataFormFields.component";
import {AddedCoOwnerContactData} from "../../../../../../../corelogic/usecases/coowneraddition/addedCoOwnerContactData";

const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

const coOwnerSchema = (availableShares: number) => Yup.object().shape({
    quota: Yup.number()
        .min(1, 'Le nombre de tantièmes doit être supérieur à 0.')
        .max(availableShares, 'Le nombre de tantièmes doit être inférieur ou égal à ' + availableShares + '.')
        .required('Le nombre de tantièmes est requis.'),
    gender: Yup.string().required('Le genre est requis.'),
    firstName: Yup.string().required('Le prénom est requis.'),
    lastName: Yup.string().required('Le nom est requis.'),
    address: Yup.string().required('L\'adresse est requise.'),
    email: Yup.string()
        .email('L\'e-mail n\'est pas valide.')
        .required('L\'e-mail est requis.'),
    phone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide.')
});

interface Props {
    availableShares: number;
    onAddConjoint: (values: any) => void;
    onComplete: (values: any, fromConjoint: boolean) => void;
    quota: number;
    coOwnerContactData: AddedCoOwnerContactData | undefined;
}

export const CoOwnerEditionForm: FunctionComponent<Props> = ({
                                                                 availableShares,
                                                                 onAddConjoint,
                                                                 onComplete,
                                                                 quota,
                                                                 coOwnerContactData
                                                             }) => {

    const [wantToAddConjoint, setWantToAddConjoint] = useState(false);

    const handleSubmit = (values: any) => {
        if (wantToAddConjoint)
            onAddConjoint(values);
        else
            onComplete(values, false);
    };

    const FormComponent = ({errors, touched, setFieldValue, values, submitForm}: {
        errors: any,
        touched: any
        setFieldValue: any,
        values: any,
        submitForm: any
    }) => {
        return <Form className="co-owner-edition-form">
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel htmlFor="quota">Tantièmes <i>({availableShares} allouables)</i></FormLabel>
                        <Field className="form-control" id="quota" name="quota"
                               component={NumberFormField} style={{width: 100}}/>
                        {errors.quota && touched.quota ? (
                            <div className="error-message">{errors.quota}</div>
                        ) : null}
                    </Col>
                </Row>
            </FormGroup>
            <CoOwnerContactDataFormFields errors={errors} touched={touched}
                                          setFieldValue={setFieldValue} values={values}/>
            <div className="form-buttons-wrapper">
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0}}>
                        <div className="form-check include-conjoint-checkbox form-check-inline">
                            <input type="checkbox" checked={wantToAddConjoint}
                                   onChange={() => setWantToAddConjoint(!wantToAddConjoint)}
                                   className="form-check-input" id="conjoint"/>
                            <label className="form-check-label" htmlFor="conjoint">
                                Je veux renseigner également son conjoint</label>
                        </div>
                    </Col>
                </Row>
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0, textAlign: 'right'}}>
                        {wantToAddConjoint &&
                        <div className="next-link-wrapper">
                            <a href="/#"
                                onClick={event => {
                                    event.preventDefault();
                                    submitForm();
                                }}>&nbsp;<span> Ajouter son conjoint <i className="fas fa-chevron-right"/></span>
                            </a>
                        </div>}
                        {!wantToAddConjoint && <button type="submit" className="btn btn-primary">
                            Valider
                        </button>}
                    </Col>
                </Row>
            </div>
        </Form>;
    };

    return <div className="contact-form gray-light-bg">
        <Formik
            enableReinitialize={true}
            initialValues={coOwnerContactData ?
                {quota: quota !== 0 ? quota : undefined, ...coOwnerContactData} : {
                    quota: undefined,
                    gender: undefined,
                    email: '',
                    address: '',
                    phone: '',
                    firstName: '',
                    lastName: ''
                }}
            validationSchema={coOwnerSchema(availableShares)}
            onSubmit={handleSubmit}
        >
            {({
                  errors, touched, setFieldValue, values,
                  submitForm
              }) => {
                return <>
                    <FormComponent errors={errors} touched={touched} setFieldValue={setFieldValue}
                                   values={values} submitForm={submitForm}/>
                </>
            }}
        </Formik>
    </div>
};