import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY = 'ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY';
export const CO_OWNER_TO_CONDOMINIUM_PROPERTY_ADDED = 'CO_OWNER_TO_CONDOMINIUM_PROPERTY_ADDED';

export const Actions = {
    addCoOwnerToCondominiumProperty: () =>
        createAction(ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY),
    coOwnerToCondominiumPropertyAdded: (coOwnerId: string) =>
        createAction(CO_OWNER_TO_CONDOMINIUM_PROPERTY_ADDED, {coOwnerId}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
