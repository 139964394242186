import React from "react";
import {useOktaAuth} from '@okta/okta-react';
import {LogoutButton} from "./logOutButton.component";

export const SignInButton = () => {
    const {authState, authService} = useOktaAuth();
    const login = (event: any) => {
        event.preventDefault();
        authService.login('/');
    }

    if (authState.isPending) {
        return (
            <div className="justify-content-end">
                <div className="text-right">
                    <i className="fas fa-circle-notch auth-spin" style={{fontSize: '2rem'}}/>
                </div>
            </div>
        );
    } else if (!authState.isAuthenticated) {
        return (
            <div>
                <div className="d-xl-none">
                    <a href="/#">
                        <i className="fas fa-sign-in-alt" style={{fontSize: '2rem'}} onClick={login}/>
                    </a>
                </div>
                <div className="d-none d-xl-block">
                    <a href="/#" className="btn" style={{color: '#fff', backgroundColor: '#f16e00'}} onClick={login}>
                        Se connecter
                    </a>
                </div>
            </div>
        );
    }
    return <div>
        <LogoutButton/>
    </div>;
};
