import { Observable, of } from "rxjs";
import Axios from "axios-observable";
import { switchMap } from "rxjs/operators";
import { SpecialKeyGateway } from "../../../corelogic/gateways/specialKeyGateway.interface";
import { SpecialKey } from "../../../corelogic/models/write/specialKey.interface";
import { CoOwnersDetailsView } from "../../../corelogic/usecases/condominiumdetailsview/coOwnersDetailsView";

export class HttpSpecialKeyGateway implements SpecialKeyGateway {
  private static readonly CONDOMINIUM_KEY_API_URL =
    process.env.REACT_APP_BASE_API_URL + "/condominiumproperties/";
  private static readonly CO_OWNERS_KEY_API_URL =
    process.env.REACT_APP_BASE_API_URL + "/coowners/";

  save(
    condominiumPropertyId: string,
    specialKey: SpecialKey
  ): Observable<void> {
    try {
      return Axios.post(
        HttpSpecialKeyGateway.CONDOMINIUM_KEY_API_URL +
          condominiumPropertyId +
          "/specialkeys",
        { specialKey },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).pipe(
        switchMap((response) => {
          return of(response ? response.data : null);
        })
      );
    } catch (e) {
      return of() as Observable<void>;
    }
  }

  associate(
    coOwnerId: string,
    lotNumber: string,
    specialKeyId: string,
    quota: number
  ): Observable<void> {
    try {
      return Axios.post(
        HttpSpecialKeyGateway.CO_OWNERS_KEY_API_URL +
          coOwnerId +
          "/specialkeys",
        { lotNumber, specialKeyId, quota },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ).pipe(
        switchMap((response) => {
          return of(response ? response.data : null);
        })
      );
    } catch (e) {
      return of() as Observable<void>;
    }
  }

  disassociate(lotNumber: any, specialKeyId: any): Observable<void> {
    try {
      return Axios.delete(
        process.env.REACT_APP_BASE_API_URL +
          "/lots/" +
          lotNumber +
          "/specialkeys/" +
          specialKeyId
      ).pipe(
        switchMap((response) => {
          return of(response ? response.data : null);
        })
      );
    } catch (e) {
      return of() as Observable<void>;
    }
  }

  deleteById(specialKeyId: string): Observable<void> {
    try {
      return Axios.delete(
        process.env.REACT_APP_BASE_API_URL + "/specialkeys/" + specialKeyId
      ).pipe(
        switchMap((response) => {
          return of(response ? response.data : null);
        })
      );
    } catch (e) {
      return of() as Observable<void>;
    }
  }

  list(condominiumPropertyId: string): Observable<SpecialKey[]> {
    return Axios.get(
      HttpSpecialKeyGateway.CONDOMINIUM_KEY_API_URL +
        condominiumPropertyId +
        "/specialkeys"
    ).pipe(
      switchMap((response) => {
        return of(response ? response.data : []);
      })
    );
  }

  distributionByCoOwners(
    condominiumPropertyId: string
  ): Observable<CoOwnersDetailsView> {
    return Axios.get(
      HttpSpecialKeyGateway.CONDOMINIUM_KEY_API_URL +
        condominiumPropertyId +
        "/specialkeys/assignments?distribution=coowner"
    ).pipe(
      switchMap((response) => {
        return of(response ? response.data : []);
      })
    );
  }
}
