import {combineReducers} from "redux";
import * as actionCreators from "../usecases/specialkeyslist/actionCreators";
import {SpecialKey} from "../usecases/specialkeyslist/specialKey.interface";

const data = (state: SpecialKey[] | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.LIST_SPECIAL_KEYS:
            return null;
        case actionCreators.SPECIAL_KEYS_LISTED:
            return action.payload.specialKeys;
        default:
            return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    switch(action.type) {
        case actionCreators.LIST_SPECIAL_KEYS:
            return true;
        case actionCreators.SPECIAL_KEYS_LISTED:
            return false;
        default: return state;
    }
};

export default combineReducers({
    data,
    fetching
});
