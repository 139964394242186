import {combineReducers} from "redux";
import * as actionCreators from "../usecases/coownersdetailsretrieval/actionCreators";
import {CoOwnerDetails} from "../models/coOwnerDetails";

const data = (state: CoOwnerDetails | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.RETRIEVE_CO_OWNER_DETAILS:
            return null;
        case actionCreators.CO_OWNER_DETAILS_RETRIEVED:
            return action.payload;
        default:
            return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.RETRIEVE_CO_OWNER_DETAILS;
};

export default combineReducers({
    data,
    fetching
});
