import React, { FC, useState } from "react";
import { SpecialKeyAssociationModal } from "./specialKeyAssociationModal.component";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { CoOwnerLot } from "../../../../../../../corelogic/models/coOwnerLot";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../../../../../../corelogic/usecases/specialkeydisassociation/actionCreators";

interface Props {
  lot: CoOwnerLot;
  coOwnerId: string;
}

export const CoOwnerLotsSpecialKeys: FC<Props> = ({ coOwnerId, lot }) => {
  const [showSpecialKeyModal, setShowSpecialKey] = useState(false);
  const [specialKeyIdToDisassociate, setSpecialKeyIdToDisassociate] = useState<
    string | undefined
  >(undefined);
  const handleCloseConfirmationModal = () =>
    setSpecialKeyIdToDisassociate(undefined);
  const handleShowConfirmationModal = (specialKeyId: string) =>
    setSpecialKeyIdToDisassociate(specialKeyId);

  const dispatch = useDispatch();

  const closeSpecialKeyModal = () => {
    setShowSpecialKey(false);
  };

  const onTrash = (specialKeyId: string) => () => {
    handleShowConfirmationModal(specialKeyId);
  };

  const handleDisassociation = () => {
    disassociateSpecialKey(specialKeyIdToDisassociate!);
    handleCloseConfirmationModal();
  };

  const disassociateSpecialKey = (specialKeyId: string) => {
    dispatch(
      actionCreators.Actions.disassociateSpecialKey(lot.lotNumber, specialKeyId)
    );
  };

  return (
    <div
      style={{
        fontSize: 14,
        backgroundColor: "#fcf9f5",
        padding: 5,
        borderRadius: 10,
      }}
    >
      <h6 style={{ fontSize: 14 }}>
        <span className="font-weight-bold">
          Clés spéciales ({lot.specialKeys.length}){" "}
        </span>
        <Button
          variant="link"
          style={{ padding: 0, lineHeight: 0.2, verticalAlign: "top" }}
          onClick={() => setShowSpecialKey(true)}
        >
          <span className="fas fa-plus" style={{ color: "blue" }}></span>
        </Button>
      </h6>
      {lot.specialKeys && (
        <div>
          {lot.specialKeys.map((specialKey) => {
            return (
              <Row key={specialKey.id}>
                <Col xs={6} sm={6} md={6} lg={6}>
                  {specialKey.name}&nbsp;&nbsp;
                  {specialKey.quota} / {specialKey.shares}&nbsp;&nbsp;
                  <i
                    className="fas fa-trash"
                    style={{ cursor: "pointer", color: "red" }}
                    onClick={onTrash(specialKey.id)}
                  />
                </Col>
              </Row>
            );
          })}
        </div>
      )}
      {showSpecialKeyModal && (
        <SpecialKeyAssociationModal
          closeModalFn={closeSpecialKeyModal}
          coOwnerId={coOwnerId}
          lotNumber={lot.lotNumber}
        />
      )}
      <Modal
        show={!!specialKeyIdToDisassociate}
        onHide={handleCloseConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir désassocier cette clé spéciale ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmationModal}>
            Annuler
          </Button>
          <Button
            variant="primary"
            style={{ backgroundColor: "#f16e00", border: "1px solid #f16e00" }}
            onClick={handleDisassociation}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
