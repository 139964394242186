import {combineReducers} from "redux";
import * as actionCreators from "../usecases/condominiumpropertyaddition/actionCreators";

const justAdded = (state:  {id: string, name: string}  | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CONDOMINIUM_PROPERTY_ADDED:
            return {id: action.payload.condominiumPropertyId, name: action.payload.condominiumPropertyName};
        default:
            return state;
    }
};

const adding = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.ADD_CONDOMINIUM_PROPERTY;
};

export default combineReducers({
    adding,
    justAdded
});
