import * as actionCreators from "../usecases/cashcoownermoney/actionCreators";
import {combineReducers} from "redux";

const callForFundsAssignmentId = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_MONEY_CASHED:
            return action.payload.callForFundsAssignmentId;
        case actionCreators.CASH_CO_OWNER_MONEY:
        case actionCreators.CLEAR_MONEY_CASH_STATUS:
            return null;
        default:
            return state;
    }
};

const processing = (state: boolean = false, action: actionCreators.Actions) => {
    switch (action.type) {
        case 'CASH_CO_OWNER_MONEY':
            return true;
        case 'CO_OWNER_MONEY_CASHED':
            return false;
        default:
            return state;
    }
};

export default combineReducers({
    callForFundsAssignmentId,
    processing
});
