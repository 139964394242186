import {Field} from "formik";
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import React, {FunctionComponent} from "react";
import {NumberFormField} from "../../formcustomfields/numberFormField.component";

interface Props {
    errors: any,
    touched: any
    setFieldValue: any,
    values: any;
}

export const CondominiumPropertyExerciseEditionFormFields: FunctionComponent<Props> = ({
                                                                                   setFieldValue, values,
                                                                                   errors, touched
                                                                               }) => {

    return <>
        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="provision">Budget prévisionnel</FormLabel>
                    <Field className="form-control" id="provision" name="provision"
                           component={NumberFormField} style={{width: '100%'}}/>
                    {errors.provision && touched.provision ? (
                        <div className="error-message">{errors.provision}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>

        <FormGroup>
            <Row>
                <Col>
                    <FormLabel htmlFor="constructionFund">Fonds de travaux</FormLabel>
                    <Field className="form-control" id="constructionFund" name="constructionFund"
                           component={NumberFormField} style={{width: '100%'}}/>
                    {errors.constructionFund && touched.constructionFund ? (
                        <div className="error-message">{errors.constructionFund}</div>
                    ) : null}
                </Col>
            </Row>
        </FormGroup>

    </>
};
