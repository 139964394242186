import React, {FunctionComponent} from "react";
import Cleave from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.fr';

interface Props {
    field: any;
    form: any
}

const AsYouTypeFormatter = require('google-libphonenumber').AsYouTypeFormatter;
(window as any).Cleave = (window as any).Cleave || {};
(window as any).Cleave.AsYouTypeFormatter = AsYouTypeFormatter;

export const PhoneFormField: FunctionComponent<Props> = ({
                                  field, // { name, value, onChange, onBlur }
                                  form: { touched, errors }, ...props
                              }) => {
    return <div>
        <Cleave
            options={{phone: true, phoneRegionCode: 'FR'}}
            {...field} {...props} />
    </div>
};