import * as actionCreators from "../uicomponents/condominium-properties-selection/selectedCondominiumPropertyActionCreators";
import * as condoAddActionCreators from "../../../corelogic/usecases/condominiumpropertyaddition/actionCreators";

const selectCondominiumPropertyId = (
  state: { id: string; name: string; shares: number } | null = null,
  action: actionCreators.Actions | condoAddActionCreators.Actions
) => {
  switch (action.type) {
    case "SELECT_CONDOMINIUM_PROPERTY":
      return action.payload;
    case "CONDOMINIUM_PROPERTY_ADDED":
      const {
        condominiumPropertyId,
        condominiumPropertyName,
        condominiumPropertyShares,
      } = action.payload;
      return {
        id: condominiumPropertyId,
        name: "SDC " + condominiumPropertyName,
        shares: condominiumPropertyShares,
      };
    default:
      return state;
  }
};

export default selectCondominiumPropertyId;
