import {Axios} from 'axios-observable';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CoOwnerGateway} from '../../../corelogic/gateways/coOwnerGateway.interface';
import {CondominiumPropertyCoOwnerHeader} from '../../../corelogic/models/condominiumPropertyCoOwnerHeader';
import {CoOwnerDetails} from '../../../corelogic/models/coOwnerDetails';
import {CoOwnerLot} from '../../../corelogic/models/coOwnerLot';
import {CoOwner} from '../../../corelogic/models/write/coOwner.interface';

export class HttpCoOwnerGateway implements CoOwnerGateway {
    private static readonly CO_OWNERS_API_URL = process.env.REACT_APP_BASE_API_URL + '/coowners';
    private static readonly CONDOMINIUM_PROPERTY_CO_OWNERS_API_URL =
        process.env.REACT_APP_BASE_API_URL + '/condominiumproperties/';
    private static readonly ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY_API_URL =
        process.env.REACT_APP_BASE_API_URL + '/condominiumproperties/';

    retrieveCoOwnerDetailsById(coOwnerId: string, conjoint: boolean): Observable<CoOwnerDetails | null> {
        return Axios.get(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '?conjoint=' + conjoint).pipe(
            map(response => response?.data ?? null)
        );
    }

    retrieveCondominiumPropertyCoOwnersList(
        condominiumPropertyId: string
    ): Observable<CondominiumPropertyCoOwnerHeader[]> {
        return Axios.get(
            HttpCoOwnerGateway.CONDOMINIUM_PROPERTY_CO_OWNERS_API_URL + condominiumPropertyId + '/coowners'
        ).pipe(map(response => response?.data ?? null));
    }

    addCoOwner(condominiumPropertyId: string, coOwner: CoOwner): Observable<void> {
        return Axios.post(
            HttpCoOwnerGateway.ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY_API_URL + condominiumPropertyId + '/coowners',
            {coOwner}
        ).pipe(map(response => response?.data ?? null));
    }

    remove(coOwnerId: string): Observable<void> {
        return Axios.delete(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId, {
            params: {}
        }).pipe(map(response => response?.data ?? null));
    }

    addLots(
        coOwnerId: string,
        lots: { quota: number; lotNumber: string; lotType: string; building: string }[]
    ): Observable<void> {
        try {
            return Axios.post(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '/lots', {lots}, {}).pipe(
                map(response => response?.data ?? null)
            );
        } catch (e) {
            return EMPTY as Observable<void>;
        }
    }

    updateLot(coOwnerId: string, lotNumberReference: string,
              lot: {
                  quota: number;
                  lotNumber: string; lotType: string; building: string
              }): Observable<void> {
        try {
            return Axios.put(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '/lots/' +
                lotNumberReference, {lot}, {}).pipe(
                map(response => response?.data ?? null)
            );
        } catch (e) {
            return EMPTY as Observable<void>;
        }
    }

    removeLots(coOwnerId: string, lotNumbers: string[]): Observable<void> {
        return Axios.delete(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '/lots', {
            data: {coOwnerId, lotNumbers}
        }).pipe(map(response => response?.data ?? null));
    }

    retrieveCoOwnerLotsByCoOwnerId(coOwnerId: string): Observable<CoOwnerLot[]> {
        return Axios.get(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '/lots', {}).pipe(
            map(response => response?.data ?? null)
        );
    }

    editCoOwnerDetails(
        coOwnerId: string,
        field: string,
        conjoint: boolean,
        editedData: {
            email?: string;
            address?: { address: string; zipCode: string; city: string; country: string };
            phone?: string;
            lastName?: string;
            firstName?: string;
            gender?: string;
        }
    ): Observable<void> {
        return Axios.put(HttpCoOwnerGateway.CO_OWNERS_API_URL + '/' + coOwnerId + '/details', {
            coOwnerId,
            field,
            conjoint,
            editedData
        }).pipe(map(response => response?.data ?? null));
    }
}
