import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {SpecialKeyGateway} from "../../gateways/specialKeyGateway.interface";
import {map, switchMap} from "rxjs/operators";

export const associateSpecialKey = (action$: ActionsObservable<actionCreators.Actions>,
                                    state$: StateObservable<AppState>,
                                    {specialKeyGateway}: {
                                        specialKeyGateway: SpecialKeyGateway
                                    }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.associateSpecialKey>>(actionCreators.ASSOCIATE_SPECIAL_KEY),
        switchMap(action => {
            const {coOwnerId, lotNumber, specialKeyId, quota} = action.payload;
            return specialKeyGateway.associate(
                coOwnerId, lotNumber, specialKeyId, quota
            ).pipe(
                map(() => actionCreators.Actions.specialKeyAssociated(specialKeyId))
            );
        }));
}
