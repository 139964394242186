import React from "react";
import "./index.scss";
import { App } from "./adapters/primary/uicomponents/root/app.component";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createReduxStore } from "./store/store";
import { HttpCondominiumPropertyGateway } from "./adapters/secondary/real/httpCondominiumPropertyGateway";
import { HttpCallsForFundsGateway } from "./adapters/secondary/real/httpCallsForFundsGateway";
import { HttpCoOwnerGateway } from "./adapters/secondary/real/httpCoOwnerGateway";
import { render } from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { RealUuidGenerator } from "./corelogic/models/write/realUuidGenerator";
import { Security } from "@okta/okta-react";
import { LocalStoragePermanentDataStore } from "./adapters/secondary/permanentstore/localStoragePermanentDataStore";
import { HttpSpecialKeyGateway } from "./adapters/secondary/real/httpSpecialKeyGateway";
import { HttpCondominiumDetailsViewGateway } from "./adapters/secondary/real/httpCondominiumDetailsViewGateway";

const history = createBrowserHistory();

const callForFundsGateway = new HttpCallsForFundsGateway();
const uuidGenerator = new RealUuidGenerator();
const condominiumPropertyGateway = new HttpCondominiumPropertyGateway();
const coOwnerGateway = new HttpCoOwnerGateway();
const specialKeyGateway = new HttpSpecialKeyGateway();
const condominiumDetailsViewGateway = new HttpCondominiumDetailsViewGateway();
const permanentDataStore = new LocalStoragePermanentDataStore();
const store = createReduxStore({
  callForFundsGateway,
  condominiumPropertyGateway,
  coOwnerGateway,
  uuidGenerator,
  permanentDataStore,
  specialKeyGateway,
  condominiumDetailsViewGateway,
});

const authConfig = {
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  redirectUri: process.env.REACT_APP_OKTA_LOGIN_REDIRECT_URI,
  scopes: (process.env.REACT_APP_OKTA_SCOPES as string).split(/\s+/),
  pkce: true,
};

renderRootComponent();

function renderRootComponent() {
  render(
    <Provider store={store}>
      <Router history={history}>
        <Security {...authConfig}>
          <App />
        </Security>
      </Router>
    </Provider>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
