import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CallsForFundsSynthesis} from "../../models/callsForFundsSynthesis.interface";

export const RETRIEVE_CALLS_FOR_FUNDS = 'RETRIEVE_CALLS_FOR_FUNDS';
export const CALLS_FOR_FUNDS_RETRIEVED = 'CALLS_FOR_FUNDS_RETRIEVED';

export const Actions = {
    retrieveCallsForFunds: (condominiumPropertyId: string) =>
        createAction(RETRIEVE_CALLS_FOR_FUNDS, {condominiumPropertyId}),
    callsForFundsRetrieved: (callForFundsSynthesis: CallsForFundsSynthesis) =>
        createAction(CALLS_FOR_FUNDS_RETRIEVED, callForFundsSynthesis)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;