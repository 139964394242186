import * as React from "react";
import {FunctionComponent} from "react";
import {Col, Nav, Navbar, Row} from "react-bootstrap";
import {CondominiumPropertiesSelection} from "../../condominium-properties-selection/condominiumPropertiesSelection.component";
import {SignInButton} from "../sidebar/signInButton.component";

export const TopBar: FunctionComponent<{isUserAuthenticated: boolean}> = ({isUserAuthenticated}) => {

    return <>{<Navbar fixed="top">
        <Nav className="mr-auto" style={{width: '100%'}}>
            <Row style={{width: '100%', padding: 0, margin: 0}}>
                <Col className="text-left col-xs-9 col-sm-7 condominium-properties-selection">
                    {isUserAuthenticated && <CondominiumPropertiesSelection/>}
                </Col>
                <Col className="text-right col-xs-3 col-sm-5" style={{padding: 0, margin: 0}}>
                    <SignInButton/>
                </Col>
            </Row>
        </Nav>
        <Navbar.Toggle/>
        <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
            </Navbar.Text>
        </Navbar.Collapse>
    </Navbar>}</>;
};
