import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "../coownerlotmodification/actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const updateCoOwnerLot = (action$: ActionsObservable<actionCreators.Actions>,
                               state$: StateObservable<AppState>,
                               {coOwnerGateway}: {
                                   coOwnerGateway: CoOwnerGateway
                               }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.updateCoOwnerLot>>(actionCreators.UPDATE_CO_OWNER_LOT),
        switchMap(action => {
                return coOwnerGateway.updateLot(action.payload.coOwnerId, action.payload.lotNumberReference,
                    {quota: action.payload.quota, lotNumber: action.payload.lotNumber, lotType: action.payload.lotType,
                    building: action.payload.building}).pipe(
                    map(() => actionCreators.Actions.coOwnerLotUpdated(action.payload.coOwnerId, action.payload.lotNumber))
                )
            }
        )
    )
};
