import {Jumbotron} from "react-bootstrap";
import React from "react";

export const PaymentDone = () => {
    return <Jumbotron>
        <h1>Paiement effectué !</h1>
        <p>
            Vous venez de vous acquitter de votre appel de fond et nous vous en remercions !<br/>
            Vous allez recevoir par e-mail votre facture dans quelques instants.
        </p>
        <p>
        </p>
    </Jumbotron>
}