import * as React from "react";
import {FunctionComponent, useState} from "react";
import PlacesAutocomplete from 'react-places-autocomplete';

interface Props {
    field: any;
    form: any;
    className: string;
}

export const GooglePlacesAutocompleteField: FunctionComponent<Props> = ({
                                                                            field,
                                                                            form: {touched, errors, values, setFieldValue, setFieldTouched},
                                                                            ...props
                                                                        }) => {
    const [address, setAddress] = useState('');

    const handleChange = (address: string) => {
        setAddress(address);
        setFieldValue('address', address || '');
    };

    const handleSelect = (address: string) => {
        setAddress(address);
        setFieldValue('address', address || '');
    };

    return <PlacesAutocomplete
        value={address ? address : field.value || ''}
        onChange={handleChange}
        onSelect={handleSelect}
        debounce={300}
        searchOptions={{
            componentRestrictions: {
                country: 'fr'
            }
        }
        }
    >
        {({getInputProps, suggestions, getSuggestionItemProps}: any) => (
            <div>
                <input
                    {...getInputProps({
                        placeholder: '',
                        className: props.className
                    })}

                />
                <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion: any) => {
                        const className = suggestion.active
                            ? 'google-autocomplete-suggestions-item'
                            : 'google-autocomplete-suggestions-item';
                        // inline style for demonstration purpose
                        const style = suggestion.active
                            ? {backgroundColor: '#fafafa', cursor: 'pointer'}
                            : {backgroundColor: '#ffffff', cursor: 'pointer'};
                        return (
                            <div
                                {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                })}
                                key={suggestion.placeId}
                            >
                                <span>{suggestion.description}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        )}
    </PlacesAutocomplete>
};