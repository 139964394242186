import * as React from "react";
import {CallsForFundsSynthesis} from "../../../../../../corelogic/models/callsForFundsSynthesis.interface";
import {formatDate} from "../../../../utils/formatDate";
import {Card} from "react-bootstrap";
import {BudgetIndicator} from "./budgetIndicator.component";

interface Props {
    callsForFundsSynthesis: CallsForFundsSynthesis
}

export const ExerciceIndicator = React.memo<Props>(({callsForFundsSynthesis}) => {
    return <Card>
        <Card.Header className="text-left">
            <div><i className="fa fa-calendar-alt"/>&nbsp;&nbsp;Exercice</div>
            <div style={{fontSize: 14}}>
                {formatDate(callsForFundsSynthesis.exercice.startDate)}&nbsp;-&nbsp;
                {formatDate(callsForFundsSynthesis.exercice.endDate)}</div>
        </Card.Header>
        <Card.Body className="text-left">
            <BudgetIndicator callsForFundsSynthesis={callsForFundsSynthesis}/>
        </Card.Body>
    </Card>
});