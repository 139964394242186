import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const REMOVE_CO_OWNER = 'REMOVE_CO_OWNER';
export const CO_OWNER_REMOVED = 'CO_OWNER_REMOVED';

export const Actions = {
    removeCoOwner: (coOwnerId: string) =>
        createAction(REMOVE_CO_OWNER, {coOwnerId}),
    coOwnerRemoved: (coOwnerId: string) =>
        createAction(CO_OWNER_REMOVED, {coOwnerId}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
