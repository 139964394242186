import React, {Component} from "react";
import "./index.scss";
import {Col, Row} from "react-bootstrap";
import {NewCondominiumPropertyButton} from "./newCondominiumPropertyButton.component";
import {CondominiumPropertyEditionModal} from "../condominiumpropertymanagementscreen/condominiumpropertyedition";
import {AppState} from "../../../../../store/appState.interface";
import * as condominiumPropertiesActionCreators
    from "../../../../../corelogic/usecases/condominiumpropertiesretrieval/actionCreators";
import {connect} from "react-redux";
import {CondominiumProperty} from "../../../../../corelogic/models/condominiumProperty.interface";
import {CondominiumPropertiesTable} from "./condominiumPropertiesTable.component";
import {CustomSpinner} from "../../layout/progresscomponents/spinners/customSpinner.component";
import {CondominiumPropertyExerciseEditionModal} from "./condominiumpropertyexerciseedition";
import {getCondominiumPropertyAddition} from "../coownersmanagementscreen/coowneredition/selectors";
import {createSelector} from "reselect";
import {UserAuthenticationData} from "../../../../../corelogic/models/userAuthenticationData.interface";

export const getCondominiumProperties = createSelector(
    (state: AppState) => state.coreLogicState.condominiumProperties.data,
    (state: AppState) => state.coreLogicState.condominiumProperties.fetching,
    (data, fetching) => ({data, fetching})
);

interface Props {
    retrieveCondominiumProperties: typeof condominiumPropertiesActionCreators.Actions.retrieveCondominiumProperties;
    condominiumProperties: { data: CondominiumProperty[] | null, fetching: boolean };
    condominiumPropertyAddition: {
        adding: boolean,
        justAdded: { id: string, name: string } | null
    },
    selectedCondominiumProperty: CondominiumProperty | undefined,
    authenticatedUser: UserAuthenticationData | null;
    hasRetrievedCondo: boolean;
}

interface State {
}


class CondominiumPropertyManagement extends Component<Props, State> {

    state = {
        shouldShowCondominiumPropertyEdition: false,
        shouldShowCondominiumPropertyExerciseEdition: false,
        condominiumPropertyForExerciseAddition: {
            name: '',
            id: ''
        },
        hasRetrievedCondo: false
    };

    componentDidMount() {
        if (this.props.authenticatedUser) {
            this.setState({hasRetrievedCondo: true});
            this.props.retrieveCondominiumProperties();
        }
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (!this.state.hasRetrievedCondo && this.props.authenticatedUser) {
            this.props.retrieveCondominiumProperties();
            this.setState({hasRetrievedCondo: true});
        }
        if (prevProps && prevProps.condominiumPropertyAddition.justAdded?.id !==
            this.props.condominiumPropertyAddition.justAdded?.id) {
            this.setState({
                condominiumPropertyForExerciseAddition: {
                    id: this.props.condominiumPropertyAddition.justAdded?.id,
                    name: this.props.condominiumPropertyAddition.justAdded?.name,
                },
                shouldShowCondominiumPropertyExerciseEdition: true
            });
        }
    }

    removeCondominiumProperty = (condominiumPropertyId: string) => () => {
        alert(condominiumPropertyId + ' is removed');
    }

    showCondominiumPropertyEdition = () => {
        this.setState({
            shouldShowCondominiumPropertyEdition: true
        });
    };

    showCondominiumPropertyExerciseEdition = (condominiumProperty: CondominiumProperty) => {
        this.setState({
            condominiumPropertyForExerciseAddition: {
                id: condominiumProperty.id,
                name: condominiumProperty.name
            },
            shouldShowCondominiumPropertyExerciseEdition: true
        });
    };

    closeCondominiumPropertyEdition = () => {
        this.setState({shouldShowCondominiumPropertyEdition: false});
    };

    closeCondominiumPropertyExerciseEdition = () => {
        this.setState({shouldShowCondominiumPropertyExerciseEdition: false});
    };

    refreshContent = () => {
        this.props.retrieveCondominiumProperties();
    }

    render() {
        if (!this.props.condominiumProperties.data || this.props.condominiumProperties.fetching || !this.props.authenticatedUser)
            return <CustomSpinner/>;
        return <div className="condominium-property-management">
            <Row>
                <Col className="text-right" style={{paddingRight: '2rem'}}>
                    <NewCondominiumPropertyButton action={this.showCondominiumPropertyEdition}/>
                </Col>
            </Row>
            {this.props.condominiumProperties.data.length > 0 &&
            <div>
                <CondominiumPropertiesTable condominiumProperties={this.props.condominiumProperties.data}
                                            selectedCondominiumProperty={this.props.selectedCondominiumProperty}
                                            removeCondominiumProperty={this.removeCondominiumProperty}
                                            showCondominiumPropertyExerciseEdition={this.showCondominiumPropertyExerciseEdition}/>
            </div>}
            {this.props.condominiumProperties.data.length === 0 && <Row>
                <Col className="text-center">
                    <p>Vous n'avez pas encore configuré de copropriétés.</p>
                </Col>
            </Row>}
            {this.state.shouldShowCondominiumPropertyEdition &&
            <CondominiumPropertyEditionModal onClose={this.closeCondominiumPropertyEdition}
                                             refreshContent={this.refreshContent}/>}
            {(this.state.shouldShowCondominiumPropertyExerciseEdition) &&
            <CondominiumPropertyExerciseEditionModal onClose={this.closeCondominiumPropertyExerciseEdition}
                                                     refreshContent={this.refreshContent}
                                                     condominiumPropertyId={this.state.condominiumPropertyForExerciseAddition.id}
                                                     condominiumPropertyName={this.state.condominiumPropertyForExerciseAddition.name}/>
            }
        </div>
    }

}

const mapStateToProps = (state: AppState) => ({
    authenticatedUser: state.coreLogicState.authenticatedUser,
    condominiumProperties: getCondominiumProperties(state),
    condominiumPropertyAddition: getCondominiumPropertyAddition(state),
    selectedCondominiumProperty: state.coreLogicState.condominiumProperties.data
        ?.find(condominiumProperty =>
            condominiumProperty.id === state.uiState.selectedCondominiumProperty?.id)
});

const mapDispatchToProps = {
    retrieveCondominiumProperties: condominiumPropertiesActionCreators.Actions.retrieveCondominiumProperties
};

export default connect(mapStateToProps, mapDispatchToProps)(CondominiumPropertyManagement)
