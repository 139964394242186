import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CoOwnerLot} from "../../models/coOwnerLot";

export const RETRIEVE_CO_OWNER_LOTS = 'RETRIEVE_CO_OWNER_LOTS';
export const CO_OWNER_LOTS_RETRIEVED = 'CO_OWNER_LOTS_RETRIEVED';

export const Actions = {
    retrieveCoOwnerLots: (coOwnerId: string) =>
        createAction(RETRIEVE_CO_OWNER_LOTS, {coOwnerId}),
    coOwnerLotsRetrieved: (coOwnerLots: CoOwnerLot[] | null) =>
        createAction(CO_OWNER_LOTS_RETRIEVED, coOwnerLots)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;