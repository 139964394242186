import {combineReducers} from 'redux';
import * as actionCreators from '../usecases/coownerlotsremoval/actionCreators';

const justRemoved = (
    state: { coOwnerId: string; lotNumbers: string[] } | null = null,
    action: actionCreators.Actions
): typeof state => {
    switch (action.type) {
        case actionCreators.CO_OWNER_LOTS_REMOVED:
            return action.payload;
        case actionCreators.CLEAR_DATA_ABOUT_CO_OWNER_LOTS_REMOVAL:
            return null;
        default:
            return state;
    }
};

const removing = (state: boolean = false, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_LOTS_REMOVED:
            return false;
        case actionCreators.REMOVE_CO_OWNER_LOTS:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    removing,
    justRemoved
});
