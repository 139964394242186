import {Observable, of} from "rxjs";
import Axios from "axios-observable";
import {switchMap} from "rxjs/operators";
import {CallsForFundsSynthesis} from "../../../corelogic/models/callsForFundsSynthesis.interface";
import {CallForFundsGateway} from "../../../corelogic/gateways/callForFundsGateway.interface";

export class HttpCallsForFundsGateway implements CallForFundsGateway {

    private static readonly CALLS_FOR_FUNDS_API_URL = process.env.REACT_APP_BASE_API_URL + '/callsforfunds';

    retrieveSynthesis(condominiumPropertyId: string): Observable<CallsForFundsSynthesis> {
        return Axios.get(HttpCallsForFundsGateway.CALLS_FOR_FUNDS_API_URL, {
            params: {
                condominiumPropertyId
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).pipe(
            switchMap(response => {
                return of(response ? response.data : []);
            })
        )
    }

    launchNewCallForFunds(condominiumPropertyId: string, callForFundsId: string): Observable<void> {
        try {
            return Axios.post(
                process.env.REACT_APP_BASE_API_URL + '/condominiumproperties/' +
                condominiumPropertyId + '/callsforfunds',
                {id: callForFundsId}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).pipe(
                switchMap(response => {
                    return of(response ? response.data : null);
                })
            )
        } catch (e) {
            return of() as Observable<void>;
        }
    }

    cashCoOwnerMoney(callForFundsAssignmentId: string, amount: number, cashingDate: Date): Observable<void> {
        try {
            return Axios.post(
                process.env.REACT_APP_BASE_API_URL + '/callsforfunds/assignments/' +
                callForFundsAssignmentId + '/payments/manual',
                {amount, cashingDate}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).pipe(
                switchMap(response => {
                    return of(response ? response.data : null);
                })
            )
        } catch (e) {
            return of() as Observable<void>;
        }
    }

}
