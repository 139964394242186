import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CoOwnerDetailsEditionCommand} from "./coOwnerDetailsEditionCommand";

export const EDIT_CO_OWNER_DETAILS = 'EDIT_CO_OWNER_DETAILS';
export const CO_OWNER_DETAILS_EDITED = 'CO_OWNER_DETAILS_EDITED';

export const Actions = {
    editCoOwnerDetails: (command: CoOwnerDetailsEditionCommand) =>
        createAction(EDIT_CO_OWNER_DETAILS, {command}),
    coOwnerDetailsEdited: (coOwnerId: string, field: 'EMAIL' | 'ADDRESS' | 'PHONE' | 'LAST_NAME' | 'FIRST_NAME' | 'GENDER') =>
        createAction(CO_OWNER_DETAILS_EDITED, {coOwnerId, field}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
