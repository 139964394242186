import {createSelector} from "reselect";
import {AppState} from "../../../../../store/appState.interface";
import {CallForFunds} from "../../../../../corelogic/models/callsForFundsSynthesis.interface";
import {CallForFundsAssignment} from "../../../../../corelogic/models/callForFundsAssignments.interface";

const missingPayment = (assignment: CallForFundsAssignment) =>
    assignment.paid;

const sortAssignmentsByMissingPayments = (assignments: CallForFundsAssignment[]) =>
    assignments
        .sort((assignment1, assignment2) =>
            missingPayment(assignment1) - missingPayment(assignment2)
        );

const orderedCallsForFunds = (callsForFunds: CallForFunds[]) =>
    [...callsForFunds].reverse().map(callForFunds => ({
            ...callForFunds,
            assignments: sortAssignmentsByMissingPayments(
                [...callForFunds.assignments])
        })
    );

export const getCallsForFundsSynthesis = createSelector(
    (state: AppState) => state.coreLogicState.callsForFundsSynthesis,
    callsForFundsSynthesis => ({
        data: {
            ...callsForFundsSynthesis.data,
            callsForFunds: orderedCallsForFunds(callsForFundsSynthesis.data.callsForFunds),
        },
        numberOfCallForFunds: callsForFundsSynthesis.data.callsForFunds.length > 0 ?
            callsForFundsSynthesis.data.callsForFunds[callsForFundsSynthesis.data.callsForFunds.length - 1].context.numberOfCall : 0,
        currentlyPaid:
            +(callsForFundsSynthesis.data.callsForFunds.reduce((acc, callForFunds) => {
                return acc + callForFunds.assignments.reduce((acc2, assignment) => {
                    return assignment.paid + acc2;
                }, 0)
            }, 0).toFixed(2)),
        delays: (() => {
            const callsForFunds = orderedCallsForFunds(callsForFundsSynthesis.data.callsForFunds);
            const nullDelays = {
                numberOfDelays: 0,
                amount: 0
            };
            if (callsForFunds.length === 0)
                return nullDelays;
            return callsForFunds[0].assignments.reduce((acc, assignment) =>
                    assignment.acquitted ? acc : {
                        numberOfDelays: acc.numberOfDelays + 1,
                        amount: +(acc.amount + assignment.balanceAfter)
                            .toFixed(2)
                    }
                , nullDelays)
        })(),
        fetching: callsForFundsSynthesis.fetching,
        launchingCallForFunds: callsForFundsSynthesis.launchingNewCallForFunds,
        newCallForFundsJustLaunched: callsForFundsSynthesis.newCallForFundsJustLaunched
    })
);