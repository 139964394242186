import { createAction } from "../../../../store/createAction";
import { ActionsUnion } from "../../../../store/actionsUnions";

export const SELECT_CONDOMINIUM_PROPERTY = "SELECT_CONDOMINIUM_PROPERTY";

export const Actions = {
  selectCondominiumProperty: (id: string, name: string, shares: number) =>
    createAction(SELECT_CONDOMINIUM_PROPERTY, { id, name, shares }),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
