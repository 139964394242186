import {combineReducers} from 'redux';
import * as actionCreators from '../usecases/coownerlotmodification/actionCreators';

const justUpdated = (
    state: { coOwnerId: string; lotNumber: string } | null = null,
    action: actionCreators.Actions
): typeof state => {
    switch (action.type) {
        case actionCreators.CO_OWNER_LOT_UPDATED:
            return action.payload;
        case actionCreators.CLEAR_DATA_ABOUT_CO_OWNER_LOT_UPDATE:
            return null;
        default:
            return state;
    }
};

const updating = (state: boolean = false, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_LOT_UPDATED:
            return false;
        case actionCreators.UPDATE_CO_OWNER_LOT:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    updating,
    justUpdated
});
