import {combineReducers} from "redux";
import * as actionCreators from "../usecases/condominiumpropertyexerciseaddition/actionCreators";

const justAdded = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CONDOMINIUM_PROPERTY_EXERCISE_ADDED:
            return action.payload.condominiumPropertyExerciseId;
        default:
            return state;
    }
};

const adding = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.ADD_CONDOMINIUM_PROPERTY_EXERCISE;
};

export default combineReducers({
    adding,
    justAdded
});
