import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const ADD_CO_OWNER_LOTS = 'ADD_CO_OWNER_LOTS';
export const CO_OWNER_LOTS_ADDED = 'CO_OWNER_LOTS_ADDED';
export const CLEAR_DATA_ABOUT_CO_OWNER_LOTS_ADDITION = 'CLEAR_DATA_ABOUT_CO_OWNER_LOTS_ADDITION';

export const Actions = {
    addCoOwnerLots: (coOwnerId: string) =>
        createAction(ADD_CO_OWNER_LOTS, {coOwnerId}),
    coOwnerLotsAdded: (coOwnerId: string, lotNumber: string) =>
        createAction(CO_OWNER_LOTS_ADDED, {coOwnerId, lotNumber}),
    clearDataAboutCoOwnerLotsAddition: () => createAction(CLEAR_DATA_ABOUT_CO_OWNER_LOTS_ADDITION)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
