import * as React from "react";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CondominiumPropertiesWidget } from "./condominiumPropertiesWidget.component";
import * as selectCondominiumPropertyActionCreators from "./selectedCondominiumPropertyActionCreators";

import * as condominiumPropertiesActionCreators from "../../../../corelogic/usecases/condominiumpropertiesretrieval/actionCreators";
import { AppState } from "../../../../store/appState.interface";

export const CondominiumPropertiesSelection = () => {
  const dispatch = useDispatch();

  const condominiumProperties = useSelector(
    (state: AppState) => state.coreLogicState.condominiumProperties
  );

  const onSelectedCondominiumProperty = useCallback(
    (condominiumPropertyId: string, name: string, shares: number) => {
      dispatch(
        selectCondominiumPropertyActionCreators.Actions.selectCondominiumProperty(
          condominiumPropertyId,
          name,
          shares
        )
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(
      condominiumPropertiesActionCreators.Actions.retrieveCondominiumProperties()
    );
  }, [dispatch]);

  return (
    <>
      {condominiumProperties.data && (
        <CondominiumPropertiesWidget
          condominiumProperties={condominiumProperties.data}
          onCondominiumSelected={onSelectedCondominiumProperty}
        />
      )}
    </>
  );
};
