import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const UPDATE_CO_OWNER_LOT = 'UPDATE_CO_OWNER_LOT';
export const CO_OWNER_LOT_UPDATED = 'CO_OWNER_LOT_UPDATED';
export const CLEAR_DATA_ABOUT_CO_OWNER_LOT_UPDATE = 'CLEAR_DATA_ABOUT_CO_OWNER_LOT_UPDATE';

export const Actions = {
    updateCoOwnerLot: (coOwnerId: string, lotNumberReference: string,
                       quota: number, lotNumber: string, lotType: string, building: string) =>
        createAction(UPDATE_CO_OWNER_LOT, {coOwnerId, lotNumberReference, quota, lotNumber, lotType, building}),
    coOwnerLotUpdated: (coOwnerId: string, lotNumber: string) =>
        createAction(CO_OWNER_LOT_UPDATED, {coOwnerId, lotNumber}),
    clearDataAboutCoOwnerLotUpdate: () => createAction(CLEAR_DATA_ABOUT_CO_OWNER_LOT_UPDATE)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
