import React, {FunctionComponent} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import fr from 'date-fns/locale/fr';
registerLocale('fr', fr)


interface Props {
    field: any;
    form: any
}

export const DatePickerField: FunctionComponent<Props> = ({
                                                              field,
                                                              form: {
                                                                  touched,
                                                                  errors,
                                                                  values,
                                                                  setFieldValue,
                                                                  setFieldTouched
                                                              },
                                                              ...props
                                                          }) => {
    return (
        <DatePicker
            {...field}
            {...props}
            dateFormat="dd/MM/yyyy"
            selected={(field.value && new Date(field.value)) || (props as any).selected}
            onChange={val => {
                setFieldValue(field.name, val);
            }}
            maxDate={new Date()}
            wrapperClassName="date-picker"
            locale="fr"
        />
    );
}