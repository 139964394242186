import * as React from "react";
import {Col, Container, Row} from "react-bootstrap";

export const CustomSpinner = () => {
    return <Container>
        <Row className="justify-md-center">
            <Col className="text-center cssload-container">
                <div className="cssload-zenith"/>
            </Col>
        </Row>
    </Container>
};