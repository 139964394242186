import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {CondominiumPropertyGateway} from "../../gateways/condominiumPropertyGateway.interface";
import {map, switchMap} from "rxjs/operators";
import {UuidGenerator} from "../../models/write/uuidGenerator.interface";

export const addCondominiumPropertyExercise = (action$: ActionsObservable<actionCreators.Actions>,
                                               state$: StateObservable<AppState>,
                                               {condominiumPropertyGateway, uuidGenerator}: {
                                           condominiumPropertyGateway: CondominiumPropertyGateway,
                                           uuidGenerator: UuidGenerator
                                       }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.addCondominiumExerciseProperty>>(actionCreators.ADD_CONDOMINIUM_PROPERTY_EXERCISE),
        // debounceTime(200),
        switchMap(action => {
                const condominiumPropertyExerciseId = uuidGenerator.generate();
                return condominiumPropertyGateway.saveExercise({
                    id: condominiumPropertyExerciseId,
                    condominiumPropertyId: action.payload.condominiumPropertyExerciseCommand.condominiumPropertyId,
                    provision: action.payload.condominiumPropertyExerciseCommand.provision,
                    constructionFund: action.payload.condominiumPropertyExerciseCommand.constructionFund
                }).pipe(
                    map(() => actionCreators.Actions.condominiumPropertyExerciseAdded(condominiumPropertyExerciseId))
                )
            }
        )
    )
};
