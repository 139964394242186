import * as Yup from "yup";
import React, {FunctionComponent} from "react";
import {Form, Formik} from "formik";
import {CoOwnerContactDataFormFields} from "./coOwnerContactDataFormFields.component";
import {AddedCoOwnerContactData} from "../../../../../../../corelogic/usecases/coowneraddition/addedCoOwnerContactData";
import {Col, Row} from "react-bootstrap";

const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

const conjointCoOwnerSchema = Yup.object().shape({
    gender: Yup.string().required('Le genre est requis.'),
    firstName: Yup.string().required('Le prénom est requis.'),
    lastName: Yup.string().required('Le nom est requis.'),
    address: Yup.string().required('L\'adresse est requise.'),
    email: Yup.string()
        .email('L\'e-mail n\'est pas valide.')
        .required('L\'e-mail est requis.'),
    phone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide.')
});

interface Props {
    goPreviousFn: (values: any) => void;
    onComplete: (values: any, fromConjoint: boolean) => void;
    coOwnerContactData: AddedCoOwnerContactData | undefined;
}

export const ConjointCoOwnerEditionForm: FunctionComponent<Props> = ({goPreviousFn, onComplete, coOwnerContactData}) => {

    const goPrevious = (values: any) => {
        goPreviousFn(values);
    }

    const handleSubmit = (values: any) => {
        onComplete(values, true);
    };

    const FormComponent = ({errors, touched, setFieldValue, values}: {
        errors: any,
        touched: any
        setFieldValue: any,
        values: any
    }) => {
        return <Form className="co-owner-edition-form">
            <CoOwnerContactDataFormFields errors={errors} touched={touched}
                                          setFieldValue={setFieldValue} values={values}/>
            <div className="form-buttons-wrapper">
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0, textAlign: 'left'}}>
                        <button type="button" className="btn btn-primary"
                                onClick={() => goPrevious(values)}>
                            Annuler
                        </button>
                    </Col>
                    <Col style={{margin: 0, padding: 0, textAlign: 'right'}}>
                        <button type="submit" className="btn btn-primary">
                            Valider
                        </button>
                    </Col>
                </Row>
            </div>
        </Form>;
    };

    return <div className="contact-form gray-light-bg">
        <Formik
            enableReinitialize={true}
            initialValues={coOwnerContactData ? {...coOwnerContactData} : {
                gender: undefined,
                email: '',
                address: '',
                phone: '',
                firstName: '',
                lastName: ''
            }}
            validationSchema={conjointCoOwnerSchema}
            onSubmit={handleSubmit}
        >
            {({errors, touched, setFieldValue, values}) => {
                return <>
                    <FormComponent errors={errors} touched={touched} setFieldValue={setFieldValue}
                                   values={values}/>
                </>
            }}
        </Formik>
    </div>
};