import * as React from "react";
import {Card, Table} from "react-bootstrap"
import {CondominiumProperty} from "../../../../../corelogic/models/condominiumProperty.interface";
import {format} from "date-fns";

interface Props {
    condominiumProperties: CondominiumProperty[],
    selectedCondominiumProperty: CondominiumProperty | undefined,
    showCondominiumPropertyExerciseEdition: (condominiumProperty: CondominiumProperty) => void,
    removeCondominiumProperty: (condominiumPropertyId: string) => () => void;
}

export const CondominiumPropertiesTable = React.memo<Props>(({
                                                                 condominiumProperties,
                                                                 selectedCondominiumProperty,
                                                                 showCondominiumPropertyExerciseEdition,
                                                                 removeCondominiumProperty
                                                             }) => {
    return <Card>
        <Card.Body>
            <Table responsive className="condominium-properties-table">
                <thead className="first-level-head">
                <tr>
                    <th>Copropriété</th>
                    <th>Tantièmes</th>
                    <th>Exercice</th>
                </tr>
                </thead>
                <tbody className="first-level-body">
                {condominiumProperties.map(condominiumProperty => {
                    return <tr key={condominiumProperty.id} style={{textAlign: 'center'}}>
                        <td>
                            <div className="condominium-property-name">
                                {condominiumProperty.name}
                            </div>
                        </td>
                        <td>
                            {condominiumProperty.shares}
                        </td>
                        <td>
                            {condominiumProperty.currentExercise?.startDate ?
                                <p>{format(new Date(condominiumProperty.currentExercise.startDate), 'dd/MM/yyyy')} - {format(new Date(condominiumProperty.currentExercise.endDate), 'dd/MM/yyyy')}</p>
                                : <p>Aucun exercice ajouté</p>}
                            <a href="/#" className="condominium-property-new-exercise-action" onClick={event => {
                                event.preventDefault();
                                showCondominiumPropertyExerciseEdition(condominiumProperty)
                            }}>Nouvel exercice</a>
                        </td>
                        <td>
                            <i className="fas fa-trash" style={{color: 'red'}}
                               onClick={removeCondominiumProperty(condominiumProperty.id)}/>
                        </td>
                    </tr>
                })
                }
                </tbody>
            </Table>
        </Card.Body>
    </Card>
});
