import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const ADD_SPECIAL_KEY = 'ADD_SPECIAL_KEY';
export const SPECIAL_KEY_ADDED = 'SPECIAL_KEY_ADDED';
export const CLEAR_SPECIAL_KEY_ADDITION = 'CLEAR_SPECIAL_KEY_ADDITION';

export const Actions = {
    addSpecialKey: (specialKey: {name: string, shares: number}) =>
        createAction(ADD_SPECIAL_KEY, {specialKey}),
    specialKeyAdded: (specialKeyId: string) =>
        createAction(SPECIAL_KEY_ADDED, {specialKeyId}),
    clearSpecialKeyAddition: () =>
        createAction(CLEAR_SPECIAL_KEY_ADDITION),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;