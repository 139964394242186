import { createSelector } from "reselect";
import { AppState } from "../../../../../../store/appState.interface";
import { CoOwnersDetailsView } from "../../../../../../corelogic/usecases/condominiumdetailsview/coOwnersDetailsView";

export const sumOfShares = createSelector(
  (state: AppState) => state.coreLogicState.coOwnersDetailsView.data,
  (coOwnersDetailsView: CoOwnersDetailsView | null) => {
    if (!coOwnersDetailsView) return 0;
    return Object.values(coOwnersDetailsView).reduce(
      (acc: number, c: CoOwnersDetailsView["Content"]) =>
        c.coOwnerMainQuota + acc,
      0
    );
  }
);

export const availableShares = createSelector(
  (state: AppState) => state.coreLogicState.condominiumProperties.data,
  sumOfShares,
  (state: AppState) => state.uiState.selectedCondominiumProperty,
  (condominiumProperties, sum, selectedCondominiumProperty) => {
    const condominiumProperty = condominiumProperties?.find(
      (condominiumProperty) =>
        condominiumProperty.id === selectedCondominiumProperty?.id
    );
    return condominiumProperty ? condominiumProperty.shares - sum : 0;
  }
);

export const getCoOwnersSumOfShare = createSelector(
  (state: AppState) => state.coreLogicState.coOwnersDetailsView.data,
  (coOwnersDetailsViewData) => {
    if (!coOwnersDetailsViewData) return { sumOfShares: 0 };
    return {
      sumOfShares: Object.values(coOwnersDetailsViewData).reduce(
        (acc, c) => acc + c.coOwnerMainQuota,
        0
      ),
    };
  }
);

export const getCondominiumCoOwnersList = createSelector(
  (state: AppState) =>
    state.coreLogicState.condominiumPropertyCoOwnersList.data,
  (state: AppState) =>
    state.coreLogicState.condominiumPropertyCoOwnersList.fetching,
  sumOfShares,
  (condominiumPropertyCoOwnersList, fetching, sumOfShares) => {
    return {
      list: condominiumPropertyCoOwnersList.map((c) => ({
        ...c,
        coOwnerPerson: {
          ...c.coOwnerPerson,
          lastName: c.coOwnerPerson.lastName.toUpperCase(),
        },
        coOwnerPersonConjoint: c.coOwnerPersonConjoint
          ? {
              ...c.coOwnerPersonConjoint,
              lastName: c.coOwnerPersonConjoint.lastName.toUpperCase(),
            }
          : null,
      })),
      sumOfShares,
      fetching,
    };
  }
);

export const getCoOwnerAddition = createSelector(
  (state: AppState) => state.uiState.coOwnersAddition,
  (state: AppState) => state.coreLogicState.coOwnerAddition,
  ({ quota, coOwnersContactData }, { adding, justAdded }) => {
    return {
      quota,
      coOwnersContactData,
      adding,
      justAdded,
    };
  }
);

export const getCondominiumPropertyAddition = createSelector(
  (state: AppState) => state.coreLogicState.condominiumPropertyAddition,
  ({ adding, justAdded }) => {
    return {
      adding,
      justAdded,
    };
  }
);

export const getCondominiumPropertyExerciseAddition = createSelector(
  (state: AppState) => state.coreLogicState.condominiumPropertyExerciseAddition,
  ({ adding, justAdded }) => {
    return {
      adding,
      justAdded,
    };
  }
);
