import * as React from "react";
import {Component} from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {AppState} from "../../../../../../store/appState.interface";
import {CoOwnerEditionForm} from "./form/coOwnerEditionForm.component";
import {availableShares, getCoOwnerAddition} from "./selectors";
import {ConjointCoOwnerEditionForm} from "./form/conjointCoOwnerEditionForm.component";
import * as actionCreators from "./form/actionsCreators";
import * as addCoOwnerToCondominiumPropertyActionCreators
    from "../../../../../../corelogic/usecases/coowneraddition/actionCreators"
import {AddedCoOwnerContactData} from "../../../../../../corelogic/usecases/coowneraddition/addedCoOwnerContactData";
import {CustomSpinner} from "../../../layout/progresscomponents/spinners/customSpinner.component";

interface Props {
    show: boolean;
    onClose: () => void;
    availableShares: number;
    addCoOwner: typeof actionCreators.Actions.addCoOwner,
    addCoOwnerConjoint: typeof actionCreators.Actions.addCoOwnerConjoint,
    addCoOwnerToCondominiumProperty: typeof addCoOwnerToCondominiumPropertyActionCreators.Actions.addCoOwnerToCondominiumProperty,
    coOwnerAddition: {
        quota: number,
        coOwnersContactData: AddedCoOwnerContactData[]
        adding: boolean;
        justAdded: string | null;
    },
    refreshContent: () => void
}

interface State {
    page: number;
}

class CoOwnerEditionModal extends Component<Props, State> {

    state = {
        page: 1
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps && prevProps.coOwnerAddition.justAdded !== this.props.coOwnerAddition.justAdded) {
            this.handleClose();
            this.props.refreshContent();
        }
    }

    onComplete = (values: any, fromConjoint: boolean) => {
        if (fromConjoint)
            this.props.addCoOwnerConjoint({
                gender: values.gender,
                firstName: values.firstName,
                lastName: values.lastName,
                address: values.address,
                email: values.email,
                phone: values.phone
            } as AddedCoOwnerContactData);
        else
            this.props.addCoOwner(values.quota, {
                gender: values.gender,
                firstName: values.firstName,
                lastName: values.lastName,
                address: values.address,
                email: values.email,
                phone: values.phone,
            } as AddedCoOwnerContactData);
        this.props.addCoOwnerToCondominiumProperty()
    };

    onAddConjoint = (values: any) => {
        this.props.addCoOwner(values.quota, {
            gender: values.gender,
            firstName: values.firstName,
            lastName: values.lastName,
            address: values.address,
            email: values.email,
            phone: values.phone
        } as AddedCoOwnerContactData);
        this.setState(state => ({page: state.page + 1}));
    }

    goToPrevious = (values: any) => {
        this.props.addCoOwnerConjoint({
            gender: values.gender,
            firstName: values.firstName,
            lastName: values.lastName,
            address: values.address,
            email: values.email,
            phone: values.phone
        } as AddedCoOwnerContactData);
        this.setState(state => ({page: state.page - 1}));
    };

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        if (!this.props.show)
            return null;
        return <Modal show={true} onHide={this.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.state.page === 1 ? 'Nouveau copropriétaire' : 'Ajout d\'un conjoint'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.coOwnerAddition.adding && <CustomSpinner />}
                {!this.props.coOwnerAddition.adding && this.state.page === 1 &&
                    <CoOwnerEditionForm availableShares={this.props.availableShares}
                                        onAddConjoint={this.onAddConjoint}
                                        onComplete={this.onComplete}
                                        quota={this.props.coOwnerAddition.quota}
                                        coOwnerContactData={this.props.coOwnerAddition.coOwnersContactData[0]}/>}
                {!this.props.coOwnerAddition.adding && this.state.page > 1 && <ConjointCoOwnerEditionForm goPreviousFn={this.goToPrevious}
                                                onComplete={this.onComplete}
                                                coOwnerContactData={this.props.coOwnerAddition.coOwnersContactData[1]}/>
                }
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = (state: AppState) => ({
    availableShares: availableShares(state),
    coOwnerAddition: getCoOwnerAddition(state)
});

const mapDispatchToProps = {
    addCoOwner: actionCreators.Actions.addCoOwner,
    addCoOwnerConjoint: actionCreators.Actions.addCoOwnerConjoint,
    addCoOwnerToCondominiumProperty: addCoOwnerToCondominiumPropertyActionCreators.Actions.addCoOwnerToCondominiumProperty
};

export default connect(mapStateToProps, mapDispatchToProps)(CoOwnerEditionModal);