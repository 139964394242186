import * as React from "react";
import { FunctionComponent, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import { CallForFundsListingItem } from "./callsForFundsListingItem.component";
import { CallsForFundsSynthesis } from "../../../../../../corelogic/models/callsForFundsSynthesis.interface";
import { formatDate } from "../../../../utils/formatDate";
import { CoOwnerDetailsModal } from "../../coownerdetailsscreen";

interface Props {
  callsForFundsSynthesis: CallsForFundsSynthesis;
  delays: {
    amount: number;
    numberOfDelays: number;
  };
  refreshAssignments: () => void;
}

export const CallsForFundsAssignmentListing: FunctionComponent<Props> = ({
  callsForFundsSynthesis,
  delays,
  refreshAssignments,
}) => {
  const [selectedCallForFundsIndex, setSelectedCallForFundsIndex] =
    useState<number>(0);
  const [conjoint, setConjoint] = useState(true);

  const selectAnotherCallForFundsIndex = (event: any) => {
    setSelectedCallForFundsIndex(event.target.value);
  };

  const [shouldShowCoOwnerProfile, setShouldShowCoOwnerProfile] =
    useState(false);
  const [coOwnerIdProfile, setCoOwnerIdProfile] = useState("");

  const showCoOwnerProfile = (coOwnerId: string, conjoint?: boolean) => () => {
    setConjoint(!!conjoint);
    setCoOwnerIdProfile(coOwnerId);
    setShouldShowCoOwnerProfile(true);
  };

  const closeCoOwnerProfile = () => {
    setShouldShowCoOwnerProfile(false);
  };

  const downloadDocumentLink = (assignmentId: string) => () => {
    window.open(
      process.env.REACT_APP_BASE_API_URL +
        "/callsforfunds/assignments/" +
        assignmentId +
        "/file"
    );
  };

  const selectedCallForFunds =
    callsForFundsSynthesis.callsForFunds[selectedCallForFundsIndex];

  return (
    <Card className="calls-for-funds-listing">
      <Card.Header>
        <Form.Group controlId="selection">
          <Form.Control
            as="select"
            onChange={selectAnotherCallForFundsIndex}
            defaultValue={0}
          >
            {callsForFundsSynthesis.callsForFunds.map((cff, index) => (
              <option key={index} value={index}>
                Appel T{cff.context.numberOfCall}&nbsp;
              </option>
            ))}
          </Form.Control>
          <i>
            <span
              style={{
                fontSize: 15,
                fontWeight: 300,
              }}
            >
              {formatDate(selectedCallForFunds.context.startDate)}&nbsp;-&nbsp;
              {formatDate(selectedCallForFunds.context.endDate)}
            </span>
          </i>
        </Form.Group>
      </Card.Header>
      <Card.Body>
        {selectedCallForFunds.assignments.length === 0 ? (
          <p>
            Appel en cours, cliquez{" "}
            <a
              href="/#"
              onClick={refreshAssignments}
              style={{ color: "#f16e00", cursor: "pointer" }}
            >
              ici
            </a>{" "}
            pour rafraîchir
          </p>
        ) : (
          <Table responsive className="assignments-table">
            <thead className="assignments-table-first-level-head">
              <tr>
                <th>Copropriétaires</th>
              </tr>
            </thead>
            <tbody className="assignments-table-first-level-body">
              {selectedCallForFunds.assignments.map((assignment, index) => {
                return (
                  <CallForFundsListingItem
                    key={index}
                    assignment={assignment}
                    selectedNumberOfCall={
                      selectedCallForFunds.context.numberOfCall
                    }
                    lastCallNumber={
                      callsForFundsSynthesis.callsForFunds[0].context
                        .numberOfCall
                    }
                    refreshAssignments={refreshAssignments}
                    downloadDocumentLink={downloadDocumentLink}
                    openProfile={showCoOwnerProfile}
                  />
                );
              })}
            </tbody>
          </Table>
        )}

        {shouldShowCoOwnerProfile && (
          <CoOwnerDetailsModal
            onClose={closeCoOwnerProfile}
            coOwnerId={coOwnerIdProfile}
            conjoint={conjoint}
            refresh={() => {}}
          />
        )}
      </Card.Body>
    </Card>
  );
};
