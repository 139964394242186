import {Store} from "redux";
import Axios from "axios-observable";
import {AppState} from "../../../store/appState.interface";
import * as actionCreators from "../../../corelogic/usecases/userauthentication/actionCreators";

const axiosInterceptorSetup = (store: Store<AppState>, authService: any) => {
    Axios.interceptors.request.use(function (config) {
        config.headers['Content-Type'] = 'application/json';
        const authData = store.getState().coreLogicState.authenticatedUser;
        if (authData)
            config.headers['Authorization'] = 'Bearer ' + authData.accessToken;
        return config;
    }, function (error) {
        return Promise.reject(error);
    });

    const createAxiosResponseInterceptor = () => {
        const responseInterceptor = Axios.interceptors.response.use((response) => {
            return response;
        }, async (error) => {

            if (error.response.status !== 401) {
                return Promise.reject(error);
            }

            Axios.interceptors.response.eject(responseInterceptor);
            try {
                const newAccessToken = await authService.getAccessToken();
                store.dispatch(actionCreators.Actions.refreshToken(newAccessToken));
                error.response.config.headers['Authorization'] = 'Bearer ' + newAccessToken;
                error.response.config.cancelToken = undefined;
                return Axios.request(error.response.config).toPromise();
            } catch (error) {
                return Promise.reject(error);
            } finally {
                createAxiosResponseInterceptor()
            }


        });
    };
    return createAxiosResponseInterceptor();
};

export default axiosInterceptorSetup;
