import * as React from "react";
import {useSelector} from "react-redux";
import {AppState} from "../../../../../../store/appState.interface";

export const LoggedUserInfo = () => {
    const authenticatedUser = useSelector((state: AppState) => state.coreLogicState.authenticatedUser);
    return <>
        <div className="authenticated-user-box text-center" style={{fontSize: 14}}>
            {authenticatedUser ?
                <p>Connecté en tant que : <br/><a href="#login">{authenticatedUser.email}</a></p> :
                <p>Non connecté</p>
            }
        </div>
    </>;
};