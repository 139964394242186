import {Button, Col, Form} from "react-bootstrap";
import {Field, Formik} from "formik";
import React, {FunctionComponent} from "react";
import * as Yup from "yup";

export interface Props {
    onSubmit: (email: string) => void;
    onCancel: () => void;
    value: string;
}

const formSchema = Yup.object().shape({
    email: Yup.string()
        .email('L\'e-mail n\'est pas valide.')
        .required('L\'e-mail est requis.'),
});

export const EmailSingleForm: FunctionComponent<Props> = ({onSubmit, onCancel, value}) => {

    const handleSubmit = (values: any) => {
        onSubmit(values.email);
    }

    const cancel = () => {
        onCancel();
    }

    return <Formik
        enableReinitialize={true}
        initialValues={{
            email: value
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, handleSubmit
          }) => {
            return <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="align-items-center" style={{width: '100%'}}>
                    <Col xs="12">
                        <Form.Label htmlFor="email" srOnly>
                            Email
                        </Form.Label>
                        <Field className="form-control mb-2" id="email" name="email" style={{width: '100%'}}/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {errors.email && touched.email ? (
                            <div className="error-message">{errors.email}</div>
                        ) : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs="12" className="text-right pr-3">
                        <><Button type="button" onClick={cancel} className="mb-2 btn btn-dark">
                            Annuler
                        </Button>
                            <Button type="submit" className="ml-3 mb-2 btn btn-primary" style={{
                                backgroundColor: '#f16e00',
                                outline: 'none', border: '1px solid  #f16e00'
                            }}>
                                Valider
                            </Button></>
                    </Col>
                </Form.Row>

            </Form>;
        }}
    </Formik>
};