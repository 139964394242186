import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const DISASSOCIATE_SPECIAL_KEY = 'DISASSOCIATE_SPECIAL_KEY';
export const SPECIAL_KEY_DISASSOCIATED = 'SPECIAL_KEY_DISASSOCIATED';
export const RESET_SPECIAL_KEY_DISASSOCIATION = 'RESET_SPECIAL_KEY_DISASSOCIATION';

export const Actions = {
    disassociateSpecialKey: (lotNumber: string, specialKeyId: string) =>
        createAction(DISASSOCIATE_SPECIAL_KEY, {lotNumber, specialKeyId}),
    specialKeyDisassociated: (specialKeyId: string) =>
        createAction(SPECIAL_KEY_DISASSOCIATED, {specialKeyId}),
    resetSpecialKeyDisassociation: () =>
        createAction(RESET_SPECIAL_KEY_DISASSOCIATION),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;