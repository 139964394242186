import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import React, { FC, useCallback, useState } from "react";
import { CoOwnersDetailsView } from "../../../../../../corelogic/usecases/condominiumdetailsview/coOwnersDetailsView";
import { CoOwnerDetailsModal } from "../../coownerdetailsscreen";
import { CoOwnerLotsConfigurationModal } from "../../coownersmanagementscreen/coownerlots/coOwnerLotsConfigurationModal.component";
import { useDispatch } from "react-redux";
import { ByCoOwnerLots } from "./byCoOwnerLots";
import * as removeCoOwnerActionCreators from "../../../../../../corelogic/usecases/coownerremoval/actionCreators";
import * as condominiumDetailsViewActionCreators from "../../../../../../corelogic/usecases/condominiumdetailsview/actionCreators";

type Props = {
  coOwnerId: string;
  details: CoOwnersDetailsView["Content"];
};
export const ByCoOwnerCard: FC<Props> = ({ coOwnerId, details }) => {
  const [shouldShowCoOwnerProfile, setShouldShowCoOwnerProfile] =
    useState(false);
  const [shouldShowCoOwnerLotsConfiguration, setShowCoOwnerLotsConfiguration] =
    useState(false);
  const [shouldShowCoOwnerDeletion, setShouldShowCoOwnerDeletion] =
    useState(false);
  const [isConjointProfile, setConjointProfile] = useState(false);
  const dispatch = useDispatch();

  const refreshContent = useCallback(() => {
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveCoOwnersDetailsView()
    );
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveLotsDetailsView()
    );
  }, [dispatch]);

  const deleteConfirmation = () => {
    closeCoOwnerDeleteConfirmation();
    dispatch(removeCoOwnerActionCreators.Actions.removeCoOwner(coOwnerId));
  };

  const showCoOwnerProfile = useCallback(
    (conjoint: boolean) => (e: any) => {
      if (conjoint) {
        setConjointProfile(true);
        setShouldShowCoOwnerProfile(true);
      } else {
        setConjointProfile(false);
        setShouldShowCoOwnerProfile(true);
      }
    },
    [setShouldShowCoOwnerProfile]
  );

  const closeCoOwnerProfile = useCallback(() => {
    setShouldShowCoOwnerProfile(false);
  }, [setShouldShowCoOwnerProfile]);

  const showCoOwnerLotsConfiguration = useCallback(() => {
    setShowCoOwnerLotsConfiguration(true);
  }, [setShowCoOwnerLotsConfiguration]);

  const closeCoOwnerLotsConfiguration = useCallback(() => {
    setShowCoOwnerLotsConfiguration(false);
  }, [setShowCoOwnerLotsConfiguration]);

  const closeCoOwnerDeleteConfirmation = useCallback(() => {
    setShouldShowCoOwnerDeletion(false);
  }, [setShouldShowCoOwnerDeletion]);

  return (
    <div>
      <Row className="justify-content-center mt-3">
        <Col sm={8} md={12} lg={6}>
          <Card>
            <Card.Body className="p-2">
              <Row className="mb-0">
                <Col className="text-left" xs={12}>
                  <p
                    className="link mb-0"
                    style={{ fontSize: 15 }}
                    onClick={showCoOwnerProfile(false)}
                  >
                    {details.mainCoOwner.lastName}{" "}
                    {details.mainCoOwner.firstName}
                  </p>
                  {details.conjointCoOwner && (
                    <p
                      className="link"
                      style={{ fontSize: 13, marginBottom: 0 }}
                      onClick={showCoOwnerProfile(true)}
                    >
                      {details.conjointCoOwner.lastName}{" "}
                      {details.conjointCoOwner.firstName}
                    </p>
                  )}
                </Col>
                <Col className="text-right" xs={12}>
                  <p className="mb-0" style={{ fontSize: 15 }}>
                    {details.coOwnerMainQuota}
                  </p>
                </Col>
              </Row>
              {
                <Row className="mb-0 mt-4">
                  <Col xs={12}>
                    {details.lots.length === 0 ? (
                      <div>
                        <p style={{ fontSize: 12 }}>Aucun lot assigné</p>
                        <Button
                          variant="link"
                          className="text-center"
                          style={{ fontSize: 13 }}
                          onClick={showCoOwnerLotsConfiguration}
                        >
                          Gérer les lots
                        </Button>
                      </div>
                    ) : (
                      <>
                        {details.lots.map((lot, index) => {
                          return (
                            <div key={lot.number}>
                              <ByCoOwnerLots key={lot.number} lot={lot} />
                              {index === details.lots.length - 1 ? (
                                <Button
                                  variant="link"
                                  className="text-center"
                                  style={{ fontSize: 13 }}
                                  onClick={showCoOwnerLotsConfiguration}
                                >
                                  Gérer les lots
                                </Button>
                              ) : (
                                <hr />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}
                  </Col>
                </Row>
              }
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {shouldShowCoOwnerProfile && (
        <CoOwnerDetailsModal
          onClose={closeCoOwnerProfile}
          coOwnerId={coOwnerId}
          conjoint={isConjointProfile}
          refresh={refreshContent}
        />
      )}
      {shouldShowCoOwnerLotsConfiguration && (
        <CoOwnerLotsConfigurationModal
          onClose={closeCoOwnerLotsConfiguration}
          coOwnerId={coOwnerId}
          coOwnerFirstName={details.mainCoOwner.firstName}
          coOwnerLastName={details.mainCoOwner.lastName}
          coOwnerQuota={details.coOwnerMainQuota}
          conjointFirstName={details.conjointCoOwner?.firstName}
          conjointLastName={details.conjointCoOwner?.lastName}
          refresh={refreshContent}
        />
      )}

      <Modal
        show={shouldShowCoOwnerDeletion}
        onHide={closeCoOwnerDeleteConfirmation}
      >
        <Modal.Header closeButton>
          <Modal.Title>Suppression de copropriétaire</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Confirmez-vous la suppression du copropriétaire{" "}
          {details.mainCoOwner.firstName} {details.mainCoOwner.lastName} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeCoOwnerDeleteConfirmation}>
            Non
          </Button>
          <Button onClick={deleteConfirmation} className="btn button-action">
            Oui
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
