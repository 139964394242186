import {default as React, FunctionComponent, useCallback, useEffect, useState} from "react";
import {ExpandedDetailsListItem} from "./expandedDetailsListItem.component";
import {Button, Fade} from "react-bootstrap";
import {CallForFundsAssignment} from "../../../../../../corelogic/models/callForFundsAssignments.interface";
import {CashMoneyModal} from "./cashmoney/cashMoneyModal";
import {useDispatch, useSelector} from "react-redux";
import * as actionCreators from "../../../../../../corelogic/usecases/cashcoownermoney/actionCreators";
import {AppState} from "../../../../../../store/appState.interface";

interface Props {
    assignment: CallForFundsAssignment;
    lastCallNumber: number;
    selectedNumberOfCall: number;
    openProfile: (coOwnerId: string, conjoint?: boolean) => () => void;
    downloadDocumentLink: (assignmentId: string) => () => void;
    refreshAssignments: () => void;
}

export const CallForFundsListingItem: FunctionComponent<Props> = ({
                                                                      assignment,
                                                                      openProfile,
                                                                      lastCallNumber,
                                                                      selectedNumberOfCall,
                                                                      downloadDocumentLink,
                                                                      refreshAssignments
                                                                  }) => {

    const [shouldExpand, setShouldExpand] = useState(false);
    const [showCashMoneyModal, setShowCashMoneyModal] = useState(false);
    const dispatch = useDispatch();

    const paymentProcessStatus = useSelector((state: AppState) => state.coreLogicState.coOwnerMoneyCashing);

    const refreshAllAssignments = useCallback(() => {
        refreshAssignments();
    }, [refreshAssignments]);

    useEffect(() => {
        if (paymentProcessStatus.callForFundsAssignmentId && !paymentProcessStatus.processing) {
            dispatch(actionCreators.Actions.clearMoneyCashStatus());
            refreshAllAssignments();
            setShowCashMoneyModal(false);
        }
    }, [refreshAllAssignments, paymentProcessStatus, dispatch, setShowCashMoneyModal, assignment.id]);

    const selectedItem = useCallback(() => {
        setShouldExpand(!shouldExpand);
    }, [shouldExpand]);

    const cashMoney = useCallback((cash: number, cashingDate: Date) => {
        dispatch(actionCreators.Actions.cashCoOwnerMoney(assignment.id, cash, cashingDate));
    }, [dispatch, assignment.id]);

    const openCashMoneyModal = useCallback(() => {
        setShowCashMoneyModal(true);
    }, [setShowCashMoneyModal]);

    const closeCashMoneyModal = useCallback(() => {
        setShowCashMoneyModal(false);
    }, [setShowCashMoneyModal]);

    return <>
        <tr className="call-for-funds-assignment-row" style={{textAlign: 'center'}}>
            <td>
                <div className="co-owner-assignment-fullname" onClick={openProfile(assignment.coOwner.id)}>
                    {assignment.coOwner.person.firstName + ' ' + assignment.coOwner.person.lastName}
                </div>
                {assignment.coOwner.conjointPerson &&
                <><br/>
                    <div className="co-owner-assignment-fullname" onClick={openProfile(assignment.coOwner.id, true)}>
                        {assignment.coOwner.conjointPerson.firstName + ' ' + assignment.coOwner.conjointPerson.lastName}</div>
                </>}
            </td>
            <td className="cashed-money">
                <span className={assignment.paid === 0 ? "none" :
                    (+(assignment.amount.constructionFund +
                    assignment.amount.provision + assignment.balanceBefore).toFixed(2) <= assignment.paid ? "full" : "partially")}>
                    {assignment.paid.toFixed(2)}
                </span>
                &nbsp;/ {(assignment.amount.constructionFund +
                assignment.amount.provision + assignment.balanceBefore).toFixed(2)}&nbsp;€
            </td>
            <td className="co-owner-assignment-fullname">
                {lastCallNumber === selectedNumberOfCall && <Button variant="link" onClick={openCashMoneyModal}>Encaisser</Button>}
            </td>
            <td className="accordion-angle">
                <span onClick={selectedItem}>
                    {shouldExpand ? <i className="fas fa-angle-up"/> : <i className="fas fa-angle-down"/>}
                </span>
            </td>
        </tr>
        <Fade in={shouldExpand}>
            <tr>
                {shouldExpand &&
                <ExpandedDetailsListItem assignment={assignment} downloadDocumentLink={downloadDocumentLink}/>}
            </tr>
        </Fade>
        <CashMoneyModal showCashMoneyModal={showCashMoneyModal}
                        closeCashMoneyModal={closeCashMoneyModal}
                        coOwnerFirstName={assignment.coOwner.person.firstName}
                        coOwnerLastName={assignment.coOwner.person.lastName}
                        cashMoney={cashMoney}
                        maxAmount={assignment.balanceAfter}
                        processingPayment={paymentProcessStatus.processing}/>
    </>
};
