import * as actionCreators from "./actionsCreators";

export const coOwnerLotsAddition = (state: {
    lot: {
        quota: number;
        lotNumber: string;
        lotType: string;
        building: string;
    } | null
} = {lot: null}, action: actionCreators.Actions) => {
    if (action.type === actionCreators.INIT_AVAILABLE_SHARES)
        return {lot: null};
    if (action.type === actionCreators.ADD_CO_OWNER_LOT)
        return {
            lot: {
                lotType: action.payload.lotType,
                lotNumber: action.payload.lotNumber,
                building: action.payload.building,
                quota: action.payload.quota
            }
        };
    return state;
};