import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../store/appState.interface";
import { Col, Row } from "react-bootstrap";
import { ByCoOwnerCard } from "./byCoOwnerCard";
import { NewCoOwnerButton } from "../../coownersmanagementscreen/newCoOwnerButton.component";
import { CoOwnerEditionModal } from "../../coownersmanagementscreen/coowneredition";
import * as condominiumDetailsViewActionCreators from "../../../../../../corelogic/usecases/condominiumdetailsview/actionCreators";
import { getCoOwnersSumOfShare } from "../../coownersmanagementscreen/coowneredition/selectors";
import { getSelectedCondominiumProperty } from "../../../condominium-properties-selection/selectors";

type Props = {
  condominiumId: string;
};

export const ViewByCoOwner: FC<Props> = ({ condominiumId }) => {
  const condominiumDetailsView = useSelector(
    (state: AppState) => state.coreLogicState.coOwnersDetailsView
  );
  const coOwnersSumOfShare = useSelector(getCoOwnersSumOfShare);
  const condominiumProperty = useSelector(getSelectedCondominiumProperty);

  const dispatch = useDispatch();

  const refreshContent = useCallback(() => {
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveCoOwnersDetailsView()
    );
  }, [dispatch]);

  useEffect(() => {
    if (condominiumId) refreshContent();
  }, [dispatch, condominiumId, refreshContent]);

  const [shouldShowCoOwnerEdition, setShouldShowCoOwnerEdition] =
    React.useState(false);
  const showCoOwnerEdition = () => setShouldShowCoOwnerEdition(true);
  const closeCoOwnerEdition = () => setShouldShowCoOwnerEdition(false);

  return (
    <>
      <div className="coowners-management">
        {condominiumDetailsView.data && (
          <>
            {coOwnersSumOfShare.sumOfShares < condominiumProperty!.shares && (
              <Row>
                <Col className="text-right" style={{ paddingRight: "2rem" }}>
                  <NewCoOwnerButton action={showCoOwnerEdition} />
                </Col>
              </Row>
            )}
            <Row>
              <Col className="text-right" style={{ paddingRight: "2rem" }}>
                <h5>
                  {coOwnersSumOfShare.sumOfShares +
                    " / " +
                    condominiumProperty?.shares}{" "}
                  tantièmes
                </h5>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                {Object.keys(condominiumDetailsView.data).map((coOwnerId) => (
                  <ByCoOwnerCard
                    key={coOwnerId}
                    coOwnerId={coOwnerId}
                    details={condominiumDetailsView.data![coOwnerId]}
                  />
                ))}
              </Col>
            </Row>
          </>
        )}
      </div>
      <CoOwnerEditionModal
        show={shouldShowCoOwnerEdition}
        onClose={closeCoOwnerEdition}
        refreshContent={refreshContent}
      />
    </>
  );
};
