import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CondominiumProperty} from "../../models/condominiumProperty.interface";

export const RETRIEVE_CONDOMINIUM_PROPERTIES = 'RETRIEVE_CONDOMINIUM_PROPERTIES';
export const CONDOMINIUM_PROPERTIES_RETRIEVED = 'CONDOMINIUM_PROPERTIES_RETRIEVED';

export const Actions = {
    retrieveCondominiumProperties: () =>
        createAction(RETRIEVE_CONDOMINIUM_PROPERTIES),
    condominiumPropertiesRetrieved: (condominiumProperties: CondominiumProperty[]) =>
        createAction(CONDOMINIUM_PROPERTIES_RETRIEVED, condominiumProperties)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;