import {createAction} from "../../../../../../../../store/createAction";
import {ActionsUnion} from "../../../../../../../../store/actionsUnions";

export const INIT_AVAILABLE_SHARES = 'INIT_AVAILABLE_SHARES';
export const ADD_CO_OWNER_LOT = 'ADD_CO_OWNER_LOT';

export const Actions = {
    setTotalAvailableShares: (availableShares: number) =>
        createAction(INIT_AVAILABLE_SHARES, {availableShares}),
    addCoOwnerLot: (index: number, quota: number, lotNumber: string, lotType: string, building: string) =>
        createAction(ADD_CO_OWNER_LOT, {index, quota, lotNumber, lotType, building})
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;