import {combineReducers} from "redux";
import * as actionCreators from "../usecases/specialkeyassociation/actionCreators";

const justAssociated = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.SPECIAL_KEY_ASSOCIATED:
            return action.payload.specialKeyId;
        case actionCreators.RESET_SPECIAL_KEY_ASSOCIATION:
            return null;
        default:
            return state;
    }
};

const associating = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.ASSOCIATE_SPECIAL_KEY;
};

export default combineReducers({
    associating,
    justAssociated
});
