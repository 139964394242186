import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CoOwnerDetails} from "../../models/coOwnerDetails";

export const RETRIEVE_CO_OWNER_DETAILS = 'RETRIEVE_CO_OWNER_DETAILS';
export const CO_OWNER_DETAILS_RETRIEVED = 'CO_OWNER_DETAILS_RETRIEVED';

export const Actions = {
    retrieveCoOwnerDetails: (coOwnerId: string, conjoint: boolean) =>
        createAction(RETRIEVE_CO_OWNER_DETAILS, {coOwnerId, conjoint}),
    coOwnerDetailsRetrieved: (coOwnerDetails: CoOwnerDetails | null) =>
        createAction(CO_OWNER_DETAILS_RETRIEVED, coOwnerDetails)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;