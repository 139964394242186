import React, { useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";
import { AppState } from "../../../../../store/appState.interface";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { ViewByCoOwner } from "./by-coowner/viewByCoOwner.component";
import { ViewByLot } from "./by-lot/viewByLot.component";

export const CondominiumDetailsView = () => {
  const condominium = useSelector(
    (state: AppState) => state.uiState.selectedCondominiumProperty
  );
  const [tab, onTabSelection] = useState("copro");

  return (
    <>
      {condominium && (
        <Row>
          <Col>
            <Tabs
              id="special-keys-distribution-view-mode"
              activeKey={tab}
              onSelect={(view) => onTabSelection(view!)}
              className="mb-3"
            >
              <Tab eventKey="copro" title="Vue par copropriétaire">
                <>
                  <ViewByCoOwner condominiumId={condominium?.id} />
                </>
              </Tab>
              <Tab eventKey="lot" title="Vue par lot">
                <>
                  <ViewByLot
                    condominiumId={condominium?.id}
                    condominiumShares={condominium?.shares}
                  />
                </>
              </Tab>
            </Tabs>
          </Col>
        </Row>
      )}
    </>
  );
};
