import {Button} from "react-bootstrap";
import {CoOwnerLotsSpecialKeys} from "./special-keys/index.component";
import React, {FC} from "react";
import {CoOwnerLot} from "../../../../../../corelogic/models/coOwnerLot";

interface Props {
    lot: CoOwnerLot
    index: number;
    selectedIds: Record<string, boolean>;
    editLot: (lotNumber: string) => (e: any) => void;
    setSelectedIds: (selectedIds: Record<string, boolean>) => void;
    coOwnerId: string;
}

export const CoOwnerLotsTableItem: FC<Props> = ({coOwnerId, lot, index, selectedIds, editLot, setSelectedIds}) => {

    const onSelectRow = (lot: CoOwnerLot) => (e: any) => {
        if (selectedIds[lot.lotNumber]) {
            const {[lot.lotNumber]: value, ...withoutSelected} = selectedIds;
            setSelectedIds(withoutSelected);
        } else
            setSelectedIds({...selectedIds, [lot.lotNumber]: true});
    }

    return <>
        <tr key={index}>
            <td>
                <input type="checkbox" checked={selectedIds[lot.lotNumber]}
                       onChange={onSelectRow(lot)}/>
            </td>
            <td>{lot.lotNumber}</td>
            <td>{lot.lotType}</td>
            <td>{lot.building}</td>
            <td>{lot.quota}</td>
            <td>
                <Button variant="link" style={{padding: 0, lineHeight: 1.3, verticalAlign: 'top'}}
                        onClick={editLot(lot.lotNumber)}><i className="fas fa-pen"/></Button>
            </td>
        </tr>
        <tr>
            <td colSpan={6} className="text-left">
                <CoOwnerLotsSpecialKeys coOwnerId={coOwnerId} lot={lot} />
                <hr/>
            </td>
        </tr>
    </>;
}