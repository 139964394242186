import React, { FunctionComponent, useCallback, useState } from "react";
import * as balanceBeforeCallsArchivesSetActionCreators from "../../../../../../../corelogic/usecases/balancebeforecallsarchivesset/actionCreators";
import { useDispatch } from "react-redux";
import { BalanceBeforeCallsArchivesTable } from "./balanceBeforeCallsArchivesTable.component";
import { CoOwnerDetailsModal } from "../../../coownerdetailsscreen";
import { CondominiumProperty } from "../../../../../../../corelogic/models/condominiumProperty.interface";
import { BalanceBeforeCall } from "../../../../../../../corelogic/models/balanceBeforeCall.interface";

interface Props {
  condominiumProperty: CondominiumProperty;
  balancesBeforeCall: BalanceBeforeCall[];
}

export const BalancesBeforeCallInit: FunctionComponent<Props> = ({
  condominiumProperty,
  balancesBeforeCall,
}) => {
  const dispatch = useDispatch();

  const [shouldShowCoOwnerProfile, setShouldShowCoOwnerProfile] =
    useState(false);
  const [coOwnerIdProfile, setCoOwnerIdProfile] = useState("");
  const [conjoint, setConjoint] = useState(true);

  const showCoOwnerProfile = (coOwnerId: string, conjoint?: boolean) => () => {
    setConjoint(!!conjoint);
    setCoOwnerIdProfile(coOwnerId);
    setShouldShowCoOwnerProfile(true);
  };

  const closeCoOwnerProfile = () => {
    setShouldShowCoOwnerProfile(false);
  };

  const setBalanceBeforeCallCallBack = useCallback(
    (coOwnerId: string, balanceBefore: number) => {
      dispatch(
        balanceBeforeCallsArchivesSetActionCreators.Actions.setBalanceBeforeCall(
          condominiumProperty.currentExercise!.id,
          coOwnerId,
          balanceBefore
        )
      );
    },
    [dispatch, condominiumProperty.currentExercise]
  );

  return (
    <div>
      <BalanceBeforeCallsArchivesTable
        balanceBeforeCallsArchives={balancesBeforeCall}
        openProfile={showCoOwnerProfile}
        setBalanceBeforeCall={setBalanceBeforeCallCallBack}
      />
      {shouldShowCoOwnerProfile && (
        <CoOwnerDetailsModal
          onClose={closeCoOwnerProfile}
          coOwnerId={coOwnerIdProfile}
          conjoint={conjoint}
          refresh={() => {}}
        />
      )}
    </div>
  );
};
