import React, {FunctionComponent} from 'react';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {Col, FormGroup, FormLabel, Row} from "react-bootstrap";
import './coOwnerLotsConfigurationForm.component';
import {NumberFormField} from "../../../../formcustomfields/numberFormField.component";
import {SelectFormField} from "../../../../formcustomfields/selectFormField.component";
import {CoOwnerLot} from "../../../../../../../../corelogic/models/coOwnerLot";

const coOwnerLotSchema = (availableShares: number) => Yup.object().shape({
    lotNumber: Yup.string().required('Le numéro de lot est requis.'),
    lotType: Yup.string().required('Le type de lot est requis.'),
    quota: Yup.number()
        .min(1, 'Le nombre de tantièmes doit être supérieur à 0.')
        .max(availableShares, 'Le nombre de tantièmes doit être inférieur ou égal à ' + availableShares + '.')
        .required('Le nombre de tantièmes est requis.'),
    building: Yup.string().required('Le bâtiment est requis.')
});

interface Props {
    onSubmittedNewCoOwnerLot: (lotNumberReference: string | undefined, values: any) => void;
    remainingQuota: number;
    cancelCoOwnerLotAddition: () => void;
    editedLot: CoOwnerLot | null;
}

export const CoOwnerLotsConfigurationForm: FunctionComponent<Props> = ({
                                                                           onSubmittedNewCoOwnerLot,
                                                                           remainingQuota,
                                                                           cancelCoOwnerLotAddition,
                                                                           editedLot
                                                                       }) => {

    const handleSubmit = (values: any) => {
        onSubmittedNewCoOwnerLot(editedLot?.lotNumber, values);
    };

    const FormComponent = ({errors, touched, values}: {
        errors: any,
        touched: any
        setFieldValue: any,
        values: any,
        submitForm: any
    }) => {

        return <Form className="co-owner-edition-form">
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel
                            htmlFor="quota">Tantièmes <i>({remainingQuota} allouables)</i></FormLabel>
                        <Field className="form-control" id="quota" name="quota"
                               component={NumberFormField} style={{width: 100}}/>
                        {errors.quota && touched.quota ? (
                            <div className="error-message">{errors.quota}</div>
                        ) : null}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel htmlFor="lotNumber">Numéro de lot</FormLabel>
                        <Field className="form-control" id="lotNumber" name="lotNumber"/>
                        {errors.lotNumber && touched.lotNumber ? (
                            <div className="error-message">{errors.lotNumber}</div>
                        ) : null}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel htmlFor="lotType">Type de lot</FormLabel>
                        <Field className="form-control" id="lotType" name="lotType"
                               component={SelectFormField}
                        >
                            <option value="Appartement">Appartement</option>
                            <option value="Cave">Cave</option>
                            <option value="Parking">Parking</option>
                            <option value="Local">Local</option>
                            <option value="Autre">Autre</option>
                        </Field>
                        {errors.lotType && touched.lotType ? (
                            <div className="error-message">{errors.lotType}</div>
                        ) : null}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col>
                        <FormLabel htmlFor="building">Bâtiment</FormLabel>
                        <Field className="form-control" id="building" name="building"
                               component={SelectFormField}
                        >
                            <option value="A ou 1">A ou 1</option>
                            <option value="B ou 2">B ou 2</option>
                            <option value="C ou 3">C ou 3</option>
                        </Field>
                        {errors.building && touched.building ? (
                            <div className="error-message">{errors.building}</div>
                        ) : null}
                    </Col>
                </Row>
            </FormGroup>
            <div className="form-buttons-wrapper">
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0, textAlign: 'left'}}>
                        <button type="button" className="btn btn-primary" onClick={cancelCoOwnerLotAddition}>Annuler
                        </button>
                    </Col>
                    <Col style={{margin: 0, padding: 0, textAlign: 'right'}}>
                        <button type="submit" className="btn btn-primary">
                            Valider
                        </button>
                    </Col>
                </Row>
            </div>
        </Form>;
    };

    return <div className="contact-form gray-light-bg">
        <Formik
            enableReinitialize={true}
            initialValues={{
                quota: editedLot?.quota,
                lotNumber: editedLot?.lotNumber,
                lotType: editedLot?.lotType || 'Appartement',
                building: editedLot?.building || 'A ou 1',
            }}
            validationSchema={coOwnerLotSchema(remainingQuota)}
            onSubmit={handleSubmit}
        >
            {({
                  errors, touched, setFieldValue, values,
                  submitForm
              }) => {
                return <>
                    <FormComponent errors={errors} touched={touched} setFieldValue={setFieldValue}
                                   values={values} submitForm={submitForm}/>
                </>
            }}
        </Formik>
    </div>
};