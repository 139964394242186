import React, {FunctionComponent} from "react";
import {Form, Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import * as Yup from "yup";
import {CondominiumPropertyEditionFormFields} from "./condominiumPropertyEditionFormFields.component";
import {addMonths, subSeconds} from "date-fns";
import {CondominiumPropertyCommand} from "../../../../../../corelogic/usecases/condominiumpropertyaddition/condominiumPropertyCommand.interface";

interface Props {
    onSubmit: (condominiumPropertyCommand: CondominiumPropertyCommand) => any;
}

const condominiumPropertyEditionSchema =
    Yup.object().shape({
        address: Yup.string().required('L\'adresse est requise'),
        shares: Yup.number()
            .min(1, 'Le nombre de tantièmes doit être supérieur à 0.')
            .required('Le nombre de tantièmes est requis.'),
        exerciseRange: Yup.number().required('La période d\'exercice doit être renseignée')
    });

export const CondominiumPropertyEditionForm: FunctionComponent<Props> = ({onSubmit}) => {

    const suggestedExercisesStarts = (() => {
        const currentDate = new Date();
        return [
            new Date(currentDate.getFullYear(), 0, 1),
            new Date(currentDate.getFullYear(), 3, 1),
            new Date(currentDate.getFullYear(), 6, 1),
            new Date(currentDate.getFullYear(), 9, 1)
        ];
    })();

    const endDateExerciseSuggestionCalculator = (startDate: Date) =>
        subSeconds(addMonths(startDate, 12), 1);

    const handleSubmit = (values: any) => {
        onSubmit({
            name: values.address,
            exerciseRange: {startMonth: +values.exerciseRange},
            shares: +values.shares
        });
    };

    const FormComponent = ({errors, touched, setFieldValue, values}: {
        errors: any,
        touched: any
        setFieldValue: any,
        values: any,
        submitForm: any
    }) => {
        return <Form className="co-owner-edition-form">
            <CondominiumPropertyEditionFormFields suggestedExercisesStarts={suggestedExercisesStarts}
                                                  endDateExerciseSuggestionCalculator={endDateExerciseSuggestionCalculator}
                                                  errors={errors} touched={touched}
                                                  setFieldValue={setFieldValue} values={values}/>
            <div className="form-buttons-wrapper">
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0, textAlign: 'right'}}>
                        <button type="submit" className="btn btn-primary">
                            Valider
                        </button>
                    </Col>
                </Row>
            </div>
        </Form>;
    };

    return <div className="contact-form gray-light-bg">
        <Formik
            enableReinitialize={true}
            initialValues={{
                exerciseRange: suggestedExercisesStarts[0].getMonth(),
                shares: "1000",
            }}
            validationSchema={condominiumPropertyEditionSchema}
            onSubmit={handleSubmit}
        >
            {({
                  errors, touched, setFieldValue, values,
                  submitForm
              }) => {
                return <>
                    <FormComponent errors={errors} touched={touched} setFieldValue={setFieldValue}
                                   values={values} submitForm={submitForm}/>
                </>
            }}
        </Formik>
    </div>
};

