import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import * as actionCreators from "./actionCreators";
import { AppState } from "../../../store/appState.interface";
import { CondominiumPropertyGateway } from "../../gateways/condominiumPropertyGateway.interface";
import { map, switchMap } from "rxjs/operators";
import { UuidGenerator } from "../../models/write/uuidGenerator.interface";

export const addCondominiumProperty = (
  action$: ActionsObservable<actionCreators.Actions>,
  state$: StateObservable<AppState>,
  {
    condominiumPropertyGateway,
    uuidGenerator,
  }: {
    condominiumPropertyGateway: CondominiumPropertyGateway;
    uuidGenerator: UuidGenerator;
  }
) => {
  return action$.pipe(
    ofType<
      actionCreators.Actions,
      ReturnType<typeof actionCreators.Actions.addCondominiumProperty>
    >(actionCreators.ADD_CONDOMINIUM_PROPERTY),
    // debounceTime(200),
    switchMap((action) => {
      const condominiumPropertyId = uuidGenerator.generate();
      return condominiumPropertyGateway
        .save({
          id: condominiumPropertyId,
          name: action.payload.condominiumPropertyCommand.name,
          shares: action.payload.condominiumPropertyCommand.shares,
          exerciseRange:
            action.payload.condominiumPropertyCommand.exerciseRange,
        })
        .pipe(
          map(() =>
            actionCreators.Actions.condominiumPropertyAdded(
              condominiumPropertyId,
              action.payload.condominiumPropertyCommand.name,
              action.payload.condominiumPropertyCommand.shares
            )
          )
        );
    })
  );
};
