import {combineReducers} from "redux";
import * as actionCreators from "../usecases/condominiumpropertycoownerslistretrieval/actionCreators";
import {CondominiumPropertyCoOwnerHeader} from "../models/condominiumPropertyCoOwnerHeader";

const data = (state: CondominiumPropertyCoOwnerHeader[] = [], action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST:
            return [];
        case actionCreators.CONDOMINIUM_PROPERTY_CO_OWNERS_LIST_RETRIEVED:
            return action.payload;
        default:
            return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST;
};

export default combineReducers({
    data,
    fetching
});
