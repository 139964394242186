import {createAction} from '../../../store/createAction';
import {ActionsUnion} from '../../../store/actionsUnions';

export const REMOVE_CO_OWNER_LOTS = 'REMOVE_CO_OWNER_LOTS';
export const CO_OWNER_LOTS_REMOVED = 'CO_OWNER_LOTS_REMOVED';
export const CLEAR_DATA_ABOUT_CO_OWNER_LOTS_REMOVAL = 'CLEAR_DATA_ABOUT_CO_OWNER_LOTS_REMOVAL';

export const Actions = {
    removeCoOwnerLots: (coOwnerId: string, lotNumbers: string[]) =>
        createAction(REMOVE_CO_OWNER_LOTS, {coOwnerId, lotNumbers}),
    coOwnerLotsRemoved: (coOwnerId: string, lotNumbers: string[]) =>
        createAction(CO_OWNER_LOTS_REMOVED, {coOwnerId, lotNumbers}),
    clearDataAboutCoOwnerLotsRemoval: () => createAction(CLEAR_DATA_ABOUT_CO_OWNER_LOTS_REMOVAL)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
