import {combineReducers} from "redux";
import * as actionCreators from "../usecases/coownerremoval/actionCreators";

const justRemoved = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_REMOVED:
            return action.payload.coOwnerId;
        default:
            return state;
    }
};

const removing = (state: boolean = false, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_REMOVED:
            return false;
        case actionCreators.REMOVE_CO_OWNER:
            return true;
        default:
            return state;
    }
};

export default combineReducers({
    removing,
    justRemoved
});
