import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const ASSOCIATE_SPECIAL_KEY = 'ASSOCIATE_SPECIAL_KEY';
export const SPECIAL_KEY_ASSOCIATED = 'SPECIAL_KEY_ASSOCIATED';
export const RESET_SPECIAL_KEY_ASSOCIATION = 'RESET_SPECIAL_KEY_ASSOCIATION';

export const Actions = {
    associateSpecialKey: (coOwnerId: string, lotNumber: string, specialKeyId: string, quota: number) =>
        createAction(ASSOCIATE_SPECIAL_KEY, {coOwnerId, lotNumber, specialKeyId, quota}),
    specialKeyAssociated: (specialKeyId: string) =>
        createAction(SPECIAL_KEY_ASSOCIATED, {specialKeyId}),
    resetSpecialKeyAssociation: () =>
        createAction(RESET_SPECIAL_KEY_ASSOCIATION),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;