import * as React from "react";
import {Card, Table} from "react-bootstrap"
import {CondominiumPropertyCoOwnerHeader} from "../../../../../corelogic/models/condominiumPropertyCoOwnerHeader";
import {CoOwnerTableRow} from "./coOwnerTableRow";

interface Props {
    coOwners: CondominiumPropertyCoOwnerHeader[],
    openProfile: (coOwnerId: string, conjoint?: boolean) => () => void;
    configureLots: (coOwnerId: string, coOwnerFirstName: string, coOwnerLastName: string, coOwnerQuota: number) => () => void;
    removeCoOwner: (coOwnerId: string) => void;
    hasCondominiumPropertyCallsForFunds: boolean;
}

export const CoOwnersTable = React.memo<Props>(({
                                                    coOwners,
                                                    openProfile,
                                                    configureLots,
                                                    removeCoOwner,
                                                    hasCondominiumPropertyCallsForFunds
                                                }) => {
    return <Card>
        <Card.Body>
            <Table responsive className="list-table">
                <thead className="first-level-head">
                <tr>
                    <th>Copropriétaires</th>
                    <th>Tantièmes</th>
                </tr>
                </thead>
                <tbody className="first-level-body">
                {coOwners.map(coOwner =>
                    <CoOwnerTableRow key={coOwner.id}
                                     coOwner={coOwner}
                                     openProfile={openProfile}
                                     configureLots={configureLots}
                                     removeCoOwner={removeCoOwner}
                                     hasCondominiumPropertyCallsForFunds={hasCondominiumPropertyCallsForFunds}/>
                )
                }
                </tbody>
            </Table>
        </Card.Body>
    </Card>
});
