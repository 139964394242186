import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CondominiumPropertyCoOwnerHeader} from "../../models/condominiumPropertyCoOwnerHeader";

export const RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST = 'RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST';
export const CONDOMINIUM_PROPERTY_CO_OWNERS_LIST_RETRIEVED = 'CONDOMINIUM_PROPERTY_CO_OWNERS_LIST_RETRIEVED';

export const Actions = {
    retrieveCondominiumPropertyCoOwnersList: (condominiumPropertyId: string) =>
        createAction(RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST, {condominiumPropertyId}),
    condominiumPropertyCoOwnersListRetrieved: (condominiumCoOwnerHeaders: CondominiumPropertyCoOwnerHeader[]) =>
        createAction(CONDOMINIUM_PROPERTY_CO_OWNERS_LIST_RETRIEVED, condominiumCoOwnerHeaders)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;