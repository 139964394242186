import {combineReducers} from "redux";
import selectedCondominiumProperty from "../adapters/primary/reducers/selectedCondominiumPropertyReducer";
import coOwnersAddition from "../adapters/primary/reducers/coOwnerAdditionReducer";
import {coOwnerLotsAddition} from "../adapters/primary/uicomponents/screens/coownersmanagementscreen/coownerlots/coownerlotsedition/form/coOwnerLotsAdditionReducer";

export default combineReducers({
    selectedCondominiumProperty,
    coOwnersAddition,
    coOwnerLotsAddition
})
