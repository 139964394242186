import {Observable, of} from "rxjs";
import Axios from "axios-observable";
import {switchMap} from "rxjs/operators";
import {CondominiumPropertyGateway} from "../../../corelogic/gateways/condominiumPropertyGateway.interface";
import {CondominiumProperty} from "../../../corelogic/models/condominiumProperty.interface";
import {CondominiumPropertyExercise as CondominiumPropertyExerciseWriteModel} from "../../../corelogic/models/write/condominiumPropertyExercise.interface";
import {BalanceBeforeCall} from "../../../corelogic/models/balanceBeforeCall.interface";

export class HttpCondominiumPropertyGateway implements CondominiumPropertyGateway {

    private static readonly CONDOMINIUM_API_URL = process.env.REACT_APP_BASE_API_URL + '/condominiumproperties';

    retrieve(): Observable<CondominiumProperty[]> {
        return Axios.get(HttpCondominiumPropertyGateway.CONDOMINIUM_API_URL + '/me', {
            headers: {
                'Content-Type': 'application/json',
            }
        }).pipe(
            switchMap(response => {
                return of(response ? response.data : []);
            })
        )
    }

    retrieveBalanceBeforeCallsArchives(condominiumPropertyExerciseId: string): Observable<BalanceBeforeCall[]> {
        return Axios.get(process.env.REACT_APP_BASE_API_URL + '/condominiumpropertyexercises/' + condominiumPropertyExerciseId +
            '/balancebeforecallsarchives', {
            headers: {
                'Content-Type': 'application/json',
            }
        }).pipe(
            switchMap(response => {
                return of(response ? response.data : []);
            })
        )
    }

    save(condominiumProperty: CondominiumProperty): Observable<void> {
        try {
            return Axios.post(
                HttpCondominiumPropertyGateway.CONDOMINIUM_API_URL,
                {condominiumProperty}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).pipe(
                switchMap(response => {
                    return of(response ? response.data : null);
                })
            )
        } catch (e) {
            return of() as Observable<void>;
        }
    }

    saveExercise(condominiumPropertyExercise: CondominiumPropertyExerciseWriteModel): Observable<void> {
        try {
            return Axios.post(
                HttpCondominiumPropertyGateway.CONDOMINIUM_API_URL + '/' +
                condominiumPropertyExercise.condominiumPropertyId + '/exercises',
                {condominiumPropertyExercise}, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).pipe(
                switchMap(response => {
                    return of(response ? response.data : null);
                })
            )
        } catch (e) {
            return of() as Observable<void>;
        }
    }

    setBalanceBeforeCall(balanceBefore: number, coOwnerId: string, condominiumPropertyExerciseId: string): Observable<void> {
        try {
            return Axios.post(process.env.REACT_APP_BASE_API_URL + '/condominiumpropertyexercises/' + condominiumPropertyExerciseId +
                '/balancebeforecallsarchives',
                {
                    balanceBefore,
                    condominiumPropertyExerciseId,
                    coOwnerId
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).pipe(
                switchMap(response => {
                    return of(response ? response.data : null);
                })
            )
        } catch (e) {
            return of() as Observable<void>;
        }
    }
}
