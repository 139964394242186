import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import * as listActionCreators from "../../../../../corelogic/usecases/specialkeyslist/actionCreators";
import { AppState } from "../../../../../store/appState.interface";
import { SpecialKeysList } from "./specialKeysList.component";
import { SpecialKeyAdditionModal } from "./specialKeyAdditionModal";
import * as actionCreatorsSpecialKeyDeletion from "../../../../../corelogic/usecases/specialkeydeletion/actionCreators";

export const SpecialKeysManagement = () => {
  const [showSpecialKeyModal, setShowSpecialKey] = useState(false);
  const dispatch = useDispatch();
  const specialKeysList = useSelector(
    (state: AppState) => state.coreLogicState.specialKeysList
  );
  const condominiumId = useSelector(
    (state: AppState) => state.uiState.selectedCondominiumProperty?.id
  );
  const specialKeyJustDeleted = useSelector(
    (state: AppState) => state.coreLogicState.specialKeyDeletion.justDeleted
  );

  const refresh = useCallback(() => {
    dispatch(listActionCreators.Actions.listSpecialKeys());
  }, [dispatch]);

  useEffect(() => {
    if (condominiumId) refresh();
  }, [dispatch, refresh, condominiumId]);

  useEffect(() => {
    if (specialKeyJustDeleted) {
      refresh();
      dispatch(
        actionCreatorsSpecialKeyDeletion.Actions.resetSpecialKeyDeletion()
      );
    }
  }, [dispatch, refresh, specialKeyJustDeleted]);

  const openAdditionModal = () => setShowSpecialKey(true);

  const closeAdditionModal = () => setShowSpecialKey(false);

  return (
    <div className="special-keys-management">
      <Row>
        <Col className="text-right" style={{ paddingRight: "2rem" }}>
          <Button
            className="special-keys-add-button"
            onClick={openAdditionModal}
          >
            <i className="fas fa-plus" />
            &nbsp;<span>Ajouter une clé spéciale</span>
          </Button>
        </Col>
      </Row>
      {specialKeysList.data && (
        <Row>
          <Col className="text-center">
            {specialKeysList.data.length > 0 ? (
              <SpecialKeysList data={specialKeysList.data} />
            ) : (
              <p>Aucune clé spéciale renseignée pour cette copropriété</p>
            )}
          </Col>
        </Row>
      )}
      {showSpecialKeyModal && (
        <SpecialKeyAdditionModal onClose={closeAdditionModal} />
      )}
    </div>
  );
};
