import * as actionCreators from "../usecases/userauthentication/actionCreators";
import {UserAuthenticationData} from "../models/userAuthenticationData.interface";

export const authenticatedUser = (state: UserAuthenticationData | null = null, action: actionCreators.Actions) => {
    if (action.type === 'AUTHENTICATED_USER')
        return action.payload;
    if (action.type === 'LOGGED_OUT_USER')
        return null;
    if (action.type === 'REFRESH_TOKEN')
        return {
            id: state!.id,
            name: state!.name,
            email: state!.email,
            accessToken: action.payload.accessToken
        }
    return state;
};
