import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {SpecialKeyGateway} from "../../gateways/specialKeyGateway.interface";
import {map, switchMap} from "rxjs/operators";
import {UuidGenerator} from "../../models/write/uuidGenerator.interface";

export const addSpecialKey = (action$: ActionsObservable<actionCreators.Actions>,
                              state$: StateObservable<AppState>,
                              {specialKeyGateway, uuidGenerator}: {
                                  specialKeyGateway: SpecialKeyGateway,
                                  uuidGenerator: UuidGenerator
                              }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.addSpecialKey>>(actionCreators.ADD_SPECIAL_KEY),
        switchMap(action => {
            const specialKeyId = uuidGenerator.generate();
            return specialKeyGateway.save(
                state$.value.uiState.selectedCondominiumProperty!.id, {
                    id: specialKeyId,
                    name: action.payload.specialKey.name,
                    shares: action.payload.specialKey.shares
                }
            ).pipe(
                map(() => actionCreators.Actions.specialKeyAdded(specialKeyId))
            );
        }));
}
