import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {SpecialKeyGateway} from "../../gateways/specialKeyGateway.interface";
import {map, switchMap} from "rxjs/operators";
import {SpecialKey} from "./specialKey.interface";

export const listSpecialKeys = (action$: ActionsObservable<actionCreators.Actions>,
                                state$: StateObservable<AppState>,
                                {specialKeyGateway}: {
                                    specialKeyGateway: SpecialKeyGateway,
                                }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.listSpecialKeys>>(actionCreators.LIST_SPECIAL_KEYS),
        switchMap(() => {
            return specialKeyGateway.list(
                state$.value.uiState.selectedCondominiumProperty!.id
            ).pipe(
                map((specialKeys: SpecialKey[]) => actionCreators.Actions.specialKeysListed(specialKeys))
            );
        }));
}
