import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import * as actionCreators from "./actionCreators";
import { AppState } from "../../../store/appState.interface";
import { SpecialKeyGateway } from "../../gateways/specialKeyGateway.interface";
import { map, switchMap } from "rxjs/operators";

export const deleteSpecialKey = (
  action$: ActionsObservable<actionCreators.Actions>,
  state$: StateObservable<AppState>,
  {
    specialKeyGateway,
  }: {
    specialKeyGateway: SpecialKeyGateway;
  }
) => {
  return action$.pipe(
    ofType<
      actionCreators.Actions,
      ReturnType<typeof actionCreators.Actions.deleteSpecialKey>
    >(actionCreators.DELETE_SPECIAL_KEY),
    switchMap((action) => {
      const { specialKeyId } = action.payload;
      return specialKeyGateway
        .deleteById(specialKeyId)
        .pipe(
          map(() => actionCreators.Actions.specialKeyDeleted(specialKeyId))
        );
    })
  );
};
