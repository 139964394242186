import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {CondominiumPropertyExerciseCommand} from "./condominiumPropertyExerciseCommand.interface";

export const ADD_CONDOMINIUM_PROPERTY_EXERCISE = 'ADD_CONDOMINIUM_PROPERTY_EXERCISE';
export const CONDOMINIUM_PROPERTY_EXERCISE_ADDED = 'CONDOMINIUM_PROPERTY_EXERCISE_ADDED';

export const Actions = {
    addCondominiumExerciseProperty: (condominiumPropertyExerciseCommand: CondominiumPropertyExerciseCommand) =>
        createAction(ADD_CONDOMINIUM_PROPERTY_EXERCISE, {condominiumPropertyExerciseCommand}),
    condominiumPropertyExerciseAdded: (condominiumPropertyExerciseId: string) =>
        createAction(CONDOMINIUM_PROPERTY_EXERCISE_ADDED, {condominiumPropertyExerciseId}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
