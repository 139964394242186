import { createAction } from "../../../store/createAction";
import { ActionsUnion } from "../../../store/actionsUnions";
import { CondominiumPropertyCommand } from "./condominiumPropertyCommand.interface";

export const ADD_CONDOMINIUM_PROPERTY = "ADD_CONDOMINIUM_PROPERTY";
export const CONDOMINIUM_PROPERTY_ADDED = "CONDOMINIUM_PROPERTY_ADDED";

export const Actions = {
  addCondominiumProperty: (
    condominiumPropertyCommand: CondominiumPropertyCommand
  ) => createAction(ADD_CONDOMINIUM_PROPERTY, { condominiumPropertyCommand }),
  condominiumPropertyAdded: (
    condominiumPropertyId: string,
    condominiumPropertyName: string,
    condominiumPropertyShares: number
  ) =>
    createAction(CONDOMINIUM_PROPERTY_ADDED, {
      condominiumPropertyId,
      condominiumPropertyName,
      condominiumPropertyShares,
    }),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
