import * as React from "react";
import {Component} from "react";
import {Modal} from "react-bootstrap";
import {connect} from "react-redux";
import {AppState} from "../../../../../../store/appState.interface";
import {CondominiumPropertyExerciseEditionForm} from "./condominiumPropertyExerciseEditionForm.component";
import * as condominiumPropertyExerciseAdditionActionCreators
    from "../../../../../../corelogic/usecases/condominiumpropertyexerciseaddition/actionCreators";
import {getCondominiumPropertyExerciseAddition} from "../../coownersmanagementscreen/coowneredition/selectors";
import {CustomSpinner} from "../../../layout/progresscomponents/spinners/customSpinner.component";

interface Props {
    onClose: () => void;
    condominiumPropertyExerciseAddition: {
        adding: boolean,
        justAdded: string | null
    },
    addCondominiumExerciseProperty: typeof condominiumPropertyExerciseAdditionActionCreators.Actions.addCondominiumExerciseProperty;
    refreshContent: () => void;
    condominiumPropertyId: string;
    condominiumPropertyName: string;
}

interface State {
}

class CondominiumPropertyExerciseEditionModal extends Component<Props, State> {

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps && prevProps.condominiumPropertyExerciseAddition.justAdded !==
            this.props.condominiumPropertyExerciseAddition.justAdded) {
            this.handleClose();
            this.props.refreshContent();
        }
    }

    handleClose = () => {
        this.props.onClose();
    };

    render() {
        return <Modal show={true} onHide={this.handleClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {this.props.condominiumPropertyName} <br/> Nouvel exercice
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {this.props.condominiumPropertyExerciseAddition.adding ? <CustomSpinner/> :
                    <CondominiumPropertyExerciseEditionForm onSubmit={(provision, constructionFund) => {
                        this.props.addCondominiumExerciseProperty({
                            condominiumPropertyId: this.props.condominiumPropertyId,
                            provision,
                            constructionFund
                        })
                    }}/>
                }
            </Modal.Body>
        </Modal>;
    }
}

const mapStateToProps = (state: AppState) => ({
    condominiumPropertyExerciseAddition: getCondominiumPropertyExerciseAddition(state)
});

const mapDispatchToProps = {
    addCondominiumExerciseProperty: condominiumPropertyExerciseAdditionActionCreators.Actions.addCondominiumExerciseProperty
};

export default connect(mapStateToProps, mapDispatchToProps)(CondominiumPropertyExerciseEditionModal);
