import React, {FC} from "react";
import {Col, Row} from "react-bootstrap";
import {CoOwnersDetailsView} from "../../../../../../corelogic/usecases/condominiumdetailsview/coOwnersDetailsView";

type Props = {
    lot: CoOwnersDetailsView["Content"]["lots"][0];
};

export const ByCoOwnerLots: FC<Props> = ({lot}) => {
    return (
        <>
            <Row className="text-left mb-0">
                <Col className="mb-0">
                    <ul className="list-unstyled mb-0" style={{fontSize: 13}}>
                        <li>
                            {lot.number} : {lot.type} {lot.building} ({lot.quota} tantièmes)
                        </li>
                    </ul>
                </Col>
            </Row>
            <Row className="text-left mb-0 mt-4">
                <Col className="mb-0">
                    {lot.specialKeys.length > 0 && (
                        <table>
                            <tbody>
                            <tr style={{fontSize: 12}}>
                                <td colSpan={3} className="text-left">
                                    {lot.specialKeys.map((specialKey, index) => (
                                        <li key={index} className="list-unstyled">
                                            {specialKey.name} - {specialKey.quota} / {specialKey.shares}
                                        </li>
                                    ))}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    )}
                </Col>
            </Row>
        </>
    );
};
