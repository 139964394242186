import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useState} from "react";
import {Modal} from "react-bootstrap";
import {CoOwnerLotsConfigurationForm} from "./coownerlotsedition/form/coOwnerLotsConfigurationForm.component";
import {useDispatch, useSelector} from "react-redux";
import * as actionCreators from "./coownerlotsedition/form/actionsCreators";
import * as coOwnerLotsActionCreators from "../../../../../../corelogic/usecases/coownerlotsretrieval/actionCreators";
import * as coreLogicCreationActionCreators
    from "../../../../../../corelogic/usecases/coownerlotsaddition/actionCreators";
import * as coreLogicModificationActionCreators
    from "../../../../../../corelogic/usecases/coownerlotmodification/actionCreators";
import * as coOwnerLotsAdditionActionCreators
    from "../../../../../../corelogic/usecases/coownerlotsaddition/actionCreators";
import * as coreLogicLotRemovalActionCreators
    from "../../../../../../corelogic/usecases/coownerlotsremoval/actionCreators";
import {AppState} from "../../../../../../store/appState.interface";
import {CoOwnerLotsTable} from "./coOwnerLotsTable.component";
import {CustomSpinner} from "../../../layout/progresscomponents/spinners/customSpinner.component";
import {CoOwnerLot} from "../../../../../../corelogic/models/coOwnerLot";
import {createSelector, Selector} from "reselect";
import * as actionCreatorsSpecialKeyDisassociation
    from "../../../../../../corelogic/usecases/specialkeydisassociation/actionCreators";

interface Props {
    onClose: () => void;
    coOwnerId: string;
    coOwnerFirstName: string;
    coOwnerLastName: string;
    conjointFirstName: string | undefined;
    conjointLastName: string | undefined;
    coOwnerQuota: number;
    refresh: () => void;
}

export interface CoOwnerLotsVM {
    fetching: boolean;
    data: CoOwnerLot[] | null;
}

const getCoOwnerLotsVM: Selector<AppState, CoOwnerLotsVM> = createSelector(
    (state: AppState) => state.coreLogicState.coOwnerLots,
    (coOwnerLots) => ({
        fetching: coOwnerLots.fetching,
        data: coOwnerLots.data ? coOwnerLots.data : null,
    })
);

export const CoOwnerLotsConfigurationModal: FunctionComponent<Props> = ({
                                                                            coOwnerId,
                                                                            coOwnerFirstName,
                                                                            coOwnerLastName,
                                                                            conjointFirstName,
                                                                            conjointLastName,
                                                                            coOwnerQuota,
                                                                            onClose,
                                                                            refresh,
                                                                        }) => {
    const dispatch = useDispatch();

    const close = () => {
        onClose();
        refresh();
    };

    const [
        showCoOwnerLotsConfigurationForm,
        setShowCoOwnerLotsConfigurationForm,
    ] = useState(false);
    const [editedLot, setEditedLot] = useState<CoOwnerLot | null>(null);

    const justAddedLots = useSelector(
        (state: AppState) => state.coreLogicState.coOwnerLotsAddition.justAdded
    );
    const justUpdatedLot = useSelector(
        (state: AppState) => state.coreLogicState.coOwnerLotModification.justUpdated
    );

    const specialKeyDisassociated = useSelector(
        (state: AppState) => state.coreLogicState.specialKeyDisassociation
    );

    const justRemovedLot = useSelector(
        (state: AppState) => state.coreLogicState.coOwnerLotsRemoval.justRemoved
    );

    const coOwnerLotsVM = useSelector(getCoOwnerLotsVM);

    const retrieveCoOwnerLots = useCallback(() => {
        dispatch(coOwnerLotsActionCreators.Actions.retrieveCoOwnerLots(coOwnerId));
    }, [dispatch, coOwnerId]);

    const cancelCoOwnerLotEdition = useCallback(() => {
        setEditedLot(null);
        setShowCoOwnerLotsConfigurationForm(false);
        return true;
    }, [setShowCoOwnerLotsConfigurationForm]);

    useEffect(() => {
        retrieveCoOwnerLots();
    }, [retrieveCoOwnerLots]);

    useEffect(() => {
        if (coOwnerLotsVM.data && coOwnerLotsVM.data.length > 0) return;
        dispatch(actionCreators.Actions.setTotalAvailableShares(coOwnerQuota));
    }, [dispatch, coOwnerLotsVM, coOwnerQuota]);

    useEffect(() => {
        if (justAddedLots) {
            dispatch(
                coOwnerLotsAdditionActionCreators.Actions.clearDataAboutCoOwnerLotsAddition()
            );
            cancelCoOwnerLotEdition();
            retrieveCoOwnerLots();
        }
        if (justUpdatedLot) {
            dispatch(
                coreLogicModificationActionCreators.Actions.clearDataAboutCoOwnerLotUpdate()
            );
            cancelCoOwnerLotEdition();
            retrieveCoOwnerLots();
        }
        if (justRemovedLot) {
            dispatch(
                coreLogicLotRemovalActionCreators.Actions.clearDataAboutCoOwnerLotsRemoval()
            );
            retrieveCoOwnerLots();
        }
        if (specialKeyDisassociated.justDisassociated) {
            dispatch(
                coOwnerLotsActionCreators.Actions.retrieveCoOwnerLots(coOwnerId)
            );
            dispatch(actionCreatorsSpecialKeyDisassociation.Actions.resetSpecialKeyDisassociation());
        }
    }, [
        cancelCoOwnerLotEdition,
        justAddedLots,
        justRemovedLot,
        justUpdatedLot,
        dispatch,
        refresh,
        retrieveCoOwnerLots,
        coOwnerId,
        specialKeyDisassociated.justDisassociated
    ]);

    const onComplete = (lotNumberReference: string | undefined, values: any) => {
        const {quota, lotType, lotNumber, building} = values;
        if (editedLot) {
            dispatch(
                coreLogicModificationActionCreators.Actions.updateCoOwnerLot(
                    coOwnerId,
                    lotNumberReference!,
                    +quota,
                    lotNumber,
                    lotType,
                    building
                )
            );
        } else {
            dispatch(
                actionCreators.Actions.addCoOwnerLot(
                    1,
                    +quota,
                    lotNumber,
                    lotType,
                    building
                )
            );
            dispatch(
                coreLogicCreationActionCreators.Actions.addCoOwnerLots(coOwnerId)
            );
        }
    };

    const computeActualLotsQuota = (coOwnerLots: CoOwnerLot[]) => {
        return coOwnerLots.reduce((acc, coOwnerLot) => {
            if (editedLot && editedLot.lotNumber === coOwnerLot.lotNumber) return acc;
            return acc + coOwnerLot.quota;
        }, 0);
    };

    const addNewCoOwnerLot = () => {
        setShowCoOwnerLotsConfigurationForm(true);
    };

    const editLot = (lotNumber: string) => (e: any) => {
        setEditedLot(
            coOwnerLotsVM.data?.find((lotVM) => lotVM.lotNumber === lotNumber)!
        );
        setShowCoOwnerLotsConfigurationForm(true);
    };

    return (
        <div>
            <Modal show={true} onHide={close} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        Lots - {coOwnerFirstName + " " + coOwnerLastName}{" "}
                        {conjointFirstName
                            ? " - " + conjointFirstName + " " + conjointLastName
                            : ""}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {showCoOwnerLotsConfigurationForm ? (
                        <CoOwnerLotsConfigurationForm
                            remainingQuota={
                                coOwnerQuota - computeActualLotsQuota(coOwnerLotsVM.data ?? [])
                            }
                            cancelCoOwnerLotAddition={cancelCoOwnerLotEdition}
                            onSubmittedNewCoOwnerLot={onComplete}
                            editedLot={editedLot}
                        />
                    ) : (
                        <div>
                            {(coOwnerLotsVM.fetching || !coOwnerLotsVM.data) && (
                                <CustomSpinner/>
                            )}
                            {!coOwnerLotsVM.fetching && coOwnerLotsVM.data && (
                                <>
                                    <h6>
                                        Tantièmes {computeActualLotsQuota(coOwnerLotsVM.data)} /{" "}
                                        {coOwnerQuota}
                                    </h6>
                                    <br/>
                                    <CoOwnerLotsTable
                                        coOwnerId={coOwnerId}
                                        remainingQuota={
                                            coOwnerQuota -
                                            computeActualLotsQuota(coOwnerLotsVM.data ?? [])
                                        }
                                        coOwnerLots={coOwnerLotsVM.data}
                                        addNewCoOwnerLot={addNewCoOwnerLot}
                                        editLot={editLot}
                                    />
                                </>
                            )}
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};
