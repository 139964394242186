import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {BalanceBeforeCall} from "../../models/balanceBeforeCall.interface";

export const RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES = 'RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES';
export const BALANCE_BEFORE_CALLS_ARCHIVES_RETRIEVED = 'BALANCE_BEFORE_CALLS_ARCHIVES_RETRIEVED';

export const Actions = {
    retrieveBalanceBeforeCallsArchives: (condominiumPropertyExerciseId: string) =>
        createAction(RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES, {condominiumPropertyExerciseId}),
    balanceBeforeCallsArchivesRetrieved: (balanceBeforeCallsArchives: BalanceBeforeCall[]) =>
        createAction(BALANCE_BEFORE_CALLS_ARCHIVES_RETRIEVED, balanceBeforeCallsArchives)
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;