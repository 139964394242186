import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { CoOwnerLotsVM } from "./coOwnerLotsConfigurationModal.component";
import { useDispatch, useSelector } from "react-redux";
import * as coOwnerLotsRemovalActionCreators from "../../../../../../corelogic/usecases/coownerlotsremoval/actionCreators";
import { AppState } from "../../../../../../store/appState.interface";
import { CoOwnerLotsTableItem } from "./coOwnerLotsTableItem.component";

interface Props {
  coOwnerLots: CoOwnerLotsVM["data"];
  coOwnerId: string;
  editLot: (lotNumber: string) => (e: any) => void;
  addNewCoOwnerLot: () => void;
  remainingQuota: number;
}

export const CoOwnerLotsTable: FunctionComponent<Props> = ({
  coOwnerLots,
  coOwnerId,
  addNewCoOwnerLot,
  remainingQuota,
  editLot,
}) => {
  const dispatch = useDispatch();

  const [selectedIds, setSelectedIds] = useState<{
    [lotNumber: string]: boolean;
  }>({});
  const [allSelected, setAllSelected] = useState(false);

  const justRemovedLots = useSelector(
    (state: AppState) => state.coreLogicState.coOwnerLotsRemoval.justRemoved
  );

  useEffect(() => {
    if (justRemovedLots) {
      setAllSelected(false);
    }
  }, [justRemovedLots, setAllSelected]);

  const toggleAllLots = () => {
    setAllSelected(!allSelected);
    if (!allSelected)
      setSelectedIds(
        coOwnerLots!.reduce((acc, b) => ({ ...acc, [b.lotNumber]: true }), {})
      );
    else setSelectedIds({});
  };

  useEffect(() => {
    if (
      coOwnerLots &&
      Object.keys(selectedIds).length === coOwnerLots.length &&
      coOwnerLots.length !== 0
    )
      setAllSelected(true);
    else setAllSelected(false);
  }, [selectedIds, coOwnerLots]);

  const deleteSelectedIds = () => {
    dispatch(
      coOwnerLotsRemovalActionCreators.Actions.removeCoOwnerLots(
        coOwnerId,
        Object.keys(selectedIds)
      )
    );
  };

  return (
    <div>
      <Row>
        <Col className="text-left align-self-end">
          <div>
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0 }}>
                <div className="form-check include-conjoint-checkbox form-check-inline">
                  <input
                    type="checkbox"
                    checked={allSelected}
                    onChange={toggleAllLots}
                    className="form-check-input"
                  />
                  <label className="form-check-label">Tout cocher</label>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col className="text-right">
          <Row>
            <Col>
              <div>
                <button
                  className="btn btn-danger"
                  disabled={Object.keys(selectedIds).length === 0}
                  onClick={deleteSelectedIds}
                  style={{ color: "#fff" }}
                >
                  <span>
                    <i className="fas fa-trash" />
                  </span>
                </button>
              </div>
            </Col>
            <Col>
              <div>
                <button
                  className="btn btn-primary"
                  disabled={remainingQuota === 0}
                  onClick={addNewCoOwnerLot}
                  style={{ color: "#fff" }}
                >
                  <span>
                    <i className="fas fa-plus" />
                  </span>
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      {coOwnerLots!.length > 0 ? (
        <Table responsive className="text-center" borderless>
          <thead>
            <tr>
              <th />
              <th>Numéro</th>
              <th>Type</th>
              <th>Bâtiment</th>
              <th>Tantièmes</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {coOwnerLots!.map((lot, index) => (
              <CoOwnerLotsTableItem
                key={lot.lotNumber}
                lot={lot}
                coOwnerId={coOwnerId}
                index={index}
                selectedIds={selectedIds}
                editLot={editLot}
                setSelectedIds={setSelectedIds}
              />
            ))}
          </tbody>
        </Table>
      ) : (
        <p className="text-center">Aucun lot configuré</p>
      )}
    </div>
  );
};
