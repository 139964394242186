import { Card, Col, Row } from "react-bootstrap";
import React, { FC, useCallback, useState } from "react";
import { LotsDetailsView } from "../../../../../../corelogic/usecases/condominiumdetailsview/lotsDetailsView";
import { CoOwnerDetailsModal } from "../../coownerdetailsscreen";
import * as condominiumDetailsViewActionCreators from "../../../../../../corelogic/usecases/condominiumdetailsview/actionCreators";
import { useDispatch } from "react-redux";

type Props = {
  details: LotsDetailsView["Content"];
  condominiumShares: number;
};
export const ByLotCard: FC<Props> = ({ details, condominiumShares }) => {
  const dispatch = useDispatch();
  const [shouldShowCoOwnerProfile, setShouldShowCoOwnerProfile] =
    useState(false);
  const [coOwnerId, setCoOwnerId] = useState("");
  const [isConjointProfile, setConjointProfile] = useState(false);

  const refreshContent = useCallback(() => {
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveCoOwnersDetailsView()
    );
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveLotsDetailsView()
    );
  }, [dispatch]);

  const showCoOwnerProfile = useCallback(
    (coOwnerId: string, conjoint: boolean) => () => {
      setCoOwnerId(coOwnerId);
      if (conjoint) {
        setConjointProfile(true);
        setShouldShowCoOwnerProfile(true);
      } else {
        setConjointProfile(false);
        setShouldShowCoOwnerProfile(true);
      }
    },
    [setShouldShowCoOwnerProfile]
  );

  const closeCoOwnerProfile = useCallback(() => {
    setShouldShowCoOwnerProfile(false);
  }, [setShouldShowCoOwnerProfile]);

  return (
    <div>
      <Row className="justify-content-center mt-3">
        <Col sm={8} md={12} lg={6}>
          <Card>
            <Card.Body className="p-2">
              <Row className="mb-0">
                <Col className="text-left" xs={12}>
                  <p className="mb-0" style={{ fontSize: 15 }}>
                    {details.lot.number}
                    {" - "}
                    {details.lot.type} {details.lot.building}
                  </p>
                  <p
                    className="link mb-0"
                    style={{ fontSize: 15 }}
                    onClick={showCoOwnerProfile(details.mainCoOwner.id!, false)}
                  >
                    {details.mainCoOwner.lastName}{" "}
                    {details.mainCoOwner.firstName}
                  </p>
                  {details.conjointCoOwner && (
                    <p
                      className="link"
                      style={{ fontSize: 13, marginBottom: 0 }}
                      onClick={showCoOwnerProfile(
                        details.conjointCoOwner.id!,
                        true
                      )}
                    >
                      {details.conjointCoOwner.lastName}{" "}
                      {details.conjointCoOwner.firstName}
                    </p>
                  )}
                </Col>
                <Col className="text-right" xs={12}>
                  <p className="mb-0" style={{ fontSize: 15 }}>
                    {details.lot.quota} / {condominiumShares}
                  </p>
                </Col>
              </Row>
              {details.lot.specialKeys.length > 0 && (
                <div className="text-left mt-2" style={{ fontSize: 13 }}>
                  <Row>
                    <Col xs={12}>
                      <ul className="list-unstyled">
                        {details.lot.specialKeys.map((specialKey) => {
                          return (
                            <li key={specialKey.id}>
                              {specialKey.name} - {specialKey.quota} /{" "}
                              {specialKey.shares}
                            </li>
                          );
                        })}
                      </ul>
                    </Col>
                  </Row>
                </div>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {shouldShowCoOwnerProfile && (
        <CoOwnerDetailsModal
          onClose={closeCoOwnerProfile}
          coOwnerId={coOwnerId}
          conjoint={isConjointProfile}
          refresh={refreshContent}
        />
      )}
    </div>
  );
};
