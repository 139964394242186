import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {SpecialKeyGateway} from "../../gateways/specialKeyGateway.interface";
import {map, switchMap} from "rxjs/operators";

export const disassociateSpecialKey = (action$: ActionsObservable<actionCreators.Actions>,
                                       state$: StateObservable<AppState>,
                                       {specialKeyGateway}: {
                                        specialKeyGateway: SpecialKeyGateway
                                    }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.disassociateSpecialKey>>(actionCreators.DISASSOCIATE_SPECIAL_KEY),
        switchMap(action => {
            const {lotNumber, specialKeyId} = action.payload;
            return specialKeyGateway.disassociate(
                lotNumber, specialKeyId
            ).pipe(
                map(() => actionCreators.Actions.specialKeyDisassociated(specialKeyId))
            );
        }));
}
