import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const LAUNCH_CALL_FOR_FUNDS = 'LAUNCH_CALL_FOR_FUNDS';
export const CALL_FOR_FUNDS_LAUNCHED = 'CALL_FOR_FUNDS_LAUNCHED';

export const Actions = {
    launchCallForFunds: () =>
        createAction(LAUNCH_CALL_FOR_FUNDS),
    callForFundsLaunched: (callForFundsId: string) =>
        createAction(CALL_FOR_FUNDS_LAUNCHED, {callForFundsId})
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;