import {createAction} from "../../../../../../../store/createAction";
import {ActionsUnion} from "../../../../../../../store/actionsUnions";
import {AddedCoOwnerContactData} from "../../../../../../../corelogic/usecases/coowneraddition/addedCoOwnerContactData";

export const ADD_CO_OWNER = 'ADD_CO_OWNER';
export const ADD_CO_OWNER_CONJOINT = 'ADD_CO_OWNER_CONJOINT';

export const Actions = {
    addCoOwner: (quota: number, addedCoOwnerContactData: AddedCoOwnerContactData) =>
        createAction(ADD_CO_OWNER, {quota, addedCoOwnerContactData}),
    addCoOwnerConjoint: (addedCoOwnerContactData: AddedCoOwnerContactData) =>
        createAction(ADD_CO_OWNER_CONJOINT, {addedCoOwnerContactData})
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;