import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const retrieveCondominiumPropertyCoOwnersList = (action$: ActionsObservable<actionCreators.Actions>,
                                                        state$: StateObservable<AppState>,
                                                        {coOwnerGateway}:
                                                            { coOwnerGateway: CoOwnerGateway }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.retrieveCondominiumPropertyCoOwnersList>>(actionCreators.RETRIEVE_CONDOMINIUM_PROPERTY_CO_OWNERS_LIST),
        // debounceTime(200),
        switchMap(action => {
            return coOwnerGateway.retrieveCondominiumPropertyCoOwnersList(action.payload.condominiumPropertyId)
                .pipe(
                    map(condominiumProperties =>
                        actionCreators.Actions.condominiumPropertyCoOwnersListRetrieved(condominiumProperties))
                );
        }));
};
