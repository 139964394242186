import { Axios } from "axios-observable";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { CondominiumDetailsViewGateway } from "../../../corelogic/gateways/condominiumDetailsViewGateway.interface";
import { CoOwnersDetailsView } from "../../../corelogic/usecases/condominiumdetailsview/coOwnersDetailsView";
import { LotsDetailsView } from "../../../corelogic/usecases/condominiumdetailsview/lotsDetailsView";

export class HttpCondominiumDetailsViewGateway
  implements CondominiumDetailsViewGateway
{
  byCoOwner(condominiumPropertyId: string): Observable<CoOwnersDetailsView> {
    return Axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/condominiumproperties/" +
        condominiumPropertyId +
        "/coownersdetailsview"
    ).pipe(map((response) => response?.data ?? null));
  }

  byLots(condominiumPropertyId: string): Observable<LotsDetailsView> {
    return Axios.get(
      process.env.REACT_APP_BASE_API_URL +
        "/condominiumproperties/" +
        condominiumPropertyId +
        "/lotsdetailsview"
    ).pipe(map((response) => response?.data ?? null));
  }
}
