import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {map, switchMap} from "rxjs/operators";
import {AppState} from "../../../store/appState.interface";
import {CondominiumPropertyGateway} from "../../gateways/condominiumPropertyGateway.interface";

export const retrieveCondominiumProperties = (action$: ActionsObservable<actionCreators.Actions>,
                                              state$: StateObservable<AppState>,
                                              {condominiumPropertyGateway}:
                                                  { condominiumPropertyGateway: CondominiumPropertyGateway }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.retrieveCondominiumProperties>>(actionCreators.RETRIEVE_CONDOMINIUM_PROPERTIES),
        // debounceTime(200),
        switchMap(() => {
            return condominiumPropertyGateway.retrieve()
                .pipe(
                    map(condominiumProperties => {
                        return actionCreators.Actions.condominiumPropertiesRetrieved(condominiumProperties)
                    })
                );
        }));
};
