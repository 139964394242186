import * as React from "react";
import {FunctionComponent} from "react";
import {ExerciceIndicator} from "./exerciceIndicator.component";
import {CallsForFundsIndicators} from "./callsForFundsIndicator.component";
import {CallsForFundsSynthesis} from "../../../../../../corelogic/models/callsForFundsSynthesis.interface";

interface Props {
    callsForFundsSynthesis: CallsForFundsSynthesis;
    currentlyPaid: number;
    delays: {
        numberOfDelays: number,
        amount: number
    }
}

export const Indicators: FunctionComponent<Props> = ({callsForFundsSynthesis, currentlyPaid, delays}) => {
    return <div className="indicators">
        <div>
            <ExerciceIndicator callsForFundsSynthesis={callsForFundsSynthesis}/>
        </div>
        <div style={{marginTop: 20}}>
            <CallsForFundsIndicators callsForFundsSynthesis={callsForFundsSynthesis}
                                     currentlyPaid={currentlyPaid}
            delays={delays}/>
        </div>
    </div>;
}