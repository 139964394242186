import {combineReducers} from "redux";
import * as actionCreators from "../usecases/callsforfundsyearsynthesisretrieval/actionCreators";
import * as launchCallForFundsActionCreators from "../usecases/callforfundslaunching/actionCreators";
import {CallsForFundsSynthesis} from "../models/callsForFundsSynthesis.interface";

const data = (state: CallsForFundsSynthesis = {
    exercice: {} as any,
    callsForFunds: []
}, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CALLS_FOR_FUNDS_RETRIEVED:
            return action.payload || {} as any;
        default:
            return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.RETRIEVE_CALLS_FOR_FUNDS;
};

const newCallForFundsJustLaunched = (state: string | null = null,
                                     action: actionCreators.Actions | launchCallForFundsActionCreators.Actions) => {
    if (action.type === launchCallForFundsActionCreators.CALL_FOR_FUNDS_LAUNCHED)
        return action.payload.callForFundsId;
    return state;
}

const launchingNewCallForFunds = (state: boolean = false, action: launchCallForFundsActionCreators.Actions) => {
    return action.type === launchCallForFundsActionCreators.LAUNCH_CALL_FOR_FUNDS;
}

export default combineReducers({
    data,
    fetching,
    newCallForFundsJustLaunched,
    launchingNewCallForFunds
});
