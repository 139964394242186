import Select, { OnChangeValue } from "react-select";
import { Col, Row } from "react-bootstrap";
import { CondominiumProperty } from "../../../../corelogic/models/condominiumProperty.interface";
import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "../../../../store/appState.interface";

interface Props {
  condominiumProperties: CondominiumProperty[];
  onCondominiumSelected: (
    condominiumPropertyId: string,
    name: string,
    shares: number
  ) => void;
}

export const CondominiumPropertiesWidget: FunctionComponent<Props> = ({
  condominiumProperties,
  onCondominiumSelected,
}) => {
  const [selectOptions, setSelectOptions] = useState<
    { value: { id: string; shares: number }; label: string }[]
  >([]);
  const [selectedOption, setSelectedOption] = useState<OnChangeValue<
    { value: { id: string; shares: number }; label: string },
    false
  > | null>(null);

  const selectedCondominiumProperty = useSelector(
    (state: AppState) => state.uiState.selectedCondominiumProperty
  );

  useEffect(() => {
    if (condominiumProperties.length === 0) return;
    const options: { value: { id: string; shares: number }; label: string }[] =
      condominiumProperties.map((c) => ({
        label: c.name,
        value: {
          id: c.id,
          shares: c.shares,
        },
      }));
    if (
      selectedOption &&
      selectedCondominiumProperty &&
      selectedCondominiumProperty.id !== (selectedOption as any).value.id
    ) {
      const option = options.find(
        (o) => o.value.id === selectedCondominiumProperty.id
      );
      if (option)
        setSelectedOption(selectedCondominiumProperty ? option : options[0]);
    } else if (!selectedOption && condominiumProperties.length > 0) {
      setSelectedOption(options[0]);
      const { value, label } = options[0] as {
        value: { id: string; shares: number };
        label: string;
      };
      onCondominiumSelected(value.id, label, value.shares);
    }
    setSelectOptions(options);
  }, [
    onCondominiumSelected,
    condominiumProperties,
    selectedOption,
    selectedCondominiumProperty,
  ]);

  const handleChange = (
    option: OnChangeValue<
      { value: { id: string; shares: number }; label: string },
      false
    >
  ) => {
    if (option) {
      setSelectedOption(option);
      const { value, label } = option as {
        value: { id: string; shares: number };
        label: string;
      };
      onCondominiumSelected(value.id, label, value.shares);
    }
  };

  return (
    <Row className="justify-content-md-center condominium-properties-selection">
      <Col xs={1} style={{ paddingTop: 7 }}>
        <i className="fas fa-search" />
      </Col>
      <Col style={{ marginLeft: 0, paddingLeft: 0 }}>
        <form data-testid="selectCondominiumPropertyForm">
          <label
            htmlFor="condominiumPropertySelection"
            style={{ display: "none" }}
          >
            condominiumPropertySelection
          </label>
          <Select
            className="select-input"
            options={selectOptions}
            styles={customStyles}
            noOptionsMessage={() => null}
            placeholder="Votre copropriété"
            onChange={handleChange}
            name="condominiumPropertySelection"
            inputId="condominiumPropertySelection"
            value={selectedOption}
          />
        </form>
      </Col>
    </Row>
  );
};

const customStyles = {
  option: (base: any, state: any) => ({
    ...base,
    padding: 20,
    color: "#000",
    backgroundColor: "#f3f3f3",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#f16e00",
    },
  }),
  menu: (base: any) => ({
    ...base,
    zIndex: 100,
    padding: 0,
    margin: 0,
    border: 0,
  }),
  menuList: (base: any) => ({
    ...base,
  }),
  control: (base: any, state: any) => ({
    ...base,
    fontFamily: "'Roboto Condensed', sans-serif",
    border: state.isFocused ? 0 : 0,
    // This line disable the blue border
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
  }),
  singleValue: (provided: any, state: any) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return { ...provided, opacity, transition };
  },
};
