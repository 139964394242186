import { ActionsObservable, ofType, StateObservable } from "redux-observable";
import * as actionCreators from "./actionCreators";
import { AppState } from "../../../store/appState.interface";
import { map, switchMap } from "rxjs/operators";
import { CoOwnersDetailsView } from "./coOwnersDetailsView";
import { CondominiumDetailsViewGateway } from "../../gateways/condominiumDetailsViewGateway.interface";
import { LotsDetailsView } from "./lotsDetailsView";

export const retrieveCoOwnersDetailsView = (
  action$: ActionsObservable<actionCreators.Actions>,
  state$: StateObservable<AppState>,
  {
    condominiumDetailsViewGateway,
  }: {
    condominiumDetailsViewGateway: CondominiumDetailsViewGateway;
  }
) => {
  return action$.pipe(
    ofType<
      actionCreators.Actions,
      ReturnType<typeof actionCreators.Actions.retrieveCoOwnersDetailsView>
    >(actionCreators.RETRIEVE_CO_OWNERS_DETAILS_VIEW),
    switchMap(() => {
      return condominiumDetailsViewGateway
        .byCoOwner(state$.value.uiState.selectedCondominiumProperty!.id)
        .pipe(
          map((details: CoOwnersDetailsView) =>
            actionCreators.Actions.coOwnersDetailsViewRetrieved(details)
          )
        );
    })
  );
};

export const retrieveLotsDetailsView = (
  action$: ActionsObservable<actionCreators.Actions>,
  state$: StateObservable<AppState>,
  {
    condominiumDetailsViewGateway,
  }: {
    condominiumDetailsViewGateway: CondominiumDetailsViewGateway;
  }
) => {
  return action$.pipe(
    ofType<
      actionCreators.Actions,
      ReturnType<typeof actionCreators.Actions.retrieveLotsDetailsView>
    >(actionCreators.RETRIEVE_LOTS_DETAILS_VIEW),
    switchMap(() => {
      return condominiumDetailsViewGateway
        .byLots(state$.value.uiState.selectedCondominiumProperty!.id)
        .pipe(
          map((details: LotsDetailsView) =>
            actionCreators.Actions.lotsDetailsViewRetrieved(details)
          )
        );
    })
  );
};
