import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const removeCoOwner = (action$: ActionsObservable<actionCreators.Actions>,
                                  state$: StateObservable<AppState>,
                                  {coOwnerGateway}: {
                                   coOwnerGateway: CoOwnerGateway
                               }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.removeCoOwner>>(actionCreators.REMOVE_CO_OWNER),
        // debounceTime(200),
        switchMap(action => {
                return coOwnerGateway.remove(action.payload.coOwnerId).pipe(
                    map(() => actionCreators.Actions.coOwnerRemoved(action.payload.coOwnerId))
                )
            }
        )
    )
};
