import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const CASH_CO_OWNER_MONEY = 'CASH_CO_OWNER_MONEY';
export const CO_OWNER_MONEY_CASHED = 'CO_OWNER_MONEY_CASHED';
export const CLEAR_MONEY_CASH_STATUS = 'CLEAR_MONEY_CASH_STATUS';

export const Actions = {
    cashCoOwnerMoney: (callForFundsAssignmentId: string, amount: number, cashingDate: Date) =>
        createAction(CASH_CO_OWNER_MONEY, {callForFundsAssignmentId, amount, cashingDate}),
    coOwnerMoneyCashed: (callForFundsAssignmentId: string) =>
        createAction(CO_OWNER_MONEY_CASHED, {callForFundsAssignmentId}),
    clearMoneyCashStatus: () => createAction(CLEAR_MONEY_CASH_STATUS),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;