import { Button, Col, FormGroup, FormLabel, Modal, Row } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { SelectFormField } from "../../../formcustomfields/selectFormField.component";
import React, { FC, useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../../store/appState.interface";
import * as listActionCreators from "../../../../../../../corelogic/usecases/specialkeyslist/actionCreators";
import * as actionCreatorsSpecialKeyAssociation from "../../../../../../../corelogic/usecases/specialkeyassociation/actionCreators";
import { NumberFormField } from "../../../formcustomfields/numberFormField.component";
import * as coOwnerLotsActionCreators from "../../../../../../../corelogic/usecases/coownerlotsretrieval/actionCreators";
import { SpecialKeyAdditionModal } from "../../../specialkeysmanagement/specialKeyAdditionModal";

interface Props {
  closeModalFn: () => void;
  lotNumber: string;
  coOwnerId: string;
}

export const SpecialKeyAssociationModal: FC<Props> = ({
  closeModalFn,
  lotNumber,
  coOwnerId,
}) => {
  const dispatch = useDispatch();
  const [showSpecialKeyAdditionModal, setShowSpecialKey] = useState(false);
  const specialKeysList = useSelector(
    (state: AppState) => state.coreLogicState.specialKeysList
  );

  const specialKeyAssociated = useSelector(
    (state: AppState) => state.coreLogicState.specialKeyAssociation
  );

  useEffect(() => {
    dispatch(listActionCreators.Actions.listSpecialKeys());
  }, [dispatch]);

  const handleSubmit = (values: any) => {
    dispatch(
      actionCreatorsSpecialKeyAssociation.Actions.associateSpecialKey(
        coOwnerId,
        lotNumber,
        values.specialKeyId,
        values.quota
      )
    );
  };

  const openSpecialKeyAdditionModal = () => setShowSpecialKey(true);

  const closeSpecialKeyAdditionModal = () => setShowSpecialKey(false);

  useEffect(() => {
    if (specialKeyAssociated.justAssociated) {
      dispatch(
        coOwnerLotsActionCreators.Actions.retrieveCoOwnerLots(coOwnerId)
      );
      dispatch(
        actionCreatorsSpecialKeyAssociation.Actions.resetSpecialKeyAssociation()
      );
    }
  }, [dispatch, specialKeyAssociated.justAssociated, coOwnerId]);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          specialKeyId:
            specialKeysList.data &&
            specialKeysList.data[0] &&
            specialKeysList.data[0].id,
          quota: undefined,
        }}
        validationSchema={specialKeyFormSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, submitForm }) => {
          return (
            <Modal show={!showSpecialKeyAdditionModal} onHide={closeModalFn}>
              <Modal.Header closeButton>
                <Modal.Title>Clé spéciale à associer</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {!specialKeysList.fetching &&
                  specialKeysList.data &&
                  specialKeysList.data.length === 0 && (
                    <div className="text-center">
                      <p>
                        Pas de clés spéciales disponibles pour association à ce
                        lot.
                      </p>
                      <p>
                        <Button
                          variant="link"
                          onClick={openSpecialKeyAdditionModal}
                        >
                          Cliquez ici pour en créer
                        </Button>
                      </p>
                    </div>
                  )}
                <Form>
                  {!specialKeysList.fetching &&
                    specialKeysList.data &&
                    specialKeysList.data.length > 0 && (
                      <>
                        <FormGroup>
                          <Row>
                            <Col>
                              <FormLabel htmlFor="specialKeyId">
                                Nom de la clé
                              </FormLabel>
                              <Field
                                id="specialKeyId"
                                name="specialKeyId"
                                component={SelectFormField}
                                defaultValue={specialKeysList.data[0].id}
                              >
                                {specialKeysList.data.map((specialKey) => (
                                  <option
                                    key={specialKey.id}
                                    value={specialKey.id}
                                  >
                                    {specialKey.name}
                                  </option>
                                ))}
                              </Field>
                              {errors.specialKeyId && touched.specialKeyId ? (
                                <div className="error-message">
                                  {errors.specialKeyId}
                                </div>
                              ) : null}
                              <p>
                                <Button
                                  variant="link"
                                  onClick={openSpecialKeyAdditionModal}
                                >
                                  La clé n'existe pas, cliquez ici pour la créer
                                </Button>
                              </p>
                            </Col>
                          </Row>
                        </FormGroup>
                        <FormGroup>
                          <Row>
                            <Col>
                              <FormLabel htmlFor="quota">Tantièmes</FormLabel>
                              <Field
                                className="form-control"
                                id="quota"
                                name="quota"
                                component={NumberFormField}
                                style={{ width: 100 }}
                              />
                              {errors.quota && touched.quota ? (
                                <div className="error-message">
                                  {errors.quota}
                                </div>
                              ) : null}
                            </Col>
                          </Row>
                        </FormGroup>
                      </>
                    )}
                </Form>
              </Modal.Body>
              {!specialKeysList.fetching &&
                specialKeysList.data &&
                specialKeysList.data.length > 0 && (
                  <Modal.Footer style={{ border: 0 }}>
                    <Button variant="secondary" onClick={closeModalFn}>
                      Annuler
                    </Button>
                    <Button onClick={submitForm} className="btn button-action">
                      Valider
                    </Button>
                  </Modal.Footer>
                )}
            </Modal>
          );
        }}
      </Formik>
      {showSpecialKeyAdditionModal && (
        <SpecialKeyAdditionModal onClose={closeSpecialKeyAdditionModal} />
      )}
    </>
  );
};

const specialKeyFormSchema = () =>
  Yup.object().shape({
    quota: Yup.number()
      .min(1, "Le nombre de tantièmes doit être supérieur à 0.")
      // TODO
      // .max(availableShares, 'Le nombre de tantièmes doit être inférieur ou égal à ' + availableShares + '.')
      .required("Le nombre de tantièmes est requis."),
  });
