import { slide as Menu } from "react-burger-menu";
import * as React from "react";
import { Component } from "react";
import { Nav } from "react-bootstrap";
import { LoggedUserInfo } from "../topbar/loggeduserinfo/loggedUserInfo.component";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";
import gratitudeLogo from "../../../img/gratitude-shrink-logo.png";
import { AppState } from "../../../../../store/appState.interface";
import { connect } from "react-redux";

interface Props extends RouteComponentProps {
  selectedCondominiumProperty?: { id: string; name: string } | null;
}

interface State {
  menuOpen: boolean;
  showSettings: boolean;
}

class SidebarMenu extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      menuOpen: false,
      showSettings: false,
    };
  }

  goTo = (path: string) => () => {
    this.setState({ menuOpen: false });
    this.props.history.push(path);
  };

  handleStateChange(state: State) {
    this.setState({ menuOpen: state.menuOpen });
  }

  toggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };

  render() {
    const currentLocation = this.props.history.location;
    return (
      <div className="sidebar-menu">
        <Menu
          isOpen={this.state.menuOpen}
          onStateChange={(state: any) => this.handleStateChange(state)}
        >
          <div className="logo-wrapper text-center">
            <img
              src={gratitudeLogo}
              alt="Logo Gratitude"
              width="80%"
              height="80%"
            />
          </div>
          <LoggedUserInfo />
          <SideMenuLink
            text=""
            goTo={this.goTo}
            path="/coproprietes"
            icon="fa-building"
            currentLocation={currentLocation}
          />
          <hr />
          {this.props.selectedCondominiumProperty && (
            <>
              <p
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  padding: 3,
                }}
              >
                {this.props.selectedCondominiumProperty.name}
              </p>
              <SideMenuLink
                text="Composition"
                goTo={this.goTo}
                path="/details"
                icon="fa-paper-plane"
                currentLocation={currentLocation}
              />
              <SideMenuLink
                text="Mes appels de fonds"
                goTo={this.goTo}
                path="/appelsdefonds"
                icon="fa-paper-plane"
                currentLocation={currentLocation}
              />
              <hr />
              <p
                style={{ fontSize: 14, textAlign: "center" }}
                onClick={this.toggleSettings}
              >
                <i className="fas fa-cog" /> Paramétrages&nbsp;&nbsp;
                {this.state.showSettings ? (
                  <i className="fas fa-chevron-up" />
                ) : (
                  <i className="fas fa-chevron-down" />
                )}
              </p>
              {this.state.showSettings && (
                <>
                  <SideMenuLink
                    text="Clés spéciales"
                    goTo={this.goTo}
                    path="/mes-cles-speciales"
                    icon="fa-key"
                    currentLocation={currentLocation}
                  />
                </>
              )}
            </>
          )}
        </Menu>
        <div className="sidebar-sticky" style={{ width: 260 }}>
          <div className="logo-wrapper text-center">
            <img
              src={gratitudeLogo}
              alt="Logo Gratitude"
              width="80%"
              height="80%"
            />
          </div>
          <LoggedUserInfo />
          <SideMenuLink
            text="Mes copropriétés"
            goTo={this.goTo}
            path="/coproprietes"
            icon="fa-broadcast"
            currentLocation={currentLocation}
          />
          <hr />
          {this.props.selectedCondominiumProperty && (
            <>
              <p
                style={{
                  fontSize: 14,
                  textAlign: "center",
                  padding: 3,
                }}
              >
                {this.props.selectedCondominiumProperty.name}
              </p>
              <SideMenuLink
                text="Composition"
                goTo={this.goTo}
                path="/details"
                icon="fa-list"
                currentLocation={currentLocation}
              />
              <SideMenuLink
                text="Mes appels de fonds"
                goTo={this.goTo}
                path="/appelsdefonds"
                icon="fa-paper-plane"
                currentLocation={currentLocation}
              />
              <hr />
              <p
                style={{ fontSize: 14, textAlign: "center" }}
                onClick={this.toggleSettings}
              >
                <i className="fas fa-cog" /> Paramétrages&nbsp;&nbsp;
                {this.state.showSettings ? (
                  <i className="fas fa-chevron-up" />
                ) : (
                  <i className="fas fa-chevron-down" />
                )}
              </p>
              {this.state.showSettings && (
                <>
                  <SideMenuLink
                    text="Clés spéciales"
                    goTo={this.goTo}
                    path="/mes-cles-speciales"
                    icon="fa-key"
                    currentLocation={currentLocation}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

const SideMenuLink = ({
  text,
  goTo,
  path,
  icon,
  currentLocation,
}: {
  text: string;
  goTo: (path: string) => () => void;
  path: string;
  icon: string;
  currentLocation: any;
}) => (
  <Nav defaultActiveKey="#" className="flex-column">
    <Nav.Link
      onClick={goTo(path)}
      className={
        "text-center " +
        (currentLocation.pathname === path ? "selected-menu" : "")
      }
    >
      <i className={"fas " + icon} />
      &nbsp;&nbsp;
      <span>{text}</span>
    </Nav.Link>
  </Nav>
);

const mapStateToProps = (state: AppState) => ({
  selectedCondominiumProperty: state.uiState.selectedCondominiumProperty,
});

export default withRouter(connect(mapStateToProps)(SidebarMenu));
