import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CallForFundsGateway} from "../../gateways/callForFundsGateway.interface";
import {UuidGenerator} from "../../models/write/uuidGenerator.interface";

export const launchCallForFunds = (action$: ActionsObservable<actionCreators.Actions>,
                                   state$: StateObservable<AppState>,
                                   {callForFundsGateway, uuidGenerator}:
                                       {
                                           callForFundsGateway: CallForFundsGateway,
                                           uuidGenerator: UuidGenerator
                                       }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.launchCallForFunds>>(actionCreators.LAUNCH_CALL_FOR_FUNDS),
        switchMap(() => {
            const callForFundsId = uuidGenerator.generate();
                return callForFundsGateway.launchNewCallForFunds(
                    state$.value.uiState.selectedCondominiumProperty!.id, callForFundsId
                ).pipe(
                    map(() => actionCreators.Actions.callForFundsLaunched(callForFundsId))
                );
        }));
};