import React, {FunctionComponent} from "react";
import {Form, Formik} from "formik";
import {Col, Row} from "react-bootstrap";
import * as Yup from "yup";
import {StringSchema} from "yup";
import {CondominiumPropertyExerciseEditionFormFields} from "./condominiumPropertyExerciseEditionFormFields.component";

interface Props {
    onSubmit: (provision: number, constructionFund: number) => void;
}

const condominiumPropertyExerciseEditionSchema =
    Yup.object().shape({
        provision: Yup.number()
            .min(1, 'Le montant du budget prévisionnel doit être supérieur à 0.')
            .required('Le montant du budget prévisionnel est requis.'),
        constructionFund: Yup.number()
            .when('provision', ((provision: number, schema: StringSchema<any>) => {
                const minimum = +(provision * 0.05).toFixed(2);
                return schema.test({
                    test: constructionFund =>
                        !constructionFund || constructionFund === 0 || constructionFund >= minimum,
                    message: `Le fonds de travaux, si renseigné, doit être supérieur ou égal à 5% du budget prévisionnel soit ${minimum} €.`
                })
            }))
    });

export const CondominiumPropertyExerciseEditionForm: FunctionComponent<Props> = ({onSubmit}) => {

    const handleSubmit = (values: any) => {
        onSubmit(+values.provision, +values.constructionFund);
    };

    const FormComponent = ({errors, touched, setFieldValue, values}: {
        errors: any,
        touched: any
        setFieldValue: any,
        values: any,
        submitForm: any
    }) => {
        return <Form className="co-owner-edition-form">
            <CondominiumPropertyExerciseEditionFormFields
                errors={errors} touched={touched}
                setFieldValue={setFieldValue} values={values}/>
            <div className="form-buttons-wrapper">
                <Row style={{margin: 0, padding: 0}}>
                    <Col style={{margin: 0, padding: 0, textAlign: 'right'}}>
                        <button type="submit" className="btn btn-primary">
                            Valider
                        </button>
                    </Col>
                </Row>
            </div>
        </Form>;
    };

    return <div className="contact-form gray-light-bg">
        <Formik
            enableReinitialize={true}
            initialValues={{}}
            validationSchema={condominiumPropertyExerciseEditionSchema}
            onSubmit={handleSubmit}
        >
            {({
                  errors, touched, setFieldValue, values,
                  submitForm
              }) => {
                return <>
                    <FormComponent errors={errors} touched={touched} setFieldValue={setFieldValue}
                                   values={values} submitForm={submitForm}/>
                </>
            }}
        </Formik>
    </div>
};

