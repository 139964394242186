import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";
import {SpecialKey} from "./specialKey.interface";

export const LIST_SPECIAL_KEYS = 'LIST_SPECIAL_KEYS';
export const SPECIAL_KEYS_LISTED = 'SPECIAL_KEYS_LISTED';

export const Actions = {
    listSpecialKeys: () =>
        createAction(LIST_SPECIAL_KEYS),
    specialKeysListed: (specialKeys: SpecialKey[]) =>
        createAction(SPECIAL_KEYS_LISTED, {specialKeys}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;