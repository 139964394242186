import {combineReducers} from "redux";
import * as actionCreators from "../usecases/specialkeydisassociation/actionCreators";

const justDisassociated = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.SPECIAL_KEY_DISASSOCIATED:
            return action.payload.specialKeyId;
        case actionCreators.RESET_SPECIAL_KEY_DISASSOCIATION:
            return null;
        default:
            return state;
    }
};

const disassociating = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.DISASSOCIATE_SPECIAL_KEY;
};

export default combineReducers({
    disassociating,
    justDisassociated
});
