import {combineReducers} from "redux";
import * as actionCreators from "../usecases/condominiumpropertiesretrieval/actionCreators";
import {CondominiumProperty} from "../models/condominiumProperty.interface";

const data = (state: CondominiumProperty[] | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CONDOMINIUM_PROPERTIES_RETRIEVED:
            return action.payload;
        default: return state;
    }
};

const fetching = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.RETRIEVE_CONDOMINIUM_PROPERTIES;
};

export default combineReducers({
    data,
    fetching
});
