import { createAction } from "../../../store/createAction";
import { ActionsUnion } from "../../../store/actionsUnions";

export const DELETE_SPECIAL_KEY = "DELETE_SPECIAL_KEY";
export const SPECIAL_KEY_DELETED = "SPECIAL_KEY_DELETED";
export const RESET_SPECIAL_KEY_DELETION = "RESET_SPECIAL_KEY_DELETION";

export const Actions = {
  deleteSpecialKey: (specialKeyId: string) =>
    createAction(DELETE_SPECIAL_KEY, { specialKeyId }),
  specialKeyDeleted: (specialKeyId: string) =>
    createAction(SPECIAL_KEY_DELETED, { specialKeyId }),
  resetSpecialKeyDeletion: () => createAction(RESET_SPECIAL_KEY_DELETION),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
