import { createAction } from "../../../store/createAction";
import { ActionsUnion } from "../../../store/actionsUnions";
import { CoOwnersDetailsView } from "./coOwnersDetailsView";
import { LotsDetailsView } from "./lotsDetailsView";

export const RETRIEVE_CO_OWNERS_DETAILS_VIEW =
  "RETRIEVE_CO_OWNERS_DETAILS_VIEW";
export const CO_OWNERS_DETAILS_VIEW_RETRIEVED =
  "CONDOMINIUM_CO_OWNERS_DETAILS_VIEW_RETRIEVED";

export const RETRIEVE_LOTS_DETAILS_VIEW = "RETRIEVE_LOTS_DETAILS_VIEW";

export const LOTS_DETAILS_VIEW_RETRIEVED = "LOTS_DETAILS_VIEW_RETRIEVED";

export const Actions = {
  retrieveCoOwnersDetailsView: () =>
    createAction(RETRIEVE_CO_OWNERS_DETAILS_VIEW),
  coOwnersDetailsViewRetrieved: (coOwnersDetailsView: CoOwnersDetailsView) =>
    createAction(CO_OWNERS_DETAILS_VIEW_RETRIEVED, {
      coOwnersDetailsView,
    }),
  retrieveLotsDetailsView: () => createAction(RETRIEVE_LOTS_DETAILS_VIEW),
  lotsDetailsViewRetrieved: (lotsDetailsView: LotsDetailsView) =>
    createAction(LOTS_DETAILS_VIEW_RETRIEVED, {
      lotsDetailsView,
    }),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
