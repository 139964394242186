import { authenticatedUser } from "../corelogic/reducers/userAuthenticationReducer";
import callsForFundsSynthesis from "../corelogic/reducers/callForFundsSynthesisReducer";
import condominiumProperties from "../corelogic/reducers/condominiumPropertiesReducer";
import condominiumPropertyCoOwnersList from "../corelogic/reducers/condominiumPropertyCoOwnersListReducer";
import coOwnerAddition from "../corelogic/reducers/coOwnerAdditionReducer";
import specialKeyAddition from "../corelogic/reducers/specialKeyAdditionReducer";
import specialKeyAssociation from "../corelogic/reducers/specialKeyAssociationReducer";
import specialKeyDisassociation from "../corelogic/reducers/specialKeyDisassociationReducer";
import specialKeyDeletion from "../corelogic/reducers/specialKeyDeletionReducer";
import specialKeysList from "../corelogic/reducers/specialKeysListReducer";
import coOwnersDetailsView from "../corelogic/reducers/coOwnersDetailsViewReducer";
import lotsDetailsView from "../corelogic/reducers/lotsDetailsViewReducer";
import coOwnerRemoval from "../corelogic/reducers/coOwnerRemovalReducer";
import coOwnerLotsAddition from "../corelogic/reducers/coOwnerLotsAdditionReducer";
import coOwnerLotModification from "../corelogic/reducers/coOwnerLotModificationReducer";
import coOwnerLotsRemoval from "../corelogic/reducers/coOwnerLotsRemovalReducer";
import condominiumPropertyAddition from "../corelogic/reducers/condominiumPropertyAdditionReducer";
import condominiumPropertyExerciseAddition from "../corelogic/reducers/condominiumPropertyExerciseAdditionReducer";
import coOwnerDetails from "../corelogic/reducers/coOwnerDetailsReducer";
import coOwnerDetailsEdition from "../corelogic/reducers/coOwnerDetailsEditionReducer";
import coOwnerLots from "../corelogic/reducers/coOwnerLotsReducer";
import balanceBeforeCallsArchives from "../corelogic/reducers/balanceBeforeCallsArchivesReducer";
import coOwnerMoneyCashing from "../corelogic/reducers/coOwnerMoneyCashingReducer";
import { combineReducers } from "redux";

export default combineReducers({
  authenticatedUser,
  callsForFundsSynthesis,
  condominiumProperties,
  coOwnerDetails,
  coOwnerDetailsEdition,
  condominiumPropertyCoOwnersList,
  coOwnerAddition,
  coOwnerRemoval,
  coOwnerLotsAddition,
  coOwnerLotModification,
  coOwnerLotsRemoval,
  condominiumPropertyAddition,
  condominiumPropertyExerciseAddition,
  balanceBeforeCallsArchives,
  coOwnerLots,
  coOwnerMoneyCashing,
  specialKeyAddition,
  specialKeyAssociation,
  specialKeyDisassociation,
  specialKeysList,
  coOwnersDetailsView,
  lotsDetailsView,
  specialKeyDeletion,
});
