import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import * as callForFundsActionCreators from "../../../../../corelogic/usecases/callsforfundsyearsynthesisretrieval/actionCreators";
import * as launchCallForFundsActionCreators from "../../../../../corelogic/usecases/callforfundslaunching/actionCreators";
import { useDispatch, useSelector } from "react-redux";
import { CallsForFundsAssignmentListing } from "./listing/callsForFundsAssignmentListing.component";
import "react-circular-progressbar/dist/styles.css";
import "./callsForFunds.scss";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { getCallsForFundsSynthesis } from "./selectors";
import { BalancesBeforeCallInit } from "./listing/balancesbeforecalls/balancesBeforeCallInit.component";
import { CustomSpinner } from "../../layout/progresscomponents/spinners/customSpinner.component";
import { getSelectedCondominiumProperty } from "../../condominium-properties-selection/selectors";
import { Indicators } from "./indicators/indicators";
import { getCondominiumProperties } from "../condominiumpropertymanagementscreen/condominiumPropertyManagement.component";
import * as balanceBeforeCallsArchivesRetrievalActionCreators from "../../../../../corelogic/usecases/balancebeforecallsarchivesretrieval/actionCreators";
import { getBalanceBeforeCallsArchives } from "./listing/balancesbeforecalls/selectors";

export const CallsForFunds = () => {
  const dispatch = useDispatch();

  const selectedCondominiumProperty = useSelector(
    getSelectedCondominiumProperty
  );
  const condominiumProperties = useSelector(getCondominiumProperties);
  const balanceBeforeCallsArchives = useSelector(getBalanceBeforeCallsArchives);
  const [shouldStartRenderingComponent, setStartRenderingComponent] =
    useState(false);
  const [waitingForNewAssignments, setWaitingForNewAssignments] =
    useState(false);
  const [showLaunchConfirmation, setShowLaunchConfirmation] = useState(false);
  const handleLaunchConfirmationClose = () => setShowLaunchConfirmation(false);
  const handleLaunchConfirmationShow = () => setShowLaunchConfirmation(true);

  useEffect(() => {
    if (selectedCondominiumProperty) {
      dispatch(
        callForFundsActionCreators.Actions.retrieveCallsForFunds(
          selectedCondominiumProperty!.id
        )
      );
      setStartRenderingComponent(true);
    }
  }, [dispatch, selectedCondominiumProperty]);

  const callsForFundsSynthesis = useSelector(getCallsForFundsSynthesis);

  useEffect(() => {
    if (
      callsForFundsSynthesis.numberOfCallForFunds === 0 &&
      !callsForFundsSynthesis.fetching &&
      selectedCondominiumProperty &&
      selectedCondominiumProperty.currentExercise &&
      shouldStartRenderingComponent &&
      !waitingForNewAssignments
    ) {
      dispatch(
        balanceBeforeCallsArchivesRetrievalActionCreators.Actions.retrieveBalanceBeforeCallsArchives(
          selectedCondominiumProperty!.currentExercise.id
        )
      );
    }
  }, [
    dispatch,
    selectedCondominiumProperty,
    callsForFundsSynthesis,
    shouldStartRenderingComponent,
    waitingForNewAssignments,
  ]);

  const refreshAssignments = useCallback(
    (event?) => {
      if (event) event.preventDefault();
      dispatch(
        callForFundsActionCreators.Actions.retrieveCallsForFunds(
          selectedCondominiumProperty!.id
        )
      );
    },
    [dispatch, selectedCondominiumProperty]
  );

  useEffect(() => {
    if (!waitingForNewAssignments) return;
    const timeoutId = setTimeout(() => {
      const newCallForFunds = callsForFundsSynthesis.data.callsForFunds.find(
        (c) =>
          c.context.id === callsForFundsSynthesis.newCallForFundsJustLaunched
      );
      if (callsForFundsSynthesis.newCallForFundsJustLaunched) {
        if (newCallForFunds && newCallForFunds.assignments.length > 0) {
          setWaitingForNewAssignments(false);
        } else refreshAssignments();
      }
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [callsForFundsSynthesis, refreshAssignments, waitingForNewAssignments]);

  const launchCallForFunds = useCallback(() => {
    handleLaunchConfirmationClose();
    setWaitingForNewAssignments(true);
    dispatch(launchCallForFundsActionCreators.Actions.launchCallForFunds());
  }, [dispatch]);

  const {
    fetching,
    launchingCallForFunds,
    data,
    currentlyPaid,
    delays,
    numberOfCallForFunds,
  } = callsForFundsSynthesis;
  if (condominiumProperties.data?.length === 0)
    return (
      <div>
        <p>Vous n'avez pas encore configuré votre copropriété.</p>
        <p>Veuillez vous rendre dans la section "Mes copropriétés".</p>
      </div>
    );
  if (
    (!shouldStartRenderingComponent ||
      launchingCallForFunds ||
      fetching ||
      balanceBeforeCallsArchives.fetching) &&
    !waitingForNewAssignments
  )
    return <CustomSpinner />;
  return (
    <Row>
      <Col md={12} lg={3}>
        <Indicators
          callsForFundsSynthesis={callsForFundsSynthesis.data}
          currentlyPaid={currentlyPaid}
          delays={delays}
        />
      </Col>
      <Col lg={9} className="assignments">
        {!data.exercice.isSumOfQuotaMatch && (
          <p>
            Vous n'avez pas saisi tous les copropriétaires ainsi que les
            tantièmes associés.
            <br />
          </p>
        )}
        {data.exercice.isSumOfQuotaMatch &&
          numberOfCallForFunds === 0 &&
          waitingForNewAssignments && <CustomSpinner />}
        {data.exercice.isSumOfQuotaMatch && numberOfCallForFunds > 0 && (
          <div>
            <Row>
              <Col>
                <div>
                  {waitingForNewAssignments ? (
                    <CustomSpinner />
                  ) : (
                    <CallsForFundsAssignmentListing
                      callsForFundsSynthesis={data}
                      delays={delays}
                      refreshAssignments={refreshAssignments}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </div>
        )}
        {data.exercice.isSumOfQuotaMatch &&
          numberOfCallForFunds === 0 &&
          !waitingForNewAssignments && (
            <div>
              <br />
              <Row>
                <Col>
                  <div>
                    <BalancesBeforeCallInit
                      condominiumProperty={selectedCondominiumProperty!}
                      balancesBeforeCall={balanceBeforeCallsArchives.data}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          )}
        <br />
        {!waitingForNewAssignments &&
          data.exercice.isSumOfQuotaMatch &&
          numberOfCallForFunds < 4 && (
            <Row>
              <Col className="text-right">
                <button
                  type="submit"
                  className="btn launch-call-for-funds-button"
                  onClick={handleLaunchConfirmationShow}
                >
                  Lancer{" "}
                  {numberOfCallForFunds === 0
                    ? "l'appel de fonds du trimestre courant"
                    : `le ${numberOfCallForFunds + 1}ème appel de fonds`}
                </button>
              </Col>
            </Row>
          )}
        <Modal
          show={showLaunchConfirmation}
          onHide={handleLaunchConfirmationClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Lancement d'appel de fonds</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Confirmez-vous le lancement de l'appel de fond du trimestre
              courant ?
            </p>
            <p>
              <i>
                Pensez à bien renseigner les paiements pour chaque utilisateur
                manuellement avant de le confirmer.
              </i>
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleLaunchConfirmationClose}>
              Non
            </Button>
            <Button onClick={launchCallForFunds} className="btn button-action">
              Oui
            </Button>
          </Modal.Footer>
        </Modal>
      </Col>
    </Row>
  );
};
