import React, { FC, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../../../../store/appState.interface";
import { Col, Row } from "react-bootstrap";
import { ByLotCard } from "./byLotCard";
import * as condominiumDetailsViewActionCreators from "../../../../../../corelogic/usecases/condominiumdetailsview/actionCreators";

type Props = {
  condominiumId: string;
  condominiumShares: number;
};

export const ViewByLot: FC<Props> = ({ condominiumId, condominiumShares }) => {
  const condominiumDetailsView = useSelector(
    (state: AppState) => state.coreLogicState.lotsDetailsView
  );

  const dispatch = useDispatch();

  const refreshContent = useCallback(() => {
    dispatch(
      condominiumDetailsViewActionCreators.Actions.retrieveLotsDetailsView()
    );
  }, [dispatch]);

  useEffect(() => {
    if (condominiumId) refreshContent();
  }, [dispatch, condominiumId, refreshContent]);

  return (
    <>
      <div className="coowners-management">
        {condominiumDetailsView.data && (
          <>
            <Row>
              <Col className="text-center">
                {Object.keys(condominiumDetailsView.data).map((lotNumber) => (
                  <ByLotCard
                    key={lotNumber}
                    condominiumShares={condominiumShares}
                    details={condominiumDetailsView.data![lotNumber]}
                  />
                ))}
              </Col>
            </Row>
          </>
        )}
      </div>
    </>
  );
};
