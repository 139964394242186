import {combineReducers} from "redux";
import * as actionCreators from "../usecases/coowneraddition/actionCreators";

const justAdded = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_TO_CONDOMINIUM_PROPERTY_ADDED:
            return action.payload.coOwnerId;
        default:
            return state;
    }
};

const adding = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.ADD_CO_OWNER_TO_CONDOMINIUM_PROPERTY;
};

export default combineReducers({
    adding,
    justAdded
});
