import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {CondominiumPropertyGateway} from "../../gateways/condominiumPropertyGateway.interface";

export const retrieveBalanceBeforeCallsArchives = (action$: ActionsObservable<actionCreators.Actions>,
                                                   state$: StateObservable<AppState>,
                                                   {condominiumPropertyGateway}:
                                                       {
                                                           condominiumPropertyGateway: CondominiumPropertyGateway
                                                       }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.retrieveBalanceBeforeCallsArchives>>(actionCreators.RETRIEVE_BALANCE_BEFORE_CALLS_ARCHIVES),
        switchMap(action => {
            return condominiumPropertyGateway.retrieveBalanceBeforeCallsArchives(action.payload.condominiumPropertyExerciseId)
                .pipe(
                    map(balanceBeforeCallsArchives =>
                        actionCreators.Actions.balanceBeforeCallsArchivesRetrieved(balanceBeforeCallsArchives))
                );
        }));
};