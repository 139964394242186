import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from ".//actionCreators";
import {AppState} from "../../../store/appState.interface";
import {switchMap} from "rxjs/operators";
import {PermanentDataStore} from "../../gateways/permanentDataStore.interface";
import {of} from "rxjs";

export const authenticatedUser = (action$: ActionsObservable<actionCreators.Actions>,
                                  state$: StateObservable<AppState>,
                                  {permanentDataStore}:
                                      { permanentDataStore: PermanentDataStore }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.authenticatedUser>>(actionCreators.AUTHENTICATED_USER),
        switchMap(action => {
            permanentDataStore.store('authenticatedUser', {authenticatedUser: action.payload});
            return of({type: 'NULL'});
        }));
};
