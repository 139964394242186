import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from ".//actionCreators";
import {AppState} from "../../../store/appState.interface";
import {switchMap} from "rxjs/operators";
import {PermanentDataStore} from "../../gateways/permanentDataStore.interface";
import {of} from "ramda";

export const loggedOutUser = (action$: ActionsObservable<actionCreators.Actions>,
                                  state$: StateObservable<AppState>,
                                  {permanentDataStore}:
                                      { permanentDataStore: PermanentDataStore }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.loggedOutUser>>(actionCreators.LOGGED_OUT_USER),
        switchMap(() => {
            permanentDataStore.remove('authenticatedUser');
            return of({type: 'NULL'});
        }));
};
