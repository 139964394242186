import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {map, switchMap} from "rxjs/operators";
import {geocodeByAddress} from "react-places-autocomplete";
import {CoOwnerGateway} from "../../gateways/coOwnerGateway.interface";

export const editCoOwner = (action$: ActionsObservable<actionCreators.Actions>,
                            state$: StateObservable<AppState>,
                            {coOwnerGateway}: {
                                coOwnerGateway: CoOwnerGateway
                            }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.editCoOwnerDetails>>(actionCreators.EDIT_CO_OWNER_DETAILS),
        switchMap(async action => {
            const {coOwnerId, field, conjoint, value} = action.payload.command;
            let editedData;
            switch (field) {
                case 'ADDRESS': {
                    const [addressGeocode] = await geocodeByAddress(value);
                    editedData = {
                        address: {
                            address: value.split(',')[0],
                            zipCode: addressGeocode.address_components[6].long_name,
                            city: addressGeocode.address_components[2].long_name,
                            country: addressGeocode.address_components[5].long_name,
                        }
                    }
                    break;
                }
                case 'EMAIL': {
                    editedData = {email: value};
                    break;
                }
                case 'PHONE': {
                    editedData = {phone: value};
                    break;
                }
                case 'FIRST_NAME': {
                    editedData = {firstName: value};
                    break;
                }
                case 'LAST_NAME': {
                    editedData = {lastName: value};
                    break;
                }
                default: {
                    editedData = {gender: value};
                }
            }
            return {action, coOwnerId, field, conjoint, editedData};
        }),
        switchMap(({action, coOwnerId, field, conjoint, editedData}) => {
            return coOwnerGateway.editCoOwnerDetails(coOwnerId, field, conjoint, editedData)
                .pipe(
                    map(() => {
                        return actionCreators.Actions.coOwnerDetailsEdited(coOwnerId, field)
                    })
                );
        })
    )
};
