import {ActionsObservable, ofType, StateObservable} from 'redux-observable';
import * as actionCreators from '../coownerlotsremoval/actionCreators';
import {AppState} from '../../../store/appState.interface';
import {map, switchMap} from 'rxjs/operators';
import {CoOwnerGateway} from '../../gateways/coOwnerGateway.interface';

export const removeCoOwnerLots = (
    action$: ActionsObservable<actionCreators.Actions>,
    state$: StateObservable<AppState>,
    {
        coOwnerGateway
    }: {
        coOwnerGateway: CoOwnerGateway;
    }
) => {
    return action$.pipe(
        ofType<actionCreators.Actions, ReturnType<typeof actionCreators.Actions.removeCoOwnerLots>>(
            actionCreators.REMOVE_CO_OWNER_LOTS
        ),
        switchMap(action => {
            return coOwnerGateway
                .removeLots(action.payload.coOwnerId, action.payload.lotNumbers)
                .pipe(
                    map(() =>
                        actionCreators.Actions.coOwnerLotsRemoved(action.payload.coOwnerId, action.payload.lotNumbers)
                    )
                );
        })
    );
};
