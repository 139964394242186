import {combineReducers} from "redux";
import * as actionCreators from "../usecases/specialkeyaddition/actionCreators";

const justAdded = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.SPECIAL_KEY_ADDED:
            return action.payload.specialKeyId;
        case actionCreators.CLEAR_SPECIAL_KEY_ADDITION:
            return null;
        default:
            return state;
    }
};

const adding = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.ADD_SPECIAL_KEY;
};

export default combineReducers({
    adding,
    justAdded
});
