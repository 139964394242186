import * as React from "react";
import {CondominiumPropertyCoOwnerHeader} from "../../../../../corelogic/models/condominiumPropertyCoOwnerHeader";
import {Button, Modal} from "react-bootstrap";
import {useState} from "react";

interface Props {
    coOwner: CondominiumPropertyCoOwnerHeader;
    openProfile: (coOwnerId: string, conjoint?: boolean) => () => void;
    configureLots: (coOwnerId: string, coOwnerFirstName: string, coOwnerLastName: string, coOwnerQuota: number,
                    conjoint?: {firstName: string, lastName: string}) => () => void;
    removeCoOwner: (coOwnerId: string) => void;
    hasCondominiumPropertyCallsForFunds: boolean;
}

export const CoOwnerTableRow = React.memo<Props>(({
                                                      coOwner, hasCondominiumPropertyCallsForFunds,
                                                      openProfile, configureLots, removeCoOwner
                                                  }) => {

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const handleDeleteConfirmationClose = () => setShowDeleteConfirmation(false);
    const handleDeleteConfirmationShow = () => setShowDeleteConfirmation(true);

    const deleteConfirmation = () => {
        handleDeleteConfirmationClose();
        removeCoOwner(coOwner.id);
    }

    return <tr style={{textAlign: 'center'}}>
        <td>
            <div className="link" onClick={openProfile(coOwner.id)}>
                {coOwner.coOwnerPerson.firstName + ' ' + coOwner.coOwnerPerson.lastName}
            </div>
            {coOwner.coOwnerPersonConjoint &&
            <>
                <br/>
                <div className="link" onClick={openProfile(coOwner.id, true)}>
                    {coOwner.coOwnerPersonConjoint.firstName + ' ' + coOwner.coOwnerPersonConjoint.lastName}
                </div>
            </>}
        </td>
        <td className="link" style={{verticalAlign: 'middle'}}
            onClick={configureLots(coOwner.id, coOwner.coOwnerPerson.firstName,
                coOwner.coOwnerPerson.lastName, coOwner.quota, coOwner.coOwnerPersonConjoint ? {
                    firstName: coOwner.coOwnerPersonConjoint.firstName,
                    lastName: coOwner.coOwnerPersonConjoint.lastName
                } : undefined)}>
            {coOwner.quota}</td>
        <td>
            {!hasCondominiumPropertyCallsForFunds && <i className="fas fa-times" style={{color: 'red'}} onClick={handleDeleteConfirmationShow}/>}
        </td>
        <Modal show={showDeleteConfirmation} onHide={handleDeleteConfirmationClose}>
            <Modal.Header closeButton>
                <Modal.Title>Suppression de copropriétaire</Modal.Title>
            </Modal.Header>
            <Modal.Body>Confirmez-vous la suppression du
                copropriétaire {coOwner.coOwnerPerson.firstName} {coOwner.coOwnerPerson.lastName} ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleDeleteConfirmationClose}>
                    Non
                </Button>
                <Button onClick={deleteConfirmation} className="btn button-action">
                    Oui
                </Button>
            </Modal.Footer>
        </Modal>
    </tr>;

});
