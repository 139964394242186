import {Button, Col, Form, FormLabel} from "react-bootstrap";
import {Field, Formik} from "formik";
import React, {FunctionComponent} from "react";
import * as Yup from "yup";
import {GooglePlacesAutocompleteField} from "../../../../formcustomfields/googlePlacesAutocompleteField.component";

export interface Props {
    onSubmit: (phone: string) => void;
    onCancel: () => void;
    value: string;
}

const formSchema = Yup.object().shape({
    address: Yup.string().required('L\'adresse est requise.'),
});

export const AddressSingleForm: FunctionComponent<Props> = ({onSubmit, onCancel, value}) => {

    const handleSubmit = (values: any) => {
        onSubmit(values.address);
    }

    const cancel = () => {
        onCancel();
    }

    return <Formik
        enableReinitialize={true}
        initialValues={{
            address: value
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, handleSubmit
          }) => {
            return <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="align-items-center" style={{width: '100%'}}>
                    <Col>
                        <FormLabel htmlFor="address" srOnly>Adresse</FormLabel>
                        <Field className="form-control mb-2" id="address" name="address" component={GooglePlacesAutocompleteField}/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {errors.address && touched.address ? (
                            <div className="error-message">{errors.address}</div>
                        ) : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs="12" className="text-right pr-3">
                        <Button type="button" onClick={cancel} className="mb-2 btn btn-dark">
                            Annuler
                        </Button>
                        <Button type="submit" className="ml-3 mb-2 btn btn-primary" style={{
                            backgroundColor: '#f16e00',
                            outline: 'none', border: '1px solid  #f16e00'
                        }}>
                            Valider
                        </Button>
                    </Col>
                </Form.Row>

            </Form>;
        }}
    </Formik>
};