import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {map, switchMap} from "rxjs/operators";
import {CallForFundsGateway} from "../../gateways/callForFundsGateway.interface";
import {AppState} from "../../../store/appState.interface";

export const cashCoOwnerMoney = (action$: ActionsObservable<actionCreators.Actions>,
                                               state$: StateObservable<AppState>,
                                               {callForFundsGateway}:
                                                   { callForFundsGateway: CallForFundsGateway }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.cashCoOwnerMoney>>(actionCreators.CASH_CO_OWNER_MONEY),
        // debounceTime(200),
        switchMap(action => {
            const {callForFundsAssignmentId, amount, cashingDate} = action.payload;
            return callForFundsGateway.cashCoOwnerMoney(callForFundsAssignmentId, amount, cashingDate)
                .pipe(
                    map(() =>
                        actionCreators.Actions.coOwnerMoneyCashed(callForFundsAssignmentId))
                );
        }));
};
