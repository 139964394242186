import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const AUTHENTICATED_USER = 'AUTHENTICATED_USER';
export const LOGGED_OUT_USER = 'LOGGED_OUT_USER';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const Actions = {
    authenticatedUser: (id: string, name: string, email: string, accessToken: string) =>
        createAction(AUTHENTICATED_USER, {id, name, email, accessToken}),
    loggedOutUser: () =>
        createAction(LOGGED_OUT_USER),
    refreshToken: (accessToken: string) =>
        createAction(REFRESH_TOKEN, {accessToken})
};
// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
