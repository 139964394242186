import * as React from "react";
import { Component } from "react";
import { Col, Row } from "react-bootstrap";
import "./index.scss";
import { connect } from "react-redux";
import * as condominiumPropertyCoownersListActionCreators from "../../../../../corelogic/usecases/condominiumpropertycoownerslistretrieval/actionCreators";
import * as removeCoOwnerActionCreators from "../../../../../corelogic/usecases/coownerremoval/actionCreators";
import { AppState } from "../../../../../store/appState.interface";
import { CondominiumPropertyCoOwnerHeader } from "../../../../../corelogic/models/condominiumPropertyCoOwnerHeader";
import { CondominiumProperty } from "../../../../../corelogic/models/condominiumProperty.interface";
import { CustomSpinner } from "../../layout/progresscomponents/spinners/customSpinner.component";
import { CoOwnersTable } from "./coOwnersTable.component";
import { NewCoOwnerButton } from "./newCoOwnerButton.component";
import { CoOwnerEditionModal } from "./coowneredition";
import { getCondominiumCoOwnersList } from "./coowneredition/selectors";
import { CoOwnerDetailsModal } from "../coownerdetailsscreen";
import { CoOwnerLotsConfigurationModal } from "./coownerlots/coOwnerLotsConfigurationModal.component";
import { UserAuthenticationData } from "../../../../../corelogic/models/userAuthenticationData.interface";
import { getCondominiumProperties } from "../condominiumpropertymanagementscreen/condominiumPropertyManagement.component";

interface Props {
  retrieveCondominiumPropertyCoOwnersList: typeof condominiumPropertyCoownersListActionCreators.Actions.retrieveCondominiumPropertyCoOwnersList;
  removeCoOwner: typeof removeCoOwnerActionCreators.Actions.removeCoOwner;
  condominiumCoOwnersList: {
    list: CondominiumPropertyCoOwnerHeader[];
    sumOfShares: number;
    fetching: boolean;
  };
  coOwnerRemoval: {
    removing: boolean;
    justRemoved: string | null;
  };
  condominiumProperty: CondominiumProperty | undefined;
  authenticatedUser: UserAuthenticationData | null;
  condominiumProperties: {
    fetching: boolean;
    data: CondominiumProperty[] | null;
  };
}

interface State {
  shouldShowCoOwnerEdition: boolean;
  shouldShowCoOwnerProfile: boolean;
  shouldShowCoOwnerLotsConfiguration: boolean;
  coOwnerProfile: string;
  coOwnerFirstName: string;
  coOwnerLastName: string;
  coOwnerQuota: number;
  hasStartedRetrievingCoOwner: boolean;
  conjointFirstName: string | undefined;
  conjointLastName: string | undefined;
  conjoint: boolean;
}

class CoOwnersManagement extends Component<Props, State> {
  state = {
    shouldShowCoOwnerEdition: false,
    shouldShowCoOwnerProfile: false,
    shouldShowCoOwnerLotsConfiguration: false,
    coOwnerProfile: "",
    coOwnerFirstName: "",
    coOwnerLastName: "",
    conjointFirstName: undefined,
    conjointLastName: undefined,
    coOwnerQuota: 0,
    conjoint: false,
    hasStartedRetrievingCoOwner: false,
  };

  showCoOwnerProfile = (coOwnerId: string, conjoint?: boolean) => () => {
    this.setState({
      coOwnerProfile: coOwnerId,
      conjoint: !!conjoint,
      shouldShowCoOwnerProfile: true,
    });
  };

  closeCoOwnerProfile = () => {
    this.setState({
      coOwnerProfile: "",
      shouldShowCoOwnerProfile: false,
    });
  };

  configureCoOwnerLots =
    (
      coOwnerId: string,
      coOwnerFirstName: string,
      coOwnerLastName: string,
      coOwnerQuota: number,
      conjoint?: {
        firstName: string;
        lastName: string;
      }
    ) =>
    () => {
      this.setState({
        coOwnerProfile: coOwnerId,
        coOwnerFirstName,
        coOwnerLastName,
        conjointFirstName: conjoint?.firstName,
        conjointLastName: conjoint?.lastName,
        coOwnerQuota,
        shouldShowCoOwnerLotsConfiguration: true,
      });
    };

  closeCoOwnerLotsConfiguration = () => {
    this.setState({
      coOwnerProfile: "",
      shouldShowCoOwnerLotsConfiguration: false,
    });
  };

  componentDidMount(): void {
    if (this.props.condominiumProperty) {
      this.refreshContent();
    }
  }

  componentDidUpdate(prevProps: Props): void {
    if (
      prevProps.condominiumProperty !== this.props.condominiumProperty ||
      prevProps.coOwnerRemoval.justRemoved !==
        this.props.coOwnerRemoval.justRemoved
    ) {
      this.refreshContent();
    }
  }

  showCoOwnerEdition = () => {
    this.setState({ shouldShowCoOwnerEdition: true });
  };

  closeCoOwnerEdition = () => {
    this.setState({ shouldShowCoOwnerEdition: false });
  };

  removeCoOwner = (coOwnerId: string) => {
    this.props.removeCoOwner(coOwnerId);
  };

  refreshContent = () => {
    this.props.retrieveCondominiumPropertyCoOwnersList(
      this.props.condominiumProperty!.id!
    );
  };

  render() {
    if (
      !this.props.condominiumProperties.data ||
      this.props.condominiumCoOwnersList.fetching ||
      this.props.condominiumProperties.fetching ||
      this.props.coOwnerRemoval.removing
    )
      return <CustomSpinner />;
    if (
      this.props.condominiumProperties.data &&
      this.props.condominiumProperties.data.length === 0
    )
      return (
        <div className="coowners-management">
          <p>Vous n'avez pas encore configuré de copropriétés.</p>
          <p>Veuillez vous rendre dans la section "Mes copropriétés".</p>
        </div>
      );
    return (
      <div className="coowners-management">
        <Row>
          {this.props.condominiumCoOwnersList.list.length > 0 &&
            this.props.condominiumCoOwnersList.sumOfShares <
              this.props.condominiumProperty!.shares && (
              <Col className="text-right" style={{ paddingRight: "2rem" }}>
                <NewCoOwnerButton action={this.showCoOwnerEdition} />
              </Col>
            )}
        </Row>
        <Row>
          <Col className="text-right" style={{ paddingRight: "2rem" }}>
            <h5>
              {this.props.condominiumCoOwnersList.sumOfShares +
                " / " +
                this.props.condominiumProperty?.shares}{" "}
              tantièmes
            </h5>
          </Col>
        </Row>
        {this.props.condominiumCoOwnersList.list.length > 0 && (
          <div>
            <CoOwnersTable
              coOwners={this.props.condominiumCoOwnersList.list}
              openProfile={this.showCoOwnerProfile}
              removeCoOwner={this.removeCoOwner}
              configureLots={this.configureCoOwnerLots}
              hasCondominiumPropertyCallsForFunds={
                this.props.condominiumProperty?.hasCallsForFunds || false
              }
            />
          </div>
        )}
        {this.props.condominiumCoOwnersList.list.length === 0 && (
          <Row>
            <Col className="text-center">
              <p>Vous n'avez pas encore configuré de copropriétaire.</p>
              <NewCoOwnerButton action={this.showCoOwnerEdition} />
            </Col>
          </Row>
        )}
        <CoOwnerEditionModal
          show={this.state.shouldShowCoOwnerEdition}
          onClose={this.closeCoOwnerEdition}
          refreshContent={this.refreshContent}
        />
        {this.state.shouldShowCoOwnerProfile && (
          <CoOwnerDetailsModal
            onClose={this.closeCoOwnerProfile}
            coOwnerId={this.state.coOwnerProfile}
            conjoint={this.state.conjoint}
            refresh={() => {}}
          />
        )}
        {this.state.shouldShowCoOwnerLotsConfiguration && (
          <CoOwnerLotsConfigurationModal
            coOwnerId={this.state.coOwnerProfile}
            coOwnerFirstName={this.state.coOwnerFirstName}
            coOwnerLastName={this.state.coOwnerLastName}
            conjointFirstName={this.state.conjointFirstName}
            conjointLastName={this.state.conjointLastName}
            coOwnerQuota={this.state.coOwnerQuota}
            onClose={this.closeCoOwnerLotsConfiguration}
            refresh={() => this.refreshContent()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  authenticatedUser: state.coreLogicState.authenticatedUser,
  coOwnerRemoval: state.coreLogicState.coOwnerRemoval,
  condominiumCoOwnersList: getCondominiumCoOwnersList(state),
  condominiumProperties: getCondominiumProperties(state),
  condominiumProperty: state.coreLogicState.condominiumProperties.data?.find(
    (condominiumProperty) =>
      condominiumProperty.id === state.uiState.selectedCondominiumProperty?.id
  ),
});

const mapDispatchToProps = {
  retrieveCondominiumPropertyCoOwnersList:
    condominiumPropertyCoownersListActionCreators.Actions
      .retrieveCondominiumPropertyCoOwnersList,
  removeCoOwner: removeCoOwnerActionCreators.Actions.removeCoOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(CoOwnersManagement);
