import {Form} from "react-bootstrap";
import React, {FunctionComponent} from "react";

interface Props {
    field: any;
    form: any;
    value: any;
}

export const SelectFormField: FunctionComponent<Props> = ({
                                                              field: {id, name, value, label, onChange, onBlur},
                                                              form: {touched, errors, values, setFieldValue, setFieldTouched},
                                                              ...props
                                                          }) => {
    return <Form.Control as="select" onChange={onChange}
                         id={id} name={name} {...props}>
        {props.children}
    </Form.Control>;
};