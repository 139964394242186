import { combineReducers } from "redux";
import * as coOwnersDetailsViewActionCreators from "../usecases/condominiumdetailsview/actionCreators";
import { CoOwnersDetailsView } from "../usecases/condominiumdetailsview/coOwnersDetailsView";

const data = (
  state: CoOwnersDetailsView | null = null,
  action: coOwnersDetailsViewActionCreators.Actions
) => {
  switch (action.type) {
    case coOwnersDetailsViewActionCreators.RETRIEVE_CO_OWNERS_DETAILS_VIEW:
      return null;
    case coOwnersDetailsViewActionCreators.CO_OWNERS_DETAILS_VIEW_RETRIEVED:
      return action.payload.coOwnersDetailsView;
    default:
      return state;
  }
};

const fetching = (
  state: boolean = false,
  action: coOwnersDetailsViewActionCreators.Actions
) => {
  switch (action.type) {
    case coOwnersDetailsViewActionCreators.RETRIEVE_CO_OWNERS_DETAILS_VIEW:
      return true;
    case coOwnersDetailsViewActionCreators.CO_OWNERS_DETAILS_VIEW_RETRIEVED:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  data,
  fetching,
});
