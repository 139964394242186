import {Button, Col, Form, FormLabel} from "react-bootstrap";
import {Field, Formik} from "formik";
import React, {FunctionComponent} from "react";
import * as Yup from "yup";
import {PhoneFormField} from "../../../../formcustomfields/phoneFormField.component";

export interface Props {
    onSubmit: (phone: string) => void;
    onCancel: () => void;
    value: string;
}

const phoneRegExp = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
const formSchema = Yup.object().shape({
    phone: Yup.string().matches(phoneRegExp, 'Numéro de téléphone invalide.')
});

export const PhoneSingleForm: FunctionComponent<Props> = ({onSubmit, onCancel, value}) => {

    const handleSubmit = (values: any) => {
        onSubmit(values.phone);
    }

    const cancel = () => {
        onCancel();
    }

    return <Formik
        enableReinitialize={true}
        initialValues={{
            phone: value
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, handleSubmit
          }) => {
            return <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="align-items-center" style={{width: '100%'}}>
                    <Col xs="12">
                        <FormLabel htmlFor="phone" srOnly>Téléphone</FormLabel>
                        <Field className="form-control mb-2" id="phone" name="phone" component={PhoneFormField}/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {errors.phone && touched.phone ? (
                            <div className="error-message">{errors.phone}</div>
                        ) : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs="12" className="text-right pr-3">
                        <><Button type="button" onClick={cancel} className="mb-2 btn btn-dark">
                            Annuler
                        </Button>
                            <Button type="submit" className="ml-3 mb-2 btn btn-primary" style={{
                                backgroundColor: '#f16e00',
                                outline: 'none', border: '1px solid  #f16e00'
                            }}>
                                Valider
                            </Button></>
                    </Col>
                </Form.Row>

            </Form>;
        }}
    </Formik>
};