import * as React from "react";
import {CallsForFundsSynthesis} from "../../../../../../corelogic/models/callsForFundsSynthesis.interface";
import {Col, Row} from "react-bootstrap";

interface Props {
    callsForFundsSynthesis: CallsForFundsSynthesis
}

export const BudgetIndicator = React.memo<Props>(({callsForFundsSynthesis}) => {
    return <>
        <Row>
            <Col>
                <span>
                    CG annuelles
                </span>
            </Col>
            <Col>
                <span>
                    {callsForFundsSynthesis.exercice.provision?.toFixed(2)} €
                </span>
            </Col>
        </Row>
        <Row>
            <Col>
                <span>
                    FT annuels
                </span>
            </Col>
            <Col>
                <span>
                    {callsForFundsSynthesis.exercice.constructionFund?.toFixed(2)} €
                </span>
            </Col>
        </Row>
    </>;
});