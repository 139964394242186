import * as coownerEditionActionCreators
    from "../uicomponents/screens/coownersmanagementscreen/coowneredition/form/actionsCreators";
import * as actionCreators from "../../../corelogic/usecases/coowneraddition/actionCreators";
import {AddedCoOwnerContactData} from "../../../corelogic/usecases/coowneraddition/addedCoOwnerContactData";
import * as R from "ramda";

const coOwnerAddition = (state: {
                             quota: number;
                             coOwnersContactData: AddedCoOwnerContactData[]
                         } = {
                             quota: 0,
                             coOwnersContactData: []
                         }, action: actionCreators.Actions | coownerEditionActionCreators.Actions
) => {
    switch (action.type) {
        case coownerEditionActionCreators.ADD_CO_OWNER:
            return {
                ...state,
                quota: action.payload.quota,
                coOwnersContactData: state.coOwnersContactData.length === 0 ?
                    [action.payload.addedCoOwnerContactData] :
                    R.update(0, action.payload.addedCoOwnerContactData, state.coOwnersContactData)
            };
        case  coownerEditionActionCreators.ADD_CO_OWNER_CONJOINT:
            return {
                ...state,
                coOwnersContactData: state.coOwnersContactData.length === 1 ?
                    [...state.coOwnersContactData, action.payload.addedCoOwnerContactData] :
                    R.update(1, action.payload.addedCoOwnerContactData, state.coOwnersContactData)
            };
        case actionCreators.CO_OWNER_TO_CONDOMINIUM_PROPERTY_ADDED: {
            return {
                quota: 0,
                coOwnersContactData: []
            }
        }
        default:
            return state;
    }
};

export default coOwnerAddition;
