import * as React from "react";
import {Card, Table} from "react-bootstrap"
import {BalanceBeforeCall} from "../../../../../../../corelogic/models/balanceBeforeCall.interface";
import {BalanceBeforeCallArchiveRow} from "./balanceBeforeCallArchiveRow.component";
import {useCallback} from "react";

interface Props {
    balanceBeforeCallsArchives: BalanceBeforeCall[],
    openProfile: (coOwnerId: string, conjoint?: boolean) => () => void;
    setBalanceBeforeCall: (coOwnerId: string, balanceBefore: number) => void;
}

export const BalanceBeforeCallsArchivesTable = React.memo<Props>(({
                                                                      balanceBeforeCallsArchives,
                                                                      setBalanceBeforeCall,
                                                                      openProfile
                                                                  }) => {

    const setBalanceBeforeCallCallBack = useCallback((coOwnerId: string, balanceBefore: number) => {
        setBalanceBeforeCall(coOwnerId, balanceBefore);
    }, [setBalanceBeforeCall]);


    return <Card>
        <Card.Body>
            <Table responsive className="list-table">
                <thead className="first-level-head">
                <tr>
                    <th>Copropriétaires</th>
                    <th>Soldes avant appel</th>
                </tr>
                </thead>
                <tbody className="first-level-body">
                {balanceBeforeCallsArchives.map(balanceBeforeCall => {
                    return <BalanceBeforeCallArchiveRow key={balanceBeforeCall.coOwner.id}
                                                        openProfile={openProfile}
                                                        balanceBeforeCall={balanceBeforeCall}
                                                        setBalanceBeforeCall={setBalanceBeforeCallCallBack}/>;
                })
                }
                </tbody>
            </Table>
        </Card.Body>
    </Card>
});
