import * as React from "react";
import {FunctionComponent} from "react";
import {Button} from "react-bootstrap";

interface Props {
    action: () => void
}

export const NewCondominiumPropertyButton: FunctionComponent<Props> = ({action}) => {
    return <Button className="condominium-property-add-button" onClick={action}>
        <i className="fas fa-plus"/>&nbsp;<span>Ajouter une copropriété</span>
    </Button>;
};
