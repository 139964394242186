import {combineReducers} from "redux";
import * as actionCreators from "../usecases/coownerdetailsedition/actionCreators";

const justEdited = (state: string | null = null, action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_DETAILS_EDITED:
            return action.payload.coOwnerId;
        default:
            return state;
    }
};

const editing = (state: boolean = false, action: actionCreators.Actions) => {
    return action.type === actionCreators.EDIT_CO_OWNER_DETAILS;
};

const field = (state: 'EMAIL' | 'ADDRESS' | 'PHONE' | 'LAST_NAME' | 'FIRST_NAME' | 'GENDER' | null = null,
               action: actionCreators.Actions) => {
    switch (action.type) {
        case actionCreators.CO_OWNER_DETAILS_EDITED:
            return action.payload.field;
        default:
            return state;
    }
}

export default combineReducers({
    editing,
    field,
    justEdited
});
