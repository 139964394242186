import React, {FunctionComponent, useEffect} from "react";
import NumberFormat from "react-number-format";

interface Props {
    field: any;
    form: any
}

export const NumberFormField: FunctionComponent<Props> = ({
                                                              field,
                                                              form: {touched, errors, values, setFieldValue, setFieldTouched},
                                                              ...props
                                                          }) => {
    useEffect(() => {
        setFieldValue(field.name, field.value || '')
    }, [setFieldValue, field.name, field.value]);
    return <NumberFormat name={field.name}
                         defaultValue={field.value}
                         value={field.value ? field.value.formattedValue : field.value}
                         onValueChange={val =>
                             setFieldValue(field.name, val.floatValue || '')
                         }
                         {...field}
                         {...props}
    />
};