import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {map, switchMap} from "rxjs/operators";
import {CallForFundsGateway} from "../../gateways/callForFundsGateway.interface";
import {AppState} from "../../../store/appState.interface";

export const retrieveCallsForFundsSynthesis = (action$: ActionsObservable<actionCreators.Actions>,
                                               state$: StateObservable<AppState>,
                                               {callForFundsGateway}:
                                                   { callForFundsGateway: CallForFundsGateway }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.retrieveCallsForFunds>>(actionCreators.RETRIEVE_CALLS_FOR_FUNDS),
        // debounceTime(200),
        switchMap(action => {
            const {condominiumPropertyId} = action.payload;
            return callForFundsGateway.retrieveSynthesis(condominiumPropertyId)
                .pipe(
                    map(callForFundsSynthesis =>
                        actionCreators.Actions.callsForFundsRetrieved(callForFundsSynthesis))
                );
        }));
};
