import { combineReducers } from "redux";
import * as actionCreators from "../usecases/specialkeydeletion/actionCreators";

const justDeleted = (
  state: string | null = null,
  action: actionCreators.Actions
) => {
  switch (action.type) {
    case actionCreators.SPECIAL_KEY_DELETED:
      return action.payload.specialKeyId;
    case actionCreators.RESET_SPECIAL_KEY_DELETION:
      return null;
    default:
      return state;
  }
};

const deleting = (state: boolean = false, action: actionCreators.Actions) => {
  switch (action.type) {
    case actionCreators.DELETE_SPECIAL_KEY:
      return true;
    case actionCreators.SPECIAL_KEY_DELETED:
    case actionCreators.RESET_SPECIAL_KEY_DELETION:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  deleting,
  justDeleted,
});
