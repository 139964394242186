import {createAction} from "../../../store/createAction";
import {ActionsUnion} from "../../../store/actionsUnions";

export const SET_BALANCE_BEFORE_CALL = 'SET_BALANCE_BEFORE_CALL';
export const BALANCE_BEFORE_CALL_SET = 'BALANCE_BEFORE_CALL_SET';

export const Actions = {
    setBalanceBeforeCall: (condominiumPropertyExerciseId: string, coOwnerId: string, balanceBefore: number) =>
        createAction(SET_BALANCE_BEFORE_CALL, {condominiumPropertyExerciseId, coOwnerId, balanceBefore}),
    balanceBeforeCallSet: (coOwnerId: string) =>
        createAction(BALANCE_BEFORE_CALL_SET, {coOwnerId}),
};

// eslint-disable-next-line
export type Actions = ActionsUnion<typeof Actions>;
