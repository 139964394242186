import {Button, Col, Form, FormLabel} from "react-bootstrap";
import {Formik} from "formik";
import React, {FunctionComponent} from "react";
import * as Yup from "yup";

export interface Props {
    onSubmit: (gender: string) => void;
    onCancel: () => void;
    value: string;
}

const formSchema = Yup.object().shape({
    gender: Yup.string().required()
});

export const GenderSingleForm: FunctionComponent<Props> = ({onSubmit, onCancel, value}) => {

    const handleSubmit = (values: any) => {
        onSubmit(values.gender);
    }

    const cancel = () => {
        onCancel();
    }

    return <Formik
        enableReinitialize={true}
        initialValues={{
            gender: value
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, handleSubmit, setFieldValue, values
          }) => {
            return <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="align-items-center" style={{width: '100%'}}>
                    <Col xs="12">
                        <FormLabel htmlFor="gender" srOnly>Civilité</FormLabel>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                id="male"
                                value="male"
                                checked={values['gender'] === 'male'}
                                onChange={() => {
                                    setFieldValue('gender', 'male');
                                }}
                                name='male'
                                type="radio"
                            />
                            <label className="form-check-label" htmlFor="male">M.</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input
                                className="form-check-input"
                                id="female"
                                value="female"
                                checked={values['gender'] === 'female'}
                                onChange={() => {
                                    setFieldValue('gender', 'female');
                                }}
                                name='female'
                                type="radio"
                            />
                            <label className="form-check-label" htmlFor="female">Mme.</label>
                        </div>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {errors.gender && touched.gender ? (
                            <div className="error-message">{errors.gender}</div>
                        ) : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs="12" className="text-right pr-3">
                        <><Button type="button" onClick={cancel} className="mb-2 btn btn-dark">
                            Annuler
                        </Button>
                            <Button type="submit" className="ml-3 mb-2 btn btn-primary" style={{
                                backgroundColor: '#f16e00',
                                outline: 'none', border: '1px solid  #f16e00'
                            }}>
                                Valider
                            </Button></>
                    </Col>
                </Form.Row>

            </Form>;
        }}
    </Formik>
};