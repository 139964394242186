import {ActionsObservable, ofType, StateObservable} from "redux-observable";
import * as actionCreators from "./actionCreators";
import {AppState} from "../../../store/appState.interface";
import {CondominiumPropertyGateway} from "../../gateways/condominiumPropertyGateway.interface";
import {map, switchMap} from "rxjs/operators";

export const setBalanceBeforeCallArchive = (action$: ActionsObservable<actionCreators.Actions>,
                                            state$: StateObservable<AppState>,
                                            {condominiumPropertyGateway}: {
                                                condominiumPropertyGateway: CondominiumPropertyGateway,
                                            }) => {
    return action$.pipe(
        ofType<actionCreators.Actions,
            ReturnType<typeof actionCreators.Actions.setBalanceBeforeCall>>(actionCreators.SET_BALANCE_BEFORE_CALL),
        // debounceTime(200),
        switchMap(action => {
                return condominiumPropertyGateway.setBalanceBeforeCall(
                    action.payload.balanceBefore,
                    action.payload.coOwnerId,
                    action.payload.condominiumPropertyExerciseId
                ).pipe(
                    map(() => actionCreators.Actions.balanceBeforeCallSet(action.payload.coOwnerId))
                )
            }
        )
    )
};
