import {Button, Col, Form} from "react-bootstrap";
import {Field, Formik} from "formik";
import React, {FunctionComponent} from "react";
import * as Yup from "yup";

export interface Props {
    onSubmit: (text: string) => void;
    onCancel: () => void;
    value: string;
    name: string;
}

export const TextSingleForm: FunctionComponent<Props> = ({onSubmit, onCancel, value, name}) => {

    const formSchema = Yup.object().shape({
        [name]: Yup.string()
            .required('Ce champ est requis.'),
    });

    const handleSubmit = (values: any) => {
        onSubmit(values[name]);
    }

    const cancel = () => {
        onCancel();
    }

    return <Formik
        enableReinitialize={true}
        initialValues={{
            [name]: value
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
    >
        {({
              errors, touched, handleSubmit
          }) => {
            return <Form noValidate onSubmit={handleSubmit}>
                <Form.Row className="align-items-center" style={{width: '100%'}}>
                    <Col xs="12">
                        <Field className="form-control mb-2" id={name} name={name} style={{width: '100%'}}/>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col>
                        {errors[name] && touched[name] ? (
                            <div className="error-message">{errors[name]}</div>
                        ) : null}
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs="12" className="text-right pr-3">
                        <><Button type="button" onClick={cancel} className="mb-2 btn btn-dark">
                            Annuler
                        </Button>
                            <Button type="submit" className="ml-3 mb-2 btn btn-primary" style={{
                                backgroundColor: '#f16e00',
                                outline: 'none', border: '1px solid  #f16e00'
                            }}>
                                Valider
                            </Button></>
                    </Col>
                </Form.Row>

            </Form>;
        }}
    </Formik>
};