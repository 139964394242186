import * as React from "react";
import {FunctionComponent} from "react";
import {CallsForFundsSynthesis} from "../../../../../../corelogic/models/callsForFundsSynthesis.interface";
import {Card, Col, Row} from "react-bootstrap";
import {CircularProgressbar} from 'react-circular-progressbar';

interface Props {
    callsForFundsSynthesis: CallsForFundsSynthesis;
    currentlyPaid: number;
    delays: {
        numberOfDelays: number,
        amount: number
    }
}

export const CallsForFundsIndicators: FunctionComponent<Props> =
    ({
         callsForFundsSynthesis, currentlyPaid, delays
     }) => {
        const callsForFunds = callsForFundsSynthesis.callsForFunds;
        const expectedPayment = +((callsForFundsSynthesis.exercice.provision! + callsForFundsSynthesis.exercice.constructionFund!) *
            0.25 * (Math.max(callsForFundsSynthesis.callsForFunds.length, 1))).toFixed(2);
        return <>
            <Card>
                <Card.Header className="text-left">
                    <i className="fa fa-rocket"/>&nbsp;&nbsp;<span>{callsForFunds.length + ' ' + (
                    callsForFunds.length <= 1 ?
                        'appel lancé' :
                        'appels lancés')}</span>
                </Card.Header>
                <Card.Body className="text-left">
                    <>
                        <br/>
                        <div>

                        </div>
                        <Row>
                            <Col>
                                <span>Payés</span>
                            </Col>
                            <Col className="text-right">
                                <span>{currentlyPaid}&nbsp;€</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                Retards ({delays.numberOfDelays > 0 ?
                                <span className="with-delays">{delays.numberOfDelays}</span> :
                                <span>{delays.numberOfDelays}</span>})
                            </Col>
                            <Col className="text-right">
                                {delays.numberOfDelays > 0 ?
                                    <span className='with-delays'>
                                        {delays.amount.toFixed(2)}&nbsp;€
                                    </span> :
                                    <span>Aucun</span>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>Attendus</span>
                            </Col>
                            <Col className="text-right">
                                {callsForFunds.length > 0 ? <span>{expectedPayment}&nbsp;€</span> :
                                    <span>-</span>}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div
                                    style={{
                                        width: 80, height: 80, marginRight: "auto", marginLeft: "auto"
                                    }}>
                                    <CircularProgressbar value={+(currentlyPaid * 100 / expectedPayment).toFixed(2)}
                                                         text={`${Math.floor(currentlyPaid * 100 / expectedPayment)}%`}/>
                                </div>
                            </Col>
                        </Row>
                    </>
                </Card.Body>
            </Card>
        </>
            ;
    };