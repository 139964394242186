import * as React from "react";
import { Component } from "react";
import { Modal } from "react-bootstrap";
import { AppState } from "../../../../../store/appState.interface";
import { connect } from "react-redux";
import { CoOwnerDetails } from "../../../../../corelogic/models/coOwnerDetails";
import * as coOwnerDetailsEditionActionCreators from "../../../../../corelogic/usecases/coownerdetailsedition/actionCreators";
import * as coOwnerDetailsActionCreators from "../../../../../corelogic/usecases/coownersdetailsretrieval/actionCreators";
import { EmailSingleForm } from "../coownersmanagementscreen/coowneredition/form/edition/emailSingleForm.component";
import { PhoneSingleForm } from "../coownersmanagementscreen/coowneredition/form/edition/phoneSingleForm.component";
import { AddressSingleForm } from "../coownersmanagementscreen/coowneredition/form/edition/addressSingleForm.component";
import { CustomSpinner } from "../../layout/progresscomponents/spinners/customSpinner.component";
import { TextSingleForm } from "../coownersmanagementscreen/coowneredition/form/edition/textSingleForm.component";
import { GenderSingleForm } from "../coownersmanagementscreen/coowneredition/form/edition/genderSingleForm.component";

interface Props {
  onClose: () => void;
  coOwnerId: string;
  conjoint: boolean;
  coOwnerDetails: {
    data: CoOwnerDetails | null;
    fetching: boolean;
  };
  coOwnerDetailsEdition: {
    justEdited: string | null;
    editing: boolean;
    field:
      | "EMAIL"
      | "ADDRESS"
      | "PHONE"
      | "LAST_NAME"
      | "FIRST_NAME"
      | "GENDER"
      | null;
  };
  retrieveCoOwnerDetails: typeof coOwnerDetailsActionCreators.Actions.retrieveCoOwnerDetails;
  editCoOwnerDetails: typeof coOwnerDetailsEditionActionCreators.Actions.editCoOwnerDetails;
  refresh: () => void;
}

interface State {
  show: boolean;
  emailEditionMode: boolean;
  phoneEditionMode: boolean;
  addressEditionMode: boolean;
  firstNameEditionMode: boolean;
  lastNameEditionMode: boolean;
  genderEditionMode: boolean;
}

class CoOwnerDetailsModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      show: true,
      emailEditionMode: false,
      phoneEditionMode: false,
      addressEditionMode: false,
      firstNameEditionMode: false,
      lastNameEditionMode: false,
      genderEditionMode: false,
    };
  }

  handleClose = () => {
    this.props.onClose();
  };

  componentDidMount(): void {
    this.props.retrieveCoOwnerDetails(
      this.props.coOwnerId,
      this.props.conjoint
    );
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (
      prevProps.coOwnerDetailsEdition.editing &&
      !this.props.coOwnerDetailsEdition.editing
    ) {
      this.props.retrieveCoOwnerDetails(
        this.props.coOwnerId,
        this.props.conjoint
      );
      this.setState({
        show: true,
        emailEditionMode: false,
        phoneEditionMode: false,
        addressEditionMode: false,
        firstNameEditionMode: false,
        lastNameEditionMode: false,
        genderEditionMode: false,
      });
      this.props.refresh();
    }
  }

  changeEmail = (email: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "EMAIL",
      value: email,
    });
  };

  changeLastName = (lastName: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "LAST_NAME",
      value: lastName,
    });
  };

  changeFirstName = (firstName: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "FIRST_NAME",
      value: firstName,
    });
  };

  changeGender = (gender: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "GENDER",
      value: gender,
    });
  };

  enableEmailEditionMode = () => {
    this.setState({ emailEditionMode: true });
  };

  disableEmailEditionMode = () => {
    this.setState({ emailEditionMode: false });
  };

  changePhone = (phone: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "PHONE",
      value: phone,
    });
  };

  enablePhoneEditionMode = () => {
    this.setState({ phoneEditionMode: true });
  };

  disablePhoneEditionMode = () => {
    this.setState({ phoneEditionMode: false });
  };

  changeAddress = (address: string) => {
    this.props.editCoOwnerDetails({
      coOwnerId: this.props.coOwnerId,
      conjoint: this.props.conjoint,
      field: "ADDRESS",
      value: address,
    });
  };

  enableAddressEditionMode = () => {
    this.setState({ addressEditionMode: true });
  };

  disableAddressEditionMode = () => {
    this.setState({ addressEditionMode: false });
  };

  enableFirstNameEditionMode = () => {
    this.setState({ firstNameEditionMode: true });
  };

  disableFirstNameEditionMode = () => {
    this.setState({ firstNameEditionMode: false });
  };

  disableLastNameEditionMode = () => {
    this.setState({ lastNameEditionMode: false });
  };

  enableLastNameEditionMode = () => {
    this.setState({ lastNameEditionMode: true });
  };

  disableGenderEditionMode = () => {
    this.setState({ genderEditionMode: false });
  };

  enableGenderEditionMode = () => {
    this.setState({ genderEditionMode: true });
  };

  render() {
    const {
      id,
      gender,
      firstName,
      lastName,
      address,
      city,
      zipCode,
      country,
      phone,
      email,
      quota,
    } = this.props.coOwnerDetails.data! || {};
    return (
      <Modal show={true} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {id
              ? "Copropriétaire " + firstName + " " + lastName
              : "Chargement de la fiche ..."}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(this.props.coOwnerDetailsEdition.editing ||
            this.props.coOwnerDetails.fetching) && <CustomSpinner />}
          {!this.props.coOwnerDetailsEdition.editing &&
            !this.props.coOwnerDetails.fetching && (
              <ul>
                {this.state.genderEditionMode ? (
                  <li>
                    Civilité :{" "}
                    <GenderSingleForm
                      value={gender}
                      onSubmit={this.changeGender}
                      onCancel={this.disableGenderEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    Civilité : {gender === "male" ? "M." : "Mme"}{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enableGenderEditionMode}
                    />
                  </li>
                )}
                {this.state.firstNameEditionMode ? (
                  <li>
                    Prénom :{" "}
                    <TextSingleForm
                      value={firstName}
                      name="firstName"
                      onSubmit={this.changeFirstName}
                      onCancel={this.disableFirstNameEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    Prénom : {firstName}{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enableFirstNameEditionMode}
                    />
                  </li>
                )}
                {this.state.lastNameEditionMode ? (
                  <li>
                    Nom :{" "}
                    <TextSingleForm
                      value={lastName}
                      name="lastName"
                      onSubmit={this.changeLastName}
                      onCancel={this.disableLastNameEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    Nom : {lastName}{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enableLastNameEditionMode}
                    />
                  </li>
                )}
                {this.state.addressEditionMode ? (
                  <li>
                    Adresse :{" "}
                    <AddressSingleForm
                      value={address}
                      onSubmit={this.changeAddress}
                      onCancel={this.disableAddressEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    Adresse : {address}{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enableAddressEditionMode}
                    />
                  </li>
                )}
                <li>Code postal : {zipCode}</li>
                <li>Ville : {city}</li>
                <li>Pays : {country}</li>
                {this.state.phoneEditionMode ? (
                  <li>
                    Téléphone :{" "}
                    <PhoneSingleForm
                      value={phone}
                      onSubmit={this.changePhone}
                      onCancel={this.disablePhoneEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    Téléphone : <a href={"tel:" + phone}>{phone}</a>{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enablePhoneEditionMode}
                    />
                  </li>
                )}
                {this.state.emailEditionMode ? (
                  <li>
                    E-mail :{" "}
                    <EmailSingleForm
                      value={email}
                      onSubmit={this.changeEmail}
                      onCancel={this.disableEmailEditionMode}
                    />
                  </li>
                ) : (
                  <li>
                    E-mail : <a href={"mailto:" + email}>{email}</a>{" "}
                    <i
                      className="fas fa-pen"
                      onClick={this.enableEmailEditionMode}
                    />
                  </li>
                )}
                <li>Tantième : {quota}</li>
              </ul>
            )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  coOwnerDetails: {
    data: state.coreLogicState.coOwnerDetails.data,
    fetching: state.coreLogicState.coOwnerDetails.fetching,
  },
  coOwnerDetailsEdition: state.coreLogicState.coOwnerDetailsEdition,
});

const mapDispatchToProps = {
  retrieveCoOwnerDetails:
    coOwnerDetailsActionCreators.Actions.retrieveCoOwnerDetails,
  editCoOwnerDetails:
    coOwnerDetailsEditionActionCreators.Actions.editCoOwnerDetails,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoOwnerDetailsModal);
